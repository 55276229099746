@import '../variables';

.pointer {
  cursor: pointer;
}

.po-s {
  position: static !important;
}
.po-r {
  position: relative;
}
.po-a {
  position: absolute;
}

.po-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ovf-h {
  overflow: hidden;
}

.br-bl {
  border-bottom-left-radius: 12px;
}
.br-br {
  border-bottom-right-radius: 12px;
}

.is-hiiden {
  visibility: hidden !important;
}

.grid {
  display: grid;
}

.row-1 {
  grid-row: 1;
}

.row-2 {
  grid-row: 2;
}

.rows-2 {
  grid-row: 1/3;
}

.col-1 {
  grid-column: 1;
}

.col-2 {
  grid-column: 2;
}
.opac-1 {
  opacity: 1;
}
.opac-3 {
  opacity: 0.3;
}
.opac-35{
  opacity: .35;
}
.opac-4 {
  opacity: 0.4;
}
.opac-5 {
  opacity: 0.5;
}
.opac-6 {
  opacity: 0.6;
}
.opac-76 {
  opacity: 0.76;
}
.opac-64 {
  opacity: 0.64;
}
.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-bottom {
  display: flex;
  align-items: flex-end;
}
.flex-top {
  display: flex;
  align-items: flex-start;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-aligned {
  display: flex;
  align-items: center;
}

.flex-aligned-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.flex-aligned-top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icn-block {
  width: 50px;
  height: 50px;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-justify-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-justify-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.flex-space,
.lb-block-subhead.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lb-block-subhead.flex-space > div {
  display: flex;
  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-end {
  align-self: flex-end;
}

.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.fnt-b {
  font-weight: bold;
}

.fnt-n {
  font-weight: normal;
}

.mw-305 {
  max-width: 305px;
}
.mw-470 {
  max-width: 470px;
}

.wp-190 {
  width: 190px;
}
.w-5 {
  width: 5%;
}
.w-6 {
  width: 6%;
}
.w-10 {
  width: 10%;
}
.w-13 {
  width: 13%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-26 {
  width: 26%;
}
.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}
.w-48 {
  width: 48% ;
}
.w-46 {
  width: 46%;
}
.w-50 {
  width: 50% !important;
}
.w-51 {
  width: 51% !important;
}
.w-52 {
  width: 52%;
}
.w-55 {
  width: 55%;
}
.w-58 {
  width: 58%;
}
.w-60 {
  width: 60%;
}
.w-63 {
  width: 63%;
}
.w-60i {
  width: 60% !important;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-97 {
  width: 97.8%;
}
.w-100 {
  width: 100% !important;
}
.wp-32 {
  width: 32px;
}
.w-12 {
  width: 12px;
}
.w-24 {
  width: 24px;
}
.wp-428 {
  width: 428px;
}

.wp-100 {
  width: 100px;
}
.wp-106 {
  width: 106px;
}
.wp-250 {
  width: 250px;
}
.wp-300 {
  width: 300px;
}

.w-auto {
  width: auto;
}
.w-fit-content {
  width: fit-content;
}
.w-50-15 {
  width: calc(50% - 15px);
}
.w-70-15 {
  width: calc(70% - 15px);
}

.mw-80 {
  max-width: 80%;
}
.mw-90 {
  max-width: 90%;
}
.mw-95 {
  max-width: 95%;
}
.mw-100 {
  max-width: 100%;
}

// popups
.mw-500 {
  max-width: 500px;
}
.mw-600 {
  max-width: 600px;
}
.mw-650 {
  max-width: 650px;
}
.mw-750 {
  max-width: 750px;
}

.wh-auto {
  width: auto;
  height: auto;
}
.mw-auto {
  min-width: auto;
}
.lb-popup-body.mw-500 {
  max-width: 500px !important; // we need important here!
}
.lb-popup-body.mw-562 {
  max-width: 562px !important;
}
.lb-popup-body.mw-5050 {
  max-width: 550px !important; // we need important here!
}
.lb-popup-body.mw-550 {
  max-width: 550px !important; // we need important here!
}
.lb-popup-body.mw-600 {
  max-width: 600px;
}
.lb-popup-body.mw-641 {
  max-width: 641px;
}
.lb-popup-body.mw-634 {
  max-width: 634px;
}
.lb-popup-body.mw-650 {
  max-width: 650px;
}
.lb-popup-body.mw-750 {
  max-width: 750px;
}

.w-650 {
  width: 650px !important;
}
.lb-popup-body.mw-490 {
  max-width: 490px;
}
.lb-popup-body.mw-655 {
  max-width: 655px;
}

.lb-popup-body.mw-700 {
  max-width: 700px;
}
.lb-popup-body.mw-750 {
  max-width: 750px;
}
.lb-popup-body.mw-780 {
  max-width: 780px;
}
.lb-popup-body.mw-800 {
  max-width: 800px;
}
.lb-popup-body.mw-834 {
  max-width: 834px;
}
.mw-845,
.lb-popup-body.mw-845 {
  max-width: 845px;
}

.lb-popup-body.mw-890 {
  max-width: 890px;
}
.lb-popup-body.mw-900 {
  max-width: 900px;
}
.mw-696 {
  max-width: 696px;
}
.mw-700 {
  max-width: 700px;
}
.mw-760 {
  max-width: 760px;
}
.mw-788 {
  max-width: 788px !important;
}
.mw-780 {
  max-width: 780px !important;
}
.mw-970 {
  max-width: 970px;
}

// min heights
.h-32{
  height: 32px;
}
.h-17 {
  height: 17px;
}
.h-40 {
  height: 40px;
}
.h-69{
  height: 69px;
}
.mh-10 {
  min-height: 10vh;
}
.mh-15 {
  min-height: 15vh;
}
.mh-17 {
  min-height: 17vh;
}
.mh-20 {
  min-height: 20vh;
}
.mh-22 {
  min-height: 22vh;
}

.mh-25 {
  min-height: 25vh;
}
.mh-30 {
  min-height: 30vh;
}
.mh-35 {
  min-height: 35vh;
}
.mh-40 {
  min-height: 40vh;
}
.mh-45 {
  min-height: 45vh;
}
.mh-50 {
  min-height: 50vh;
}

.mih-40 {
  min-height: 40px;
}

.mh-3 {
  min-height: 393px;
}
.mh-40px {
  min-height: 40px;
}
.mh-45px {
  min-height: 45px;
}

.p-0 {
  padding: 0;
}

.h-50 {
  height: 50px !important;
}
.h-34 {
  height: 34px;
}
.h-100 {
  height: 100%;
}
.mv-auto {
  margin-left: auto;
  margin-right: auto;
}
.mtmi-24 {
  margin-top: -24px;
}
.mt-auto {
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}

.mt-25 {
  margin-top: 25px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-34 {
  margin-top: 34px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

// horizontal paddings
.ph-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.ph-12 {
  padding-right: 12px;
  padding-left: 12px;
}
.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.ph-16 {
  padding-right: 16px;
  padding-left: 16px;
}
.ph-17 {
  padding-right: 17px;
  padding-left: 17px;
}
.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.ph-24 {
  padding-right: 24px;
  padding-left: 24px;
}
.ph-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.ph-32 {
  padding-right: 32px;
  padding-left: 32px;
}
.ph-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.ph-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.ph-42 {
  padding-right: 42px;
  padding-left: 42px;
}
.ph-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.ph-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.ph-60 {
  padding-right: 60px;
  padding-left: 60px;
}
.ph-90 {
  padding-right: 90px;
  padding-left: 90px;
}

.mr-auto {
  margin-right: auto;
}

.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 3.9%;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-38 {
  margin-right: 38px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px !important;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-520 {
  margin-right: 520px;
}

//-mr
.mmr-25 {
  margin-right: -25px;
}

.mmt-20 {
  margin-top: -20px !important;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-9 {
  margin-bottom: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-17 {
  margin-bottom: 17px;
}
.mb-19 {
  margin-bottom: 19px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-21 {
  margin-bottom: 21px;
}
.mb-23 {
  margin-bottom: 23px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-190 {
  margin-bottom: 190px;
}

.ml-auto {
  margin-left: auto;
}
.ml-2 {
  margin-left: 2px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-28 {
  margin-left: 28px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-77 {
  margin-left: 77px;
}
.ml-86 {
  margin-left: 86px;
}
.ml-440 {
  margin-left: 440px !important;
}
.mmr-10 {
  margin-right: -10px;
}
.mmr-15 {
  margin-right: -15px;
}
.mmr-20 {
  margin-right: -20px;
}
.mmr-25 {
  margin-right: -25px;
}
.mmr-30 {
  margin-right: -30px;
}
.mmr-35 {
  margin-right: -35px;
}
.mmr-40 {
  margin-right: -40px;
}
.mmr-45 {
  margin-right: -45px;
}
.mmr-50 {
  margin-right: -50px;
}
.mmr-55 {
  margin-right: -55px;
}
.mmr-60 {
  margin-right: -60px;
}

.mml-10 {
  margin-left: -10px;
}
.mml-15 {
  margin-left: -15px;
}
.mml-20 {
  margin-left: -20px;
}
.mml-25 {
  margin-left: -25px;
}
.mml-30 {
  margin-left: -30px;
}
.mml-35 {
  margin-left: -35px;
}
.mml-40 {
  margin-left: -40px;
}
.mml-45 {
  margin-left: -45px;
}
.mml-50 {
  margin-left: -50px;
}
.mml-55 {
  margin-left: -55px;
}
.mml-60 {
  margin-left: -60px;
}

.p-25 {
  padding: 25px;
}

.pt-0 {
  padding-top: 0;
}
.pt-2 {
  padding-top: 2px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-26 {
  padding-top: 26px;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-32 {
  padding-top: 32px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-52 {
  padding-top: 52px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-100 {
  padding-top: 100px;
}

.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pr-7 {
  padding-right: 7px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-22 {
  padding-right: 22px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}
.pr-32 {
  padding-right: 32px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-70 {
  padding-right: 70px;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-7 {
  padding-bottom: 7px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-17 {
  padding-bottom: 17px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-88 {
  padding-bottom: 88px;
}

.pl-0 {
  padding-left: 0;
}
.pl-2 {
  padding-left: 2px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-24 {
  padding-left: 24px !important;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-36 {
  padding-left: 36px !important;
}
.pl-38 {
  padding-left: 38px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-200 {
  padding-left: 200px;
}
.pl-410 {
  padding-left: 410px;
}

.mh-28 {
  margin-right: 28px;
  margin-left: 28px;
}
.mh-15 {
  margin-right: 15px;
  margin-left: 15px;
}
// vertical paddings
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pv-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

// horizontal paddings
.ph-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.ph-17 {
  padding-right: 17px;
  padding-left: 17px;
}
.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.ph-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.ph-32 {
  padding-right: 32px;
  padding-left: 32px;
}
.ph-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.ph-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.ph-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.ph-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.ph-70 {
  padding-right: 70px;
  padding-left: 70px;
}
.ph-90 {
  padding-right: 90px;
  padding-left: 90px;
}

.ph-100 {
  padding-right: 100px;
  padding-left: 100px;
}
// margin minus and same padding
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-24{
  margin-left: 24px;
  margin-right: 24px;
}
.mp-20 {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.mp-25 {
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
}

.mp-30-25 {
  margin-left: -30px;
  margin-right: -25px;
  padding-left: 30px;
  padding-right: 25px;
}

.mp-30 {
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
}

.mp-32 {
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 32px;
  padding-right: 32px;
}

// percent padding
.pl-pct-25 {
  padding-left: 25%;
}
.pr-pct-30 {
  padding-right: 30%;
}

.ml-pct-15 {
  margin-left: 15%;
}

// borders
.bt {
  border-top: 1px solid $border-grey-2;
}
.bb {
  border-bottom: 1px solid $border-grey-2;
}
.bb-b {
  border-bottom: 1px solid #c4c4c4;
}
// rows with border-bottom
.row-border:not(:last-child) {
  border-bottom: 1px solid $border-grey-2;
}

.rounded {
  border-radius: 50%;
}

.min-width {
  min-width: 0;
  width: 20%;
  white-space: nowrap;
}

/* =========== text-overflow ellipsis ========== */
.lb-ellipsis-wrap {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  align-items: center;

  .lb-ellipsis {
    min-width: 0;
    // width: 100%;
    // flex: 1;
  }

  &.text-right .lb-ellipsis {
    width: 100%;
    flex: 1;
  }

  .icn-warning {
    margin: 0 0 0 9px;
  }
}

.icn-filter {
  width: 18px !important;
  height: 18px !important;
}

.lb-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

/* =========== END of text-overflow ellipsis ========== */

/* =========== Main text font classes ========== */

.fnt-7 {
  @include fontSize(7px);
}

.fnt-8 {
  @include fontSize(8px);
}

.fnt-10 {
  @include fontSize(10px);
}

.fnt-11 {
  // color: $transparent-grey;
  @include fontSize(11px);
}
.fnt-12 {
  // color: $transparent-grey;
  @include fontSize(12px);
}

.fnt-13 {
  @include fontSize(13px);
}
.fnt-14 {
  @include fontSize(14px);
}
.fnt-15 {
  @include fontSize(15px);
}
.fnt-16 {
  @include fontSize(16px);
}
.fnt-17 {
  @include fontSize(17px);
}
.fnt-18 {
  @include fontSize(18px);
}
.fnt-19 {
  @include fontSize(19px);
}
.fnt-20 {
  @include fontSize(20px);
}
.fnt-21 {
  @include fontSize(21px);
}
.fnt-22 {
  @include fontSize(22px);
}

.fnt-23 {
  @include fontSize(23px);
}

.fnt-24 {
  @include fontSize(24px);
}
.fnt-25 {
  @include fontSize(25px);
}

.fnt-25 {
  @include fontSize(25px);
}
.fnt-30 {
  @include fontSize(30px);
}
.fnt-32 {
  @include fontSize(32px);
}
.lh-14 {
  line-height: 0.9375rem;
}
.lh-15 {
  line-height: 1.5rem;
}
.lh-16 {
  line-height: 1rem;
}
.lh-17 {
  line-height: 1.0625rem;
}
.lh-18 {
  line-height: 1.125rem;
}
.lh-19 {
  line-height: 1.1875rem;
}
.lh-38 {
  line-height: 2.375rem;
}
.lh-24 {
  line-height: 1.5rem;
}
.lh-40 {
  line-height: 2.5rem;
}

/* =========== font family classes ========== */

.fnt-low {
  font-family: 'Liberty-Nus', Arial, sans-serif;
}

.fnt-low-m {
  font-family: 'Liberty-Nus Medium', Arial, sans-serif;
}

.fnt-low-b {
  font-family: 'Liberty-Nus Bold', Arial, sans-serif;
}

.fnt-upper {
  font-family: 'Liberty-MT', Arial, sans-serif;
}

.fnt-upper-m {
  font-family: 'Liberty-MT Medium', Arial, sans-serif;
}

.fnt-upper-b {
  font-family: 'Liberty-MT Bold', Arial, sans-serif;
}

/* =========== font family classes end ========== */

// standart text styles
.std-text-12 {
  @include fontSize(12px);
  line-height: 1.8em;
}

.std-text-14 {
  @include fontSize(14px);
  line-height: 1.8em;
}

.std-text-13 {
  @include fontSize(13px);
  line-height: 1.5em;
}

// text colors
.light-grey {
  color: $txt-light-grey;
}
.lightg {
  color: $txt-lightt;
}
.tr-grey {
  color: $transparent-grey;
}

.grey-24 {
  color: $icn-dark;
}

.medium-grey {
  color: $medium-grey;
}

.dark-clr {
  color: $dark;
}

.basic-clr {
  color: $basic-grey;
}

.upper {
  text-transform: uppercase;
}

.bg-grey {
  background: $bg-light;
}

.bg-white {
  background: #fff;
}

.white {
  color: $white;
}
/* =========== End of Main text font classes ========== */

// download-link style
.dwn-link {
  display: flex;
  @include fontFamily('Liberty-Nus');
  align-items: center;

  span + .icn-download {
    position: relative;
    top: -0.07rem;
    cursor: pointer;

    &:hover {
      color: $nav-red;
    }
  }
}

//print icn
.lb-subhead-custom {
  span + .icn-print {
    position: relative;
    top: -0.07rem;
    cursor: pointer;

    &:hover {
      color: $icon-red;
    }
  }
}

// radio buttons - checked
.radio-label {
  display: flex;
  align-items: center;
  margin: 0.9375rem 0;
  cursor: pointer;

  .radio-button {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  span {
    order: 2;
  }
}

.radio-box {
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid $label-grey;
  margin: 0 10px 0 0;
  order: 1;
}

.radio-button:checked {
  & ~ .radio-box {
    border: 4px solid #db0011;
    background: #fff;
  }

  & ~ span {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(14px);
  }
}

.radio-button-black:checked {
  & ~ .radio-box {
    border: 4px solid #000;
    background-image: url("/static/media/tick.8bbdea7f.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

// ---------- FLAGS -------------//
.lb-flag {
  display: inline-block;
  font-size: 0;
  background: url('../../assets/images/flags_103x69.png') no-repeat;
  background-size: 100% auto;
}

.flag-round {
  border-radius: 50%;
}

.lb-flag.flag20x20 {
  width: 20px;
  height: 20px;
  background-size: 30px 629px;

  &.fAED {
    background-position: 0 0;
  }
  &.fAMD {
    background-position: 0 -21px;
  }
  &.fAUD {
    background-position: 0 -41px;
  }
  &.fAVD {
    background-position: 0 -61px;
  }
  &.fAZM {
    background-position: -5px -85px;
  }
  &.fBGN {
    background-position: 0 -106px;
  }
  &.fBYB {
    background-position: 0 -126px;
  }
  &.fCAD {
    background-position: -5px -147px;
  }
  &.fCHF {
    background-position: -5px -168px;
  }
  &.fCZK {
    background-position: -3px -190px;
  }
  &.fDKK {
    background-position: -5px -209px;
  }
  &.fEUR {
    background-position: -5px -231px;
  }
  &.fGBP {
    background-position: -5px -252px;
  }
  &.fGEL {
    background-position: -3px -273px;
    background-size: 25px 629px;
  }
  &.fHKD {
    background-position: -5px -294px;
  }
  &.fILS {
    background-position: -5px -315px;
  }
  &.fJPY {
    background-position: -5px -336px;
  }
  &.fKZT {
    background-position: -6px -356px;
  }
  &.fLTL {
    background-position: -5px -378px;
  }
  &.fLVL {
    background-position: 0 -400px;
  }
  &.fMDL {
    background-position: -5px -420px;
  }
  &.fNOK {
    background-position: -3px -441px;
  }
  &.fPLN {
    background-position: 0 -463px;
  }
  &.fRUB {
    background-position: 0 -483px;
  }
  &.fRUR {
    background-position: 0 -504px;
  }
  &.fSEK {
    background-position: -3px -524px;
  }
  &.fTRL {
    background-position: -3px -546px;
  }
  &.fUAH {
    background-position: 0 -567px;
  }
  &.fUKG {
    background-position: 0 -587px;
  }
  &.fUSD {
    background-position: 0px -607px;
  }
}

.lb-flag.flag56x38 {
  width: 56px;
  height: 38px;
  border-radius: $borderRadius;

  &.fAED {
    background-position: 0 0;
  }
  &.fAMD {
    background-position: 0 -38px;
  }
  &.fAUD {
    background-position: 0 -76px;
  }
  &.fAVD {
    background-position: 0 -115px;
  }
  &.fAZM {
    background-position: 0 -153px;
  }
  &.fBGN {
    background-position: 0 -190px;
  }
  &.fBYB {
    background-position: 0 -228px;
  }
  &.fCAD {
    background-position: 0 -266px;
  }
  &.fCHF {
    background-position: 0 -304px;
  }
  &.fCZK {
    background-position: 0 -342px;
  }
  &.fDKK {
    background-position: 0 -380px;
  }
  &.fEUR {
    background-position: 0 -418px;
  }
  &.fGBP {
    background-position: 0 -456px;
  }
  &.fGEL {
    background-position: 0 -494px;
  }
  &.fHKD {
    background-position: 0 -532px;
  }
  &.fILS {
    background-position: 0 -571px;
  }
  &.fJPY {
    background-position: 0 -609px;
  }
  &.fKZT {
    background-position: 0 -646px;
  }
  &.fLTL {
    background-position: 0 -684px;
  }
  &.fLVL {
    background-position: 0 -722px;
  }
  &.fMDL {
    background-position: 0 -760px;
  }
  &.fNOK {
    background-position: 0 -798px;
  }
  &.fPLN {
    background-position: 0 -837px;
  }
  &.fRUB {
    background-position: 0 -875px;
  }
  &.fRUR {
    background-position: 0 -913px;
  }
  &.fSEK {
    background-position: 0 -950px;
  }
  &.fTRL {
    background-position: 0 -989px;
  }
  &.fUAH {
    background-position: 0 -1027px;
  }
  &.fUKG {
    background-position: 0 -1065px;
  }
  &.fUSD {
    background-position: 0 -1103px;
  }
}

.lb-flag.flag96x64 {
  width: 96px;
  height: 64px;
  border-radius: $borderRadius;

  &.fAED {
    background-position: 0 0;
  }
  &.fAMD {
    background-position: 0 -64px;
  }
  &.fAUD {
    background-position: 0 -130px;
  }
  &.fAVD {
    background-position: 0 -195px;
  }
  &.fAZM {
    background-position: 0 -262px;
  }
  &.fBGN {
    background-position: 0 -326px;
  }
  &.fBYB {
    background-position: 0 -390px;
  }
  &.fCAD {
    background-position: 0 -457px;
  }
  &.fCHF {
    background-position: 0 -521px;
  }
  &.fCZK {
    background-position: 0 -588px;
  }
  &.fDKK {
    background-position: 0 -652px;
  }
  &.fEUR {
    background-position: 0 -717px;
  }
  &.fGBP {
    background-position: 0 -784px;
  }
  &.fGEL {
    background-position: 0 -848px;
  }
  &.fHKD {
    background-position: 0 -913px;
  }
  &.fILS {
    background-position: 0 -979px;
  }
  &.fJPY {
    background-position: 0 -1044px;
  }
  &.fKZT {
    background-position: 0 -1109px;
  }
  &.fLTL {
    background-position: 0 -1174px;
  }
  &.fLVL {
    background-position: 0 -1239px;
  }
  &.fMDL {
    background-position: 0 -1305px;
  }
  &.fNOK {
    background-position: 0 -1369px;
  }
  &.fPLN {
    background-position: 0 -1435px;
  }
  &.fRUB {
    background-position: 0 -1500px;
  }
  &.fRUR {
    background-position: 0 -1565px;
  }
  &.fSEK {
    background-position: 0 -1630px;
  }
  &.fTRL {
    background-position: 0 -1696px;
  }
  &.fUAH {
    background-position: 0 -1761px;
  }
  &.fUKG {
    background-position: 0 -1827px;
  }
  &.fUSD {
    background-position: 0 -1891px;
  }
}

@media screen and (max-width: 1366px) {
  .fnt-22 {
    @include fontSize(18px);
  }

  .fnt-24 {
    @include fontSize(20px);
  }
}

// progress dots in product Details
.circle-black {
  background-color: $basic-grey;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;
}

.circle-grey {
  background-color: $border-grey-2;
  border-radius: 50%;
  margin-right: 11px;
  margin-top: 7px;
  //z-index: 1000;
  position: relative;
  width: 11px;
  height: 11px;
}
.line-circle {
  background-color: #e9ecf2;
  width: 1px;
  height: 18px;
  position: relative;
  right: -5px;
  margin-right: -1px;
  top: -13px;
}

.dot-row {
  &:last-child {
    .dot-main:before {
      display: none; // if there are no other rows
    }
  }

  .dot {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
    }

    &.dot-main:before {
      width: 11px;
      height: 11px;
      margin-right: 10px;
      background: $basic-grey;
    }

    &:not(.dot-main):before {
      width: 7px;
      height: 7px;
      margin-right: 12px;
      background: $border-grey-2;
      margin-left: 2px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 22px;

    .dot {
      &:after {
        content: '';
        position: absolute;
        height: 18px;
        width: 1px;
        background: $border-grey-2;
        top: calc(100% + 2px);
        left: 5px;
      }
    }
  }
}
// progress dots in product Details end

// back button
.lb-back {
  display: flex;
  align-items: center;
  @include fontSize(14px);
  cursor: pointer;
  @include fontFamily('Liberty-Nus Medium');

  .icn {
    width: 4px;
    height: 7px;
    margin-right: 10px;
  }
}
// back button end

// list styles
.list-style {
  position: relative;
  padding-left: 20px;
  margin: 10px 0;
  @include fontFamily('Liberty-Nus Bold');

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $nav-red;
    border-radius: 50%;
  }
}
.ovf-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.lh-20 {
  line-height: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e5e8eb;
}

.d-none {
  display: none !important;
}

.d-block {
  display: flex !important;
}

.disable {
  opacity: 0.4;
}

.lh-n {
  line-height: normal;
}

.color-light {
  color: #5c5c5e;
}
.color-dark {
  color: #212126;
}

.dis-block{
  display: block;
}