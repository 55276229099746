.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.mail-container .lb-block-head {
  padding: 0 1rem;
}
.mail-container .lb-block-head .lb-btn {
  height: 35px;
}
.mail-container .lb-block .btn-tabs {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mail-container .lb-block .btn-tabs .btn-notactive {
  margin: 0 0.9375rem 0 0;
  padding: 0 0.3125rem;
}
.mail-container .lb-block .btn-tabs .btn-active {
  color: #db0011;
  padding: 0 0.3125rem;
  height: 61px;
  margin: 0 0.9375rem 0 0;
  position: relative;
  display: flex;
  align-items: center;
}
.mail-container .lb-block .btn-tabs .btn-active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transition: 0.2s;
  background: #db0011;
}
.mail-container .lb-mail-block {
  display: flex;
  justify-content: space-between;
  min-height: 50vh;
}
.mail-container .lb-mail-block .lb-tooltip {
  padding: 0 21px;
}
.mail-container .lb-mail-block .lb-mail-item {
  height: auto;
  font-family: "Liberty-MT", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #242424;
  position: relative;
  transition: 0.3s;
  padding: 0.5rem 0.9375rem;
  border-top: 1px solid #f6f6f6;
  position: relative;
  cursor: pointer;
}
.mail-container .lb-mail-block .lb-mail-item:after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: -1px;
  width: 1px;
  background: #db0011;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: 0.3s ease;
}
.mail-container .lb-mail-block .lb-mail-item:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.mail-container .lb-mail-block .lb-mail-item.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.mail-container .lb-mail-block .lb-mail-item:after {
  left: 0 !important;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title {
  line-height: 1.2em;
  flex: 1 1;
  padding: 0 0.75rem 0 1.5625rem;
  width: 325px;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title .body-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title .checkbox-wrapper {
  margin-left: -1.5625rem;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title .checkbox-wrapper .checkmark {
  width: 1rem;
  height: 1rem;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title .checkbox-wrapper .nowrap {
  width: 307px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mail-container .lb-mail-block .lb-mail-item .lb-mail-item-title .icn.icn-warning-new svg {
  width: 16px;
  height: 16px;
}
.mail-container .lb-mail-block .lb-mail-item .icn-arr-right {
  width: 0.5rem;
  color: #000;
  line-height: 0;
  transition: 0.3s;
}
.mail-container .lb-mail-block .lb-mail-item:not(.active):hover {
  background: #fff;
}
.mail-container .lb-mail-block .lb-mail-item:not(.active):hover .icn-arr-right {
  color: #db0011;
}
.mail-container .lb-mail-block .lb-mail-item.active {
  background: #fff;
}
.mail-container .lb-mail-block .lb-mail-item.active .icn-arr-right {
  color: #db0011;
}
.mail-container .lb-mail-block .lb-mail-item.read {
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-item.read .nowrap {
  font-family: "Liberty-MT", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-item.not-read {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-item.not-read .nowrap {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}
.mail-container .lb-mail-block .mesage-item {
  font-family: "Liberty-Nus Medium" !important;
  padding-right: 30px;
}
.mail-container .lb-mail-block .mesage-item p {
  font-family: "Liberty-Nus Medium" !important;
}
.mail-container .lb-mail-block .mesage-item ul {
  list-style: inside !important;
  font-family: "Liberty-Nus Medium" !important;
}
.mail-container .lb-mail-block .mesage-item ol {
  list-style: inside !important;
}
.mail-container .lb-mail-block .mesage-item ol li {
  list-style-type: decimal !important;
}
.mail-container .lb-mail-block .mesage-item span {
  font-family: "Liberty-Nus Medium" !important;
}
.mail-container .lb-mail-block .mesage-item a {
  font-family: "Liberty-Nus Medium" !important;
  transition: 0.3s;
  color: #db0011;
}
.mail-container .lb-mail-block .mesage-item a:hover {
  text-decoration: underline;
}
.mail-container .lb-mail-block .mesage-item .attachments-file {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 35px;
}
.mail-container .lb-mail-block .mesage-item .attachments-file .downloadfile {
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  max-width: 235px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  position: relative;
  color: #242424;
  padding: 5px 8px;
  margin: 0 15px 15px 0;
  text-align: left;
  cursor: pointer;
  word-break: break-all;
  transition: 0.3s;
}
.mail-container .lb-mail-block .mesage-item .attachments-file .downloadfile:hover {
  color: #db0011;
}
.mail-container .lb-mail-block .mesage-item .attachments-file .downloadfile:before {
  content: "";
  border-bottom: 12px solid #242424;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 1px;
  bottom: 7px;
  z-index: 5;
}
.mail-container .lb-mail-block .mesage-item .attachments-file .downloadfile:after {
  content: "";
  background-color: #fff;
  width: 25px;
  height: 17px;
  position: absolute;
  right: -8px;
  bottom: 0;
}
.mail-container .lb-mail-block .mesage-item .message-date {
  color: #919191;
  font-size: 0.6875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  padding: 25px 0 39px 0;
  text-align: right;
}
.mail-container .lb-mail-block .lb-mail-left-block {
  background: #fbfbfb;
  width: 400px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}
.mail-container .lb-mail-block .lb-mail-left-block .lb-input-search {
  padding: 1.5625rem 0.9375rem 0.8125rem 0.9375rem;
}
.mail-container .lb-mail-block .lb-mail-left-block .lb-input-search .lb-input {
  background-color: #fbfbfb;
  border: 1px solid #d9d9e6;
  height: 35px;
}
.mail-container .lb-mail-block .lb-mail-left-block .lb-input-search.lb-input-out .icn-search {
  top: 56%;
  right: 7%;
}
.mail-container .lb-mail-block .lb-mail-left-block .all-checked .checkbox-wrapper {
  padding-left: 0.9375rem;
}
.mail-container .lb-mail-block .lb-mail-left-block .all-checked .checkbox-wrapper .checkmark {
  width: 1rem;
  height: 1rem;
}
.mail-container .lb-mail-block .lb-mail-left-block .all-checked .checkbox-wrapper .nowrap {
  color: #969899;
}
.mail-container .lb-mail-block .lb-mail-left-block .all-checked .icn-trash {
  width: 15px;
  height: 15px;
}
.mail-container .lb-mail-block .lb-mail-left-block .all-checked .icn-trash svg {
  width: 14px;
  height: 14px;
}
.mail-container .lb-mail-block .lb-mail-left-block .not-found-data {
  padding-top: 2.375rem;
  margin-top: 2.125rem;
}
.mail-container .lb-mail-block .lb-mail-left-block .track-vertical {
  z-index: 9;
}
.mail-container .lb-mail-block .lb-mail-details {
  width: 920px;
  position: relative;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block {
  padding: 0 0 0 1.25rem;
  min-height: 89px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-form-group {
  margin-bottom: 0;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-form-group .lb-input {
  height: 2.1875rem;
  width: 282px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-form-group .lb-input.lb-input-btn {
  width: 282px;
  border: 1px solid #242424;
  background: #fff !important;
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-form-group .lb-mail-select {
  width: 200px;
  font-size: 0.875rem;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-form-group .lb-mail-select .Select-option {
  font-size: 0.875rem;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-btn {
  height: 1.875rem;
  font-size: 0.75rem;
  padding: 0 6px;
  width: 95px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-min-btn {
  padding-right: 1.875rem;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-min-btn .lb-btn.btn-border i {
  transition: none;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-min-btn .lb-btn.btn-border:hover {
  background: transparent;
  border: 1px solid #db0011 !important;
  color: #db0011 !important;
}
.mail-container .lb-mail-block .lb-mail-details .lb-text-block .lb-min-btn .lb-btn.btn-border:hover i {
  color: #db0011 !important;
}
.mail-container .lb-mail-block .lb-mail-details .lb-form-group .lb-label {
  margin-bottom: 5px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-message {
  padding: 0 0.5rem 1.625rem 1.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.mail-container .lb-mail-block .lb-mail-details .original-message {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.9375rem;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile {
  padding: 0 0.9375rem;
  min-height: 45px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-success {
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  padding: 0 15px;
  max-width: 185px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-error {
  border: 1px solid #db0011;
  border-radius: 50px;
  padding: 0 15px;
  max-width: 185px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-error i {
  width: 11px;
  cursor: pointer;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  transition: 0.3s;
  width: 236px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress .file-name {
  min-width: 200px;
  width: 100%;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress.progress-bar {
  height: 35px;
  /* animation */
}
@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}
@-webkit-keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}
@keyframes progress-3 {
  to {
    width: 100%;
  }
}
@-webkit-keyframes progress-3 {
  to {
    width: 100%;
  }
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress.progress-bar .progress-value {
  border-radius: 8px;
  height: 33px;
  transition: 0.3s all linear;
  animation: progress-3 3s ease-in-out forwards;
  -webkit-animation: progress-3 3s ease-in-out forwards;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress.progress-bar .progress-value.progress-anima {
  background-color: #e1ffeb;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress .icn-box .icn-attach:first-child {
  display: block;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress .icn-box .icn-attach:first-child svg {
  width: 16px;
  height: 16px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress .icn-box .icn-attach:last-child {
  display: none;
  cursor: pointer;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress .icn-box .icn-attach:last-child svg {
  width: 14px;
  height: 14px;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress:hover {
  border: 1px solid #db0011;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress:hover .file-name {
  color: #db0011;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress:hover .icn-box .icn-attach:first-child {
  display: none;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-attachfile .lb-file-progress:hover .icn-box .icn-attach:last-child {
  display: block;
  color: #db0011;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-details-footer {
  padding: 1.1875rem 1.875rem 0 1.25rem;
  position: absolute;
  bottom: 35px;
  right: 0;
  width: 100%;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-details-footer .attachfile-btn {
  transition: 0.3s;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-details-footer .attachfile-btn:hover {
  color: #db0011;
}
.mail-container .lb-mail-block .lb-mail-details .lb-mail-details-footer .btn-canceled {
  height: 35px;
}
.mail-container .lb-mail-block .lb-mail-details .message-write {
  padding: 30px 23px;
}
.mail-container .lb-mail-block .lb-mail-details .message-write div.DraftEditor-root {
  height: 150px;
  overflow-y: auto;
}
.mail-container .lb-mail-block .lb-mail-details .message-write div.DraftEditor-editorContainer,
.mail-container .lb-mail-block .lb-mail-details .message-write div.public-DraftEditor-content {
  height: 100%;
}
.mail-container .lb-mail-block .lb-mail-details .message-write textarea {
  border: none;
  padding: 1.875rem 2.1875rem;
  resize: none;
}
.mail-container .lb-mail-block .lb-mail-details .message-write textarea:focus {
  outline: none;
}
.mail-container .lb-mail-block .lb-mail-details .message-write textarea::-webkit-input-placeholder {
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-details .message-write textarea::placeholder {
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.mail-container .lb-mail-block .lb-mail-details .message-write .public-DraftStyleDefault-block span[style="color: rgb(196, 196, 196); background-color: rgb(196, 196, 196);"] {
  display: inline-block;
  width: 100%;
  height: 1px;
  font-size: 0;
  margin-bottom: 25px;
}
.mail-container .lb-mail-block .lb-mail-details .message-write .public-DraftEditorPlaceholder-inner {
  font-size: 0.8125rem;
  color: #aaa;
}
.mail-container .lb-mail-block .lb-mail-details .message-write .DraftEditor-root {
  position: relative;
  padding-right: 15px;
}
.mail-container .lb-mail-block .lb-mail-details .message-write .DraftEditor-root .public-DraftEditorPlaceholder-root {
  position: absolute;
}

.important-message .lb-confirm-text {
  padding: 1.5625rem 1.875rem !important;
}
.important-message .lb-confirm-text .track-vertical {
  right: 10px;
}
.important-message .lb-confirm-text .notification-date-block {
  padding: 0;
}
.important-message .lb-confirm-text .lb-content-message {
  padding: 1rem 1.875rem 0 1.875rem;
}
.important-message .lb-confirm-text .icn-circle-tick {
  width: 36px;
  height: 36px;
}

.lb-mail {
  height: 100%;
  padding-right: 0.6875rem;
  display: flex;
  align-items: center;
  color: #b2afb2;
  cursor: pointer;
  transition: 0.3s;
}
.lb-mail:after {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  background: #464e57;
  margin-left: 0.6875rem;
}
.lb-mail .icn-mail {
  transition: 0.3s;
  width: 20px;
  height: 20px;
}
.lb-mail .icn-mail:hover {
  color: #fff;
}
.lb-mail .icn-notnotifi {
  width: 32px;
}
.lb-mail .icn-mailbox {
  background: #db0011;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  position: absolute;
  top: 1px;
  right: -2px;
}
.lb-mail .lb-tooltip {
  padding: 8px 6px;
  width: 251px;
}
.lb-mail .lb-tooltip-item {
  padding: 8px 10px;
  border-top: 1px solid #e6e6e6;
}
.lb-mail .lb-tooltip-item:before {
  content: "";
  display: inline-block;
  background: #db0011;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-right: 7px;
}
.lb-mail .lb-tooltip-item .icn-warning-new {
  width: 11px;
}
.lb-mail .lb-tooltip-item:last-child() {
  border-top: none;
}
.lb-mail .lb-tooltip-item.item-read:before {
  content: "";
  display: inline-block;
  background: #b1b1b1;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 7px;
}
.lb-mail .lb-tooltip-item .mail-date {
  color: #b1b1b1;
}
.lb-mail.lb-header-user .lb-sub-navbar {
  min-width: 251px;
  left: -108px;
  padding: 0;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
.lb-mail.lb-header-user .lb-sub-navbar .lb-subnav-item {
  padding: 0;
}
.lb-mail.lb-header-user .lb-sub-navbar .lb-subnav-item:after {
  content: none;
  -webkit-transform: none;
          transform: none;
}
.lb-mail.lb-header-user .lb-sub-navbar .lb-subnav-item:hover {
  background: #fff;
  border-left: 1px solid transparent;
  color: #242424;
}
.lb-mail.lb-header-user .lb-sub-navbar .lb-subnav-item:hover .mail-text {
  color: #db0011;
}

.important-message .attachments-file {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.important-message .attachments-file .downloadfile {
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  max-width: 330px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  color: #242424;
  padding: 5px 8px;
  margin: 0 15px 15px 0;
  text-align: left;
  cursor: pointer;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  transition: 0.3s;
}
.important-message .attachments-file .downloadfile:hover {
  color: #db0011;
}
.important-message .attachments-file .downloadfile:before {
  content: "";
  border-bottom: 12px solid #242424;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 1px;
  bottom: 7px;
  z-index: 5;
}
.important-message .attachments-file .downloadfile:after {
  content: "";
  background-color: #f7f8f9;
  width: 25px;
  height: 17px;
  position: absolute;
  right: -8px;
  bottom: 0;
}
.important-message .lb-block-head {
  background: none !important;
  border-bottom: none !important;
}
.important-message .lb-block-head .header-title {
  text-align: center;
}
.important-message .lb-block-head .header-title:after {
  content: "";
  background: #db0011;
  height: 1px;
  width: 47px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.important-message .lb-confirm-text a {
  color: #db0011;
}
.important-message .lb-confirm-text a:hover {
  text-decoration: underline;
}

.noti-mailbox .lb-confirm-text {
  padding: 2rem 2rem 0 2rem;
  font-size: 0.9375rem;
}

.notification-date {
  color: #db0011;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1.1875rem;
}

.rdw-image-imagewrapper img {
  width: 350px;
  height: auto;
}

.icn-notify {
  background: #db0011;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  top: 7px;
  left: 26px;
}

.lb-sub-navbar .icn-notify {
  background: #db0011;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  right: 4px;
  left: auto;
}
.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.lb-marketing-modal .lb-confirm-text {
  font-size: 0.8125rem;
  color: #929292;
}
.lb-marketing-modal .checkbox-wrapper .checkmark {
  margin: 0;
}
.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.lb-input {
  background: transparent;
  color: #fff;
  transition: 0.2s;
}

::-webkit-input-placeholder {
  color: #fff;
}

::-moz-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

:-moz-placeholder {
  color: #fff;
}

.lb-input:-webkit-autofill,
.lb-input:-webkit-autofill:hover,
.lb-input:-webkit-autofill:focus,
.lb-input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}

.lb-input.filled,
.lb-input:focus {
  background: #eff1f4 !important;
  color: #242424 !important;
}

.lb-input:disabled,
.lb-input.filled:disabled {
  color: #979797 !important;
}

.lb-datepicker:disabled,
.lb-datepicker.filled:disabled {
  color: #5d6163 !important;
}

.lb-input.filled:-webkit-autofill,
.lb-input.filled:-webkit-autofill:hover,
.lb-input.filled:-webkit-autofill:focus,
.lb-input.filled:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}
.popup-body img{
    margin:0;
    max-width:100%;
    max-height:300px;
}

.cropper-view-box{
    border-radius:50%;
}

.cropper-face{
    background-color:transparent;
}

.cropper-view-box{
    outline:none;
}

.popup-body .cropper-line{
    opacity: 0.01;
}

.cropper-point:not(.point-ne){
    display:none;
}

.point-ne{
    width:20px;
    height:20px;
    opacity:1;
} 
.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.transactions-page .card-select-out {
  max-width: 360px;
  width: 50%;
  z-index: 5;
}
.transactions-page > .lb-block {
  padding-bottom: 5vh;
}
.transactions-page > .lb-block .lb-block-row:last-child {
  border-bottom: 1px solid #e9ecf2;
}
.transactions-page > .lb-block .icn-circle-tick,
.transactions-page > .lb-block .icn-circle-close,
.transactions-page > .lb-block .icn-circle-clock {
  position: relative;
  top: 3px;
}
.transactions-page .lb-select .Select-placeholder {
  color: #5d6163 !important;
}
.transactions-page.smaller-form-group .lb-input:not(.lb-input-grey):not(.has-error),
.transactions-page.smaller-form-group .Select-control {
  border: 1px solid #d9d9e6;
}
.transactions-page.smaller-form-group .amountRange-input .lb-input,
.transactions-page.smaller-form-group .period-input .lb-input {
  background: #fafafa;
  transition: 0.2s;
}
.transactions-page.smaller-form-group .amountRange-input .lb-input:focus,
.transactions-page.smaller-form-group .period-input .lb-input:focus {
  background: #fff;
}
.transactions-page.smaller-form-group .amountRange-input .lb-input::-webkit-input-placeholder, .transactions-page.smaller-form-group .period-input .lb-input::-webkit-input-placeholder {
  color: #5d6163;
}
.transactions-page.smaller-form-group .amountRange-input .lb-input::placeholder,
.transactions-page.smaller-form-group .period-input .lb-input::placeholder {
  color: #5d6163;
}
.transactions-page.smaller-form-group .lb-select:not(.month-select):not(.status-select) .Select-placeholder {
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #5d6163;
}
.transactions-page .lb-trans-block .icn-arr-right {
  color: #b0b0b5;
}
.transactions-page .lb-trans-block .icn-arr-right:last-child {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  height: 24px !important;
  width: 24px !important;
  right: 16px !important;
  display: flex;
  justify-content: center;
}
.transactions-page .lb-trans-block .icn-arr-right:last-child svg {
  width: 6px;
  height: 10px;
}
.transactions-page .lb-trans-block .lb-block-row:not(:last-child) {
  border-bottom: none !important;
}
.transactions-page .lb-trans-block .lb-block-row:not(:last-child):after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e9ecf2;
  position: absolute;
  bottom: 0;
  left: 68px;
}

.lb-trans-block .icn-arr-right {
  color: #b0b0b5;
}
.lb-trans-block .icn-arr-right:last-child {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  height: 24px !important;
  width: 24px !important;
  right: 16px !important;
  display: flex;
  justify-content: center;
}
.lb-trans-block .icn-arr-right:last-child svg {
  width: 6px;
  height: 10px;
}
.lb-trans-block .lb-block-row:not(:last-child) {
  border-bottom: none !important;
}
.lb-trans-block .lb-block-row:not(:last-child):after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e9ecf2;
  position: absolute;
  bottom: 0;
  left: 68px;
}

.scroll-top {
  z-index: 99;
  position: fixed;
  right: 25px;
  bottom: 25px;
  height: 38px;
  width: 38px;
  background: transparent;
  border: 1px solid #242424;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.scroll-top:hover {
  background: #db0011;
  border: 1px solid #db0011;
  color: #fff;
}

/* ========== transactions history ========== */
.transactions-page .lb-block-filter {
  padding: 2.5rem 5.1875rem;
  background: #fafafa;
  border-bottom: 1px solid #e9ecf2;
}
.transactions-page .lb-block-filter .account-select-filter {
  max-width: 310px;
  width: 100%;
  height: 3.125rem !important;
}
.transactions-page .lb-block-filter .lb-select {
  height: 3.125rem !important;
  line-height: 1.5 !important;
}
.transactions-page .lb-block-filter .lb-select.has-error {
  border: 1px solid #db0011 !important;
}
.transactions-page .lb-block-filter .lb-input {
  height: 3.125rem !important;
  line-height: 3.125rem !important;
}
.transactions-page .lb-block-filter .lb-input.has-error {
  border: 1px solid #db0011 !important;
}
.transactions-page .lb-block-filter .lb-label {
  font-size: 0.875rem;
}
.transactions-page .lb-block-filter .lb-input.lb-input-grey {
  border: 1px solid #d9d9e6;
  background: #d9d9e6;
}
.transactions-page .lb-block-filter .trans-cat .lb-select .Select-input {
  line-height: 50px;
}
.transactions-page .lb-block-head .lb-input-search {
  width: 50%;
  max-width: 350px;
}
.transactions-page .lb-block-head .lb-input-search .lb-input-grey {
  border: 1px solid #d9d9e6;
}
.transactions-page .lb-block-subhead.lb-subhead-custom {
  padding: 1.875rem 1.875rem 1.5625rem;
  background: none;
}
.transactions-page .statements-summary {
  padding: 1rem 1.875rem !important;
}
.transactions-page .statements-summary__column {
  padding-right: 60px !important;
}
.transactions-page .card-select-out {
  max-width: 360px;
  width: 50%;
}
.transactions-page .currency-select {
  max-width: 130px;
  width: 130px;
  height: 50px !important;
}
.transactions-page .lb-input-grey {
  background: #fff !important;
  border: 1px solid #d9d9e6;
  transition: 0.3s;
}
.transactions-page .lb-input-grey .filled, .transactions-page .lb-input-grey:focus {
  background: #f3f3f7 !important;
  color: #5d6163;
}
.transactions-page .lb-input-grey .filled + .icn-search, .transactions-page .lb-input-grey:focus + .icn-search {
  color: #1d262f;
}
.transactions-page .amountRange-input .lb-input {
  width: 100%;
  max-width: 130px;
}
.transactions-page i.icn-calendar svg {
  width: 15px;
  height: 16px;
}
.transactions-page .period-input .lb-input:not([value=""]) {
  background: #fff;
  border: 1px solid #e4e6ea;
}
.transactions-page .lb-select.product-select {
  border: 0;
}
.transactions-page .lb-select.product-select .Select-value,
.transactions-page .lb-select.product-select .Select-input {
  padding: 0 2rem 0 1rem !important;
}
.transactions-page .lb-select.product-select .Select-placeholder {
  padding: 0 2rem 0 1rem !important;
}
.transactions-page .search-word {
  margin-right: 84px;
}
.transactions-page .search-word .lb-input {
  max-width: 770px;
  width: 770px;
}
.transactions-page .filtered-btn {
  margin-top: 32px;
}
.transactions-page .filtered-btn .lb-btn.btn-red {
  padding: 0 3.75rem;
  width: 100%;
  height: 50px !important;
}
.transactions-page .month-select {
  width: 170px;
}
.transactions-page .status-select {
  width: 240px;
}
.transactions-page .lb-block-filter-result {
  padding: 1.25rem 1.875rem;
  background: #fafafa !important;
  border-bottom: 1px solid #e9ecf2;
  color: #242424;
  font-size: 0.8125rem;
  display: flex;
  justify-content: space-between;
}
.transactions-page .lb-block-filter-result .icn {
  margin: 0 10px;
}
.transactions-page .lb-block-filter-result .remove-filter {
  font-size: 0.875rem;
  font-weight: bold;
}
.transactions-page .lb-block-filter-result .remove-filter .icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 11px;
  padding-top: 0;
  padding-left: 0;
  padding-left: 0;
  padding-top: 0;
}
.transactions-page .lb-block-filter-result .remove-filter .icn-dark-circle svg {
  width: 10px;
  height: 10px;
}
.transactions-page .lb-block-filter-result .remove-filter .icn-dark-circle:hover {
  background: #242424;
}
.transactions-page .lb-block-filter-result .remove-filter .icn-dark-circle:hover svg {
  color: #fff;
}
.transactions-page .lb-block-filter-result .remove-filter:hover .icn-dark-circle {
  background: #242424;
}
.transactions-page .lb-block-filter-result .remove-filter:hover .icn-dark-circle svg {
  color: #fff;
}
.transactions-page .lb-select + .lb-select {
  margin-left: 15px;
}
.transactions-page .icn-grey-circle {
  background: #c5c5c5;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transition: 0.2s;
}
.transactions-page .icn-grey-circle svg {
  width: 6px;
  height: 6px;
}
.transactions-page .icn-grey-circle:hover {
  background: #db0011;
}
.transactions-page .lb-btn.btn-dark,
.transactions-page .lb-btn.btn-white {
  width: 158px;
  height: 40px;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
}

/* ==========  transactions history  End========== */
/* ==========  transactions Details popup ========== */
.transaction-details {
  justify-content: space-between;
  margin-top: 20px;
}
.transaction-details .transaction-details-head h3 {
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 6px;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.transaction-details .transaction-details-row {
  width: 100%;
}
.transaction-details .transaction-details-row .transaction-details-box {
  padding: 0 1.8125rem;
}
.transaction-details .transaction-details-row .transaction-details-box h3 {
  border-bottom: 1px solid #e9ecf2;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-purpose {
  font-size: 1rem;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.transaction-details .transaction-details-row .transaction-details-box:last-child {
  border-bottom: none;
  padding: 0 1.8125rem;
}
.transaction-details .transaction-details-row .transaction-details-box h3 {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-weight: normal;
}
.transaction-details .transaction-details-row .transaction-details-box:last-child .transaction-details-col-group {
  margin-bottom: 0;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .icn-chat svg {
  width: 27px;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col-lable {
  font-size: 0.875rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #242424;
  opacity: 0.7;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col {
  font-size: 0.875rem;
  line-height: 15px;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col .lb-input {
  height: 40px;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col .print-box {
  padding: 1.5625rem 1.875rem;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col .print-box span {
  font-weight: bold;
  color: #1d262f;
  font-size: 0.875rem;
}
.transaction-details .transaction-details-row .transaction-details-box .transaction-details-col-group .transaction-details-col .lb-tooltip {
  max-width: 400px;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-col-group {
  align-items: center;
  margin-bottom: 16px;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-col-group .transaction-details-col-lable {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #7b7e80;
  opacity: 1;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-cat-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #f7f8f9;
  border: 1px solid #eaeaeb;
  border-radius: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-cat-box .icn {
  width: 32px;
  height: 32px;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-cat-box .icn svg {
  width: 32px;
  height: 32px;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-cat-box img {
  width: 32px;
  height: 32px;
}
.transaction-details .transaction-details-row .transaction-details-box.transaction-details-analytics .transaction-details-cat-box span {
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
  padding-left: 8px;
  padding-right: 4px;
}
.transaction-details .transaction-details-row .transaction-details-box .switch-rail {
  width: 48px;
  height: 30px;
  border-radius: 15px;
  background-color: #e5e5e5;
}
.transaction-details .transaction-details-row .transaction-details-box .switch-rail.active {
  background-color: #2ba15e;
}
.transaction-details .transaction-details-row .transaction-details-box .switch-rail.active .switch-rail-dot {
  background-color: #fff;
}
.transaction-details .transaction-details-row .transaction-details-box .switch-rail .switch-rail-dot {
  width: 26px;
  height: 26px;
  top: 2px;
  right: 19px;
}

.print-block {
  position: absolute;
  right: 32px;
}

.transaction-details-footer {
  padding: 32px 32px 24px 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.transaction-details-footer .tr-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #eaeaeb;
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 9px 16px;
  color: #242424;
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  cursor: pointer;
  margin-right: 24px;
  margin-bottom: 8px;
}
.transaction-details-footer .tr-btn:last-child {
  margin-right: 0;
}
.transaction-details-footer .tr-btn .icn {
  width: 20px;
  height: 20px;
}

.border-l {
  width: 1px;
  height: 35px;
  background-color: #e9e9e9;
}

/* ==========  transactions Details popup End========== */
.lb-popup {
  overflow-y: auto;
}

.current-tasks-slider {
  position: absolute !important;
  top: 70px;
  margin-bottom: 20px;
}

.payroll-transfer .block-filter {
  font-size: 0.875rem;
  font-weight: bold;
}
.payroll-transfer .block-filter .icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 11px;
  padding: 0;
}
.payroll-transfer .block-filter .icn-delet svg {
  width: 10px;
  height: 10px;
}
.payroll-transfer .block-filter .icn-delet:hover {
  background: #242424;
}
.payroll-transfer .block-filter .icn-delet:hover svg {
  color: #fff;
}
.payroll-transfer .block-filter .icn-edit svg {
  width: auto !important;
  height: 14px !important;
  padding-left: 5px;
}
.payroll-transfer .block-filter .icn-edit:hover {
  background: #242424;
}
.payroll-transfer .block-filter .icn-edit:hover svg {
  color: #fff;
}
.payroll-transfer .block-filter .icn-block-edit:hover .icn-dark-circle {
  background: #242424;
}
.payroll-transfer .block-filter .icn-block-edit:hover .icn-dark-circle svg {
  color: #fff;
}
.payroll-transfer .block-filter .icn-block-delet:hover .icn-dark-circle {
  background: #242424;
}
.payroll-transfer .block-filter .icn-block-delet:hover .icn-dark-circle svg {
  color: #fff;
}
.payroll-transfer .lb-block-row .loader-out .loader {
  margin-left: auto;
}
.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

/* utility management */
.empty-utility-block {
  padding: 50px;
  color: #000;
  font-size: 1rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.empty-utility-block.empty-utility-block-inside {
  padding: 0 50px;
}
.empty-utility-block .empty-utility {
  border: 1px dashed #dfdfdf;
  width: 100%;
  min-height: 330px;
  padding-top: 35px;
}
.empty-utility-block .empty-utility-bg {
  background-position: center center;
  background-repeat: no-repeat;
  height: 250px;
}
.empty-utility-block .empty-utility-bg .lb-btn:hover .icn.icn-red-circle svg {
  color: #fff;
}
.empty-utility-block .empty-utility-bg .lb-btn:hover span {
  color: #fff;
}

.empty-utility-cart-block {
  padding: 0 0;
  color: #000;
  font-size: 1rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.empty-utility-cart-block .empty-utility-cart {
  margin-bottom: 20px;
}
.empty-utility-cart-block .empty-utility-cart-list li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 13px 5px;
}
.empty-utility-cart-block .empty-utility-cart-list li:last-child {
  border-bottom: none;
}
.empty-utility-cart-block .empty-utility-cart-list .icn {
  width: 32px;
  height: 32px;
}
.empty-utility-cart-block .empty-utility-cart-list span {
  font-size: 1rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  padding-left: 16px;
}

.loading-utility {
  position: absolute;
  bottom: -9px;
}

.utility-block {
  display: flex;
  justify-content: space-between;
  min-height: 700px;
}
.utility-block .utility-main {
  width: 100%;
  min-width: 830px;
  max-width: 945px;
}
.utility-block .utility-main .lb-block-head {
  background-color: #fff;
  margin-bottom: 0;
  border-bottom: 1px solid #e4e6ea;
  height: 48px !important;
  min-height: 48px !important;
}
.utility-block .utility-cart {
  background-color: #e9e9e9;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  max-width: 375px;
  min-width: 350px;
  width: 100%;
  padding: 15px 22px;
}
.utility-block .utility-cart .utility-head-title {
  margin-bottom: 28px;
}
.utility-block .utility-cart .utility-cart-basket {
  background-image: url(/static/media/basket.75a4377f.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 200px 160px;
  min-height: 600px;
}
.utility-block .utility-cart .utility-cart-basket.fixed {
  position: fixed;
  top: 140px;
  width: 330px;
}
.utility-block .lb-select.utility-select {
  height: 90px;
  border: 1px solid #edeeee;
}
.utility-block .lb-select.utility-select .Select-value {
  padding: 0 2.5rem 0 1rem !important;
}
.utility-block .lb-select.utility-select .Select-placeholder {
  padding: 0 2.5rem 0 1rem !important;
  color: #242424;
}
.utility-block .lb-select.utility-select .Select-arrow-zone {
  padding-right: 1.2rem;
}
.utility-block .lb-select.utility-select .selected-option .friendly-name {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 0.9375rem;
  display: inline-block;
}
.utility-block .lb-select.utility-select .receiver-iban {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 0.6875rem;
  color: #74777a;
}
.utility-block .lb-select.utility-select .balance-amount {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 0.6875rem;
  color: #464a4e;
}
.utility-block .lb-select.utility-select .Select-placeholder {
  font-size: 1rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  padding-left: 20px;
}
.utility-block .lb-select.utility-select .account-detailes .po-r {
  min-height: 31px;
}
.utility-block .utility-head {
  padding: 0 10px 10px 38px;
}
.utility-block .utility-head .lb-input-search {
  width: 50%;
  max-width: 380px;
}
.utility-block .utility-head .lb-input-search .lb-input-grey {
  background: #fafafb;
  color: #000;
  transition: 0.3s;
  padding-right: 35px;
}
.utility-block .utility-head .lb-input-search .lb-input-grey .filled, .utility-block .utility-head .lb-input-search .lb-input-grey:focus {
  background: #f3f3f7 !important;
  color: #5d6163;
  color: #000;
}
.utility-block .utility-head .lb-input-search .lb-input-grey .filled + .icn-search, .utility-block .utility-head .lb-input-search .lb-input-grey:focus + .icn-search {
  color: #1d262f;
}
.utility-block .utility-head .lb-input-search .lb-input-grey::-webkit-input-placeholder {
  color: #000;
}
.utility-block .utility-head .lb-input-search .lb-input-grey::-moz-placeholder {
  color: #000;
}
.utility-block .utility-head .lb-input-search .lb-input-grey:-ms-input-placeholder {
  color: #000;
}
.utility-block .utility-head .lb-input-search .lb-input-grey:-moz-placeholder {
  color: #000;
}
.utility-block .template-add-box {
  font-size: 0.875rem;
  color: #000;
  outline: none;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  transition: 0.3s;
  cursor: pointer;
}
.utility-block .template-add-box .icn {
  margin-right: 2px;
  margin-top: 4px;
}
.utility-block .template-add-box .icn-red-circle svg {
  width: 10px;
  height: 10px;
}
.utility-block .template-add-box:hover {
  color: #db0011;
}
.utility-block .template-add-box:hover svg {
  color: #db0011;
}
.utility-block .utility-head-title {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1rem;
  color: #1d262f;
  margin-bottom: 25px;
}
.utility-block .utility-cart-info {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
}
.utility-block .utility-cart-info h3 {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 1rem;
  padding-bottom: 25px;
}
.utility-block .utility-cart-info .error-box {
  font-size: 0.75rem;
}
.utility-block .utility-cart-info .error-box .error-quick i {
  margin-right: 10px;
}
.utility-block .utility-cart-info .error-box .error-quick i svg {
  width: 15px;
  height: 15px;
}
.utility-block .draggable-utility-item {
  outline: none;
}
.utility-block .utility-block-row {
  padding-bottom: 55px;
}
.utility-block .utility-block-row.panel-bottom {
  padding-bottom: 0;
}
.utility-block .utility-block-row .checkAll-block {
  padding: 0 33px 15px 38px;
}
.utility-block .utility-block-row .checkAll-block .btn-allcheck {
  font-size: 12px;
  height: 30px;
  padding: 8px 17px;
  width: 132px;
  font-family: "Liberty-MT", Arial, sans-serif;
}

.utility-row {
  display: flex;
  padding: 3px 0;
  position: relative;
  min-height: 76px;
  background: #fff;
  border: 1.5px dashed transparent;
}
.utility-row.utility-row-tag {
  border: 1.5px dashed #d9d9e6;
  border-radius: 2px;
}
.utility-row {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
 supported by Chrome, Opera and Firefox */
}

.draggable-utility-item:nth-child(odd) .utility-row {
  background: #fafafb;
}

.utility-img-out {
  margin-left: 38px;
  margin-right: 25px;
  height: 70px;
  width: 100px;
}
.utility-img-out .utility-row-logo {
  width: 94px;
  height: 100%;
  object-fit: cover;
  max-width: none;
}

.btn-block {
  width: 24%;
  padding-right: 33px;
  align-items: center;
}
.btn-block .lb-btn {
  width: 132px;
  height: 30px;
  color: #000;
  font-family: "Liberty-MT", Arial, sans-serif;
  font-size: 0.75rem;
}

.utility-row-info {
  max-width: 470px;
  width: 100%;
  margin-right: 5px;
  padding-top: 0;
  padding-right: 25px;
}
.utility-row-info .valid-text {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.75rem;
  color: #888888;
}
.utility-row-info .template-name {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.utility-row-info .__react_component_tooltip {
  z-index: 9;
}
.utility-row-info .provider-name-block {
  max-width: 360px;
}
.utility-row-info .icn-trust {
  width: auto;
  height: auto;
}
.utility-row-info .icn-trust svg {
  width: auto;
  height: auto;
}

.utility-row-price {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1rem;
  color: #34ac34;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.utility-row-price .option-provider-select .Select-value {
  font-size: 0.75rem;
}
.utility-row-price .option-provider-select .Select-menu {
  font-size: 0.75rem;
}
.utility-row-price .option-provider-select .select-option {
  min-height: 30px;
}
.utility-row-price .provider-select-block {
  width: 137px;
}
.utility-row-price .provider-select-block .icn.icn-arrow-down {
  right: -9px;
}

.Loading-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.utility-input-out {
  font-size: 0.75rem;
  width: 25%;
  align-items: center;
}
.utility-input-out .icn-close {
  width: 26px;
  height: 30px;
  margin: 0 0 0 2px;
  text-align: center;
}
.utility-input-out .icn-close svg {
  width: 10px;
}
.utility-input-out .symbol-l {
  position: absolute;
  top: 7px;
  right: 18px;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.utility-input-out input[type=number] {
  -moz-appearance: textfield;
}
.utility-input-out input::-webkit-outer-spin-button,
.utility-input-out input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.utility-input-out .min-amount {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.6875rem;
  color: #db0011;
  text-align: right;
  padding: 0 36px 3px 0;
  position: absolute;
  right: 0;
  bottom: 0;
}
.utility-input-out .lb-input-grey {
  background: #fafafb;
  border: 1px solid #dddee2;
  width: 132px;
  height: 30px;
  font-family: "Liberty-MT", Arial, sans-serif;
  padding-right: 42px;
  color: #000;
  transition: 0.3s;
}
.utility-input-out .lb-input-grey .filled, .utility-input-out .lb-input-grey:focus {
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
}
.utility-input-out .lb-input-grey::-webkit-input-placeholder {
  color: #000;
}
.utility-input-out .lb-input-grey::-moz-placeholder {
  color: #000;
}
.utility-input-out .lb-input-grey:-ms-input-placeholder {
  color: #000;
}
.utility-input-out .lb-input-grey:-moz-placeholder {
  color: #000;
}
.utility-input-out .lb-select.select-parking {
  width: 132px;
}
.utility-input-out .lb-select.select-parking .Select-input {
  height: 30px;
}
.utility-input-out .lb-select.select-parking .Select-menu-outer {
  top: 0 !important;
}
.utility-input-out .lb-select.select-parking .Select-arrow-zone {
  padding-right: 0 !important;
}
.utility-input-out .lb-select.select-parking .Select-arrow-zone .icn-arrow-down {
  width: 10px;
}
.utility-input-out .lb-select.select-parking.is-focused .Select-control {
  border-color: #dddee2;
}

.utility-more-info {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0.2rem;
  right: 15px;
  cursor: pointer;
}

.utility-more-info span {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #1d262f;
}

.utility-more-info span + span {
  margin: 2px 0 0 0;
}

.utility-popup .lb-popup-row {
  border-bottom: 0 !important;
}
.utility-popup .popup-total-block {
  border-top: 1px solid #e9ecf2;
  padding: 1.25rem 1.5625rem 0 1.5625rem;
}
.utility-popup .popup-block-border {
  border-bottom: 1px solid #e9ecf2 !important;
}

.utility-template-page .lb-template-subhead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  padding-left: 2rem;
}
.utility-template-page .lb-template-subhead .lb-back {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.utility-template-page .lb-template-subhead .lb-back .icn {
  width: 4px;
  height: 7px;
  margin-right: 10px;
}
.utility-template-page .template-head-title {
  width: 60%;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}
.utility-template-page .lb-block-body {
  margin: 35px 53px;
  padding-bottom: 100px;
}
.utility-template-page .search-block {
  margin-bottom: 40px;
}
.utility-template-page .search-block .lb-input-search {
  display: inline-block;
}
.utility-template-page .search-block .lb-input-search .lb-input {
  width: 420px;
  height: 50px;
  color: #000;
}
.utility-template-page .search-block .lb-input-search .lb-input .filled, .utility-template-page .search-block .lb-input-search .lb-input:focus {
  background: #fafafb !important;
  color: #5d6163;
  color: #000;
}
.utility-template-page .search-block .lb-input-search .lb-input .filled + .icn-search, .utility-template-page .search-block .lb-input-search .lb-input:focus + .icn-search {
  color: #1d262f;
}
.utility-template-page .search-block .lb-input-search .lb-input::-webkit-input-placeholder {
  color: #969899;
}
.utility-template-page .search-block .lb-input-search .lb-input::-moz-placeholder {
  color: #969899;
}
.utility-template-page .search-block .lb-input-search .lb-input:-ms-input-placeholder {
  color: #969899;
}
.utility-template-page .search-block .lb-input-search .lb-input:-moz-placeholder {
  color: #969899;
}
.utility-template-page .lb-utility-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  /* utility-image Id for Specific icon  size  */
}
.utility-template-page .lb-utility-row .lb-utility-col {
  display: flex;
  align-items: center;
  width: 32%;
  margin: 0 20px 25px 0;
  height: 120px;
  color: #2a2a2a;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1rem;
  border-radius: 4px;
}
.utility-template-page .lb-utility-row .lb-utility-col:nth-child(3n+0) {
  margin-right: 0;
}
.utility-template-page .lb-utility-row .lb-utility-black {
  background: #fafafa;
  padding: 40px 30px 40px 30px;
  transition: 0.3s;
  align-items: center;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 8px rgb(243, 243, 247);
  position: relative;
  display: inline-block;
  display: flex;
}
.utility-template-page .lb-utility-row .lb-utility-black:after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  right: 0;
  height: 1px;
  background: #db0011;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
}
.utility-template-page .lb-utility-row .lb-utility-black:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.utility-template-page .lb-utility-row .lb-utility-black.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.utility-template-page .lb-utility-row .lb-utility-black:after {
  bottom: -0.1rem !important;
  height: 2px !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.utility-template-page .lb-utility-row .lb-utility-black:hover {
  background: #f4f4f8;
}
.utility-template-page .lb-utility-row .lb-utility-black:hover .utility-image:first-child {
  opacity: 0;
}
.utility-template-page .lb-utility-row .lb-utility-black:hover .utility-image:last-child {
  opacity: 1 !important;
}
.utility-template-page .lb-utility-row .lb-utility-black span {
  padding-left: 15px;
}
.utility-template-page .lb-utility-row .lb-utility-black .category-image {
  position: relative;
  width: 55px;
  height: auto;
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.utility-template-page .lb-utility-row .lb-utility-black .category-image .utility-image {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  transition: 0.3s;
}
.utility-template-page .lb-utility-row .lb-utility-white {
  background: #fafafa;
  border: 1px solid #d9d9e6;
  padding-right: 20px;
  transition: 0.3s;
}
.utility-template-page .lb-utility-row .lb-utility-white:hover {
  background: #f4f4f8;
}
.utility-template-page .lb-utility-row .lb-utility-white .logo-box {
  width: 125px;
  height: 110px;
  margin: 12px 25px 12px 12px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.utility-template-page .lb-utility-row .lb-utility-white .logo-box .utility-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.utility-template-page .lb-utility-row .lb-utility-white .provider-name {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.9375rem;
  color: #000;
  text-align: left;
  line-height: 20px;
}
.utility-template-page .lb-white-box {
  background: #fafafa;
  border: 1px solid #d9d9e6;
  padding-right: 20px;
  max-width: 375px;
  width: 100%;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 1rem;
  border-radius: 4px;
  color: #000;
  transition: 0.3s;
  cursor: default;
}
.utility-template-page .lb-white-box:hover {
  background: #f4f4f8;
}
.utility-template-page .lb-white-box .utility-logo {
  width: 134px;
  height: 96px;
  margin: 12px 40px 12px 12px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.utility-template-page .lb-white-box .utility-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.utility-template-page .lb-white-box .provider-name {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
}
.utility-template-page .small-form-group {
  max-width: 375px;
}
.utility-template-page .small-form-group .lb-label {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  color: #000;
}
.utility-template-page .small-form-group .lb-input {
  background: #eff1f4;
  border: 1px solid #d9d9e6;
  color: #000;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  height: 65px;
  font-size: 1rem;
}
.utility-template-page .small-form-group .lb-input .filled, .utility-template-page .small-form-group .lb-input:focus {
  border: 1px solid #a5a5b1 !important;
  color: #000;
}
.utility-template-page .small-form-group .lb-input::-webkit-input-placeholder {
  color: #000;
  font-size: 0.75rem;
}
.utility-template-page .small-form-group .lb-input::-moz-placeholder {
  color: #000;
  font-size: 0.75rem;
}
.utility-template-page .small-form-group .lb-input:-ms-input-placeholder {
  color: #000;
  font-size: 0.75rem;
}
.utility-template-page .small-form-group .lb-input:-moz-placeholder {
  color: #000;
  font-size: 0.75rem;
}
.utility-template-page .small-form-group .input-text {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.75rem;
  color: #c6c6c6;
  padding-top: 5px;
}
.utility-template-page .small-form-group .Select-placeholder {
  color: #000;
}
.utility-template-page .small-form-group .Select-value {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.utility-template-page .customer_info {
  border: 1px solid #dbdde0;
  border-radius: 4px;
  padding: 24px;
}
.utility-template-page .customer_info .customer_info-line div {
  padding-bottom: 12px;
  color: #626262;
  line-height: 18px;
}
.utility-template-page .customer_info .customer_info-line div:last-child {
  padding-bottom: 0;
}

/*tag block*/
.tag-name {
  border: 1px solid #e4e6ea;
  background: #fff;
  border-radius: 4px;
  padding: 0.5rem 0.3125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.75rem;
  margin: 0 5px 5px 0;
  transition: 0.3s;
  text-align: center;
}
.tag-name:before {
  content: "";
  background: #db0011;
  height: 32px;
  width: 1px;
  display: block;
  position: absolute;
  left: 0px;
  top: 2px;
  border-radius: 4px;
}
.tag-name:hover {
  background-color: #fdf0f1;
  border: 1px solid #f09097;
  cursor: pointer;
  color: #242424;
}
.tag-name:hover:before {
  content: none;
}
.tag-name:focus {
  outline: none;
}
.tag-name {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
 supported by Chrome, Opera and Firefox */
}

.tag-block .lb-input {
  height: 36px;
  width: 132px;
  line-height: 50px;
}
.tag-block .lb-btn.btn-red {
  height: 36px;
  font-size: 0.8125rem;
  padding: 0 0;
  min-width: 34px;
}
.tag-block .lb-btn.btn-red .icn {
  width: 13px;
}
.tag-block .lb-btn.btn-red .icn svg {
  color: #fff;
}
.tag-block .tag-edit {
  margin: 0 5px 5px 0;
}
.tag-block .tag-active .tag-name {
  background-color: #fdf0f1;
  border: 1px solid #f09097;
  cursor: pointer;
  color: #242424;
}
.tag-block .tag-active .tag-name:before {
  content: none;
}
.tag-block .tag-drag:focus {
  outline: none;
}
.tag-block .tag-drag.is-dragging .tag-btn {
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
}
.tag-block .opacity {
  opacity: 0.4;
  cursor: default !important;
}
.tag-block .opacity:hover {
  color: currentColor;
  background-color: #fff;
}
.tag-block .opacity .tag-name:hover {
  color: currentColor;
}
.tag-block .tag-btn {
  text-align: center;
}
.tag-block .tag-btn:focus {
  outline: none;
}
.tag-block .tag-btn .tag-name {
  width: 170px;
}
.tag-block .tag-grid {
  padding-top: 17px;
}
.tag-block .tag-grid div:nth-child(5) .tag-name {
  margin: 0 0 5px 0;
}
.tag-block .not-tagged .tag-name {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  width: 169px;
}
.tag-block .not-tagged .tag-name:before {
  content: "";
  background: #242424;
  height: 32px;
  width: 1px;
  display: block;
  position: absolute;
  left: 0px;
  top: 2px;
  border-radius: 4px;
}
.tag-block .not-tagged .tag-name:hover {
  border: 1px solid #242424;
  cursor: pointer;
  color: #242424;
}
.tag-block .not-tagged .opacity .tag-name {
  cursor: default !important;
}
.tag-block .nottag-active .tag-name {
  background-color: #f4f4f8;
  border: 1px solid #242424;
  cursor: pointer;
  color: #242424;
}
.tag-block .nottag-active .tag-name:before {
  content: none;
}
.tag-block .nottag-active .tag-name:hover {
  border: 1px solid #242424;
}
.tag-block .tag-control {
  position: absolute;
  right: 3px;
  top: -3px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.tag-block .tag-control .icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 2px;
  transition: 0.3s;
}
.tag-block .tag-control .icn-dark-circle svg {
  width: 10px;
  height: 10px;
}
.tag-block .tag-control .icn-dark-circle:hover {
  background: #242424;
}
.tag-block .tag-control .icn-dark-circle:hover svg {
  color: #fff;
}
.tag-block .tag-control .icn-edit svg {
  width: 20px;
  height: 20px;
  padding-left: 4px;
}
.tag-block .show-hide {
  width: 15px;
  margin-left: auto;
  margin-top: 73px;
  height: 20px;
}
.tag-block .show-hide .icn {
  width: 11px;
  color: #242424 !important;
}
.tag-block .show-newtag {
  height: 45px;
  padding: 30px 0 0 0;
}
.tag-block .disable-addnewtag .template-add-box {
  opacity: 0.4;
}
.tag-block .disable-addnewtag .template-add-box:hover {
  color: #242424;
}
.tag-block .disable-addnewtag .template-add-box:hover svg {
  color: #242424;
}

.current-tag {
  position: relative;
  background: #fff;
  border: 1px solid #db0011;
  border-radius: 4px;
  padding: 2px 5px;
  text-align: center;
  display: inline-flex;
  margin-top: 6px;
  color: #db0011;
}
.current-tag:hover {
  color: #db0011;
  cursor: pointer;
}

.tag-delete {
  position: absolute;
  right: -10px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.tag-delete .icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 2px;
  transition: 0.3s;
}
.tag-delete .icn-dark-circle svg {
  width: 5px;
  height: 5px;
}
.tag-delete .icn-dark-circle:hover {
  background: #242424;
}
.tag-delete .icn-dark-circle:hover svg {
  color: #fff;
}

.addtag-modal {
  overflow: visible !important;
}

/* @media query */
@media screen and (max-width: 1465px) {
  .utility-block .utility-head .tag-block .tag-btn .tag-name {
    width: 158px;
  }
}
@media screen and (max-width: 1440px) {
  .utility-block .utility-block-row .utility-row-info {
    max-width: 375px;
    padding-right: 37px;
  }
  .utility-block .utility-block-row .utility-row-info .identifier {
    width: 200px;
  }
  .utility-row-info {
    max-width: 375px;
    padding-right: 47px;
  }
  .identifier {
    width: 150px;
  }
}
@media screen and (max-width: 1395px) {
  .utility-block .utility-head .tag-block .tag-btn .tag-name {
    width: 150px;
  }
}
@media screen and (max-width: 1366px) {
  .utility-block .utility-block-row .utility-row-info {
    max-width: 375px;
  }
  .utility-block .utility-block-row .utility-row-info .identifier {
    max-width: 160px;
    width: auto;
  }
  .utility-row-info {
    max-width: 375px;
  }
  .identifier {
    width: 160px;
  }
}
@media screen and (max-width: 1366px) {
  .utility-block .utility-head .tag-block .tag-btn .tag-name {
    width: 150px;
  }
  .utility-row-info .provider-name-block {
    max-width: 250px;
  }
}
@media screen and (max-width: 1320px) {
  .utility-block .utility-head .tag-block .tag-btn .tag-name {
    width: 147px;
  }
}
@media screen and (max-width: 1280px) {
  .utility-block .utility-cart .utility-cart-basket.fixed {
    position: static;
    width: auto;
    top: 0;
  }
  .utility-block .utility-head .tag-block .tag-btn .tag-name {
    width: 147px;
  }
}
.symbol-l {
  position: absolute;
  top: 7px;
  right: 18px;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}

.lb-navbar .lb-badge {
  top: 9px;
  right: -18px;
}

.lb-bottom-header .lb-badge {
  width: 18px;
  height: 18px;
  top: 24px;
  right: -14px;
}

.lb-badge {
  background-color: #f7ddda;
  width: 21px;
  height: 21px;
  font-size: 0.75rem;
  color: #d8433d;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 9px;
  right: -18px;
  -webkit-transform: translateY(-50%) translateX(50%);
          transform: translateY(-50%) translateX(50%);
  padding: 4px 5px;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}

.lb-badge-new {
  width: auto;
  height: 22px;
  right: -26px !important;
  border-radius: 8px !important;
}

.opacity-75 {
  opacity: 0.75;
}

.icn-export-wrap .lb-export-btn {
  position: relative;
}
.icn-export-wrap .lb-export-btn ul {
  position: absolute;
  top: -3px;
  right: -2px;
  background: #fff;
  width: 80px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  height: auto;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.icn-export-wrap .lb-export-btn ul li {
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-left: 2px solid transparent;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  color: #5d6163;
  font-size: 14px;
}
.icn-export-wrap .lb-export-btn ul li:hover {
  border-color: #db0516;
  background-color: #e4e4e4;
  color: #5d6163;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.icn-export-wrap .lb-export-cont .loader-out {
  position: absolute;
  z-index: 10;
  left: 11px;
  top: 12px;
}
.icn-export-wrap .lb-export-select {
  border: 1px solid #646464;
  width: 45px;
}
.icn-export-wrap .lb-export-select .Select-arrow-zone {
  display: none;
}
.icn-export-wrap .lb-export-select .Select-placeholder {
  background-image: url(/static/media/export.d32d3f2d.svg);
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -99999px;
  background-size: 36%;
  padding: 0;
}
.icn-export-wrap .lb-export-select .Select-input {
  padding: 0 !important;
  width: 41px;
}
.icn-export-wrap .lb-export-select.is-open:not(.no-border) .Select-menu-outer {
  min-width: 112px;
  left: -2px;
}
.icn-export-wrap .lb-pdf-select {
  border: 1px solid #646464;
  width: 45px;
}
.icn-export-wrap .lb-pdf-select .Select-arrow-zone {
  display: none;
}
.icn-export-wrap .lb-pdf-select .Select-placeholder {
  background-image: url(/static/media/print.a175401d.svg);
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -99999px;
  background-size: 36%;
  padding: 0;
}
.icn-export-wrap .lb-pdf-select .Select-input {
  padding: 0 !important;
  width: 41px;
}
.icn-export-wrap .lb-pdf-select.is-open:not(.no-border) .Select-menu-outer {
  min-width: 112px;
  left: -2px;
}

.disabled {
  opacity: 0.6;
}

.transactions-page .corp-current-tasks .lb-block-head,
.transactions-page .corp-batch-transfer .lb-block-head {
  min-height: 75px;
}
.transactions-page .corp-current-tasks .template-add-box,
.transactions-page .corp-batch-transfer .template-add-box {
  font-size: 0.875rem;
  color: #000;
  outline: none;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  transition: 0.3s;
}
.transactions-page .corp-current-tasks .template-add-box .icn,
.transactions-page .corp-batch-transfer .template-add-box .icn {
  margin-right: 2px;
  margin-top: 5px;
}
.transactions-page .corp-current-tasks .template-add-box .icn-red-circle svg,
.transactions-page .corp-batch-transfer .template-add-box .icn-red-circle svg {
  width: 10px;
  height: 10px;
}
.transactions-page .corp-current-tasks .template-add-box:hover,
.transactions-page .corp-batch-transfer .template-add-box:hover {
  color: #db0011;
}
.transactions-page .corp-current-tasks .template-add-box:hover svg,
.transactions-page .corp-batch-transfer .template-add-box:hover svg {
  color: #db0011;
}
.transactions-page .corp-current-tasks .period-input .lb-input,
.transactions-page .corp-batch-transfer .period-input .lb-input {
  background: #fff !important;
}
.transactions-page .corp-current-tasks .amountRange-input .lb-input,
.transactions-page .corp-batch-transfer .amountRange-input .lb-input {
  background: #fff !important;
}
.transactions-page .corp-current-tasks .lb-btn.btn-add,
.transactions-page .corp-batch-transfer .lb-btn.btn-add {
  padding: 0 0.875rem;
}
.transactions-page .corp-current-tasks .lb-btn.btn-dark, .transactions-page .corp-current-tasks .lb-btn.btn-white,
.transactions-page .corp-batch-transfer .lb-btn.btn-dark,
.transactions-page .corp-batch-transfer .lb-btn.btn-white {
  width: auto;
}
.transactions-page .corp-current-tasks .lb-btn.btn-border,
.transactions-page .corp-batch-transfer .lb-btn.btn-border {
  border: 1px solid #242424;
}
.transactions-page .corp-current-tasks .lb-btn.btn-border:hover,
.transactions-page .corp-batch-transfer .lb-btn.btn-border:hover {
  border-color: transparent;
}
.transactions-page .corp-current-tasks .lb-btn.btn-link,
.transactions-page .corp-batch-transfer .lb-btn.btn-link {
  color: #242424;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.transactions-page .corp-current-tasks .lb-btn.btn-link:hover,
.transactions-page .corp-batch-transfer .lb-btn.btn-link:hover {
  color: #db0011;
}
.transactions-page .corp-current-tasks .icn-info-new,
.transactions-page .corp-batch-transfer .icn-info-new {
  transition: 0.3s;
}
.transactions-page .corp-current-tasks .icn-info-new:hover,
.transactions-page .corp-batch-transfer .icn-info-new:hover {
  color: #db0011;
}
.transactions-page .corp-current-tasks .icn-future-date,
.transactions-page .corp-batch-transfer .icn-future-date {
  transition: 0.3s;
}
.transactions-page .corp-current-tasks .icn-future-date:hover,
.transactions-page .corp-batch-transfer .icn-future-date:hover {
  color: #db0011;
}
.transactions-page .corp-current-tasks .icn-corp-add,
.transactions-page .corp-batch-transfer .icn-corp-add {
  width: 13px;
  height: 13px;
  color: #db0011;
}
.transactions-page .corp-current-tasks .icn-corp-settings,
.transactions-page .corp-batch-transfer .icn-corp-settings {
  width: 1.125rem;
  height: 1.125rem;
}
.transactions-page .corp-current-tasks .w-check,
.transactions-page .corp-batch-transfer .w-check {
  padding-right: 30px !important;
}
.transactions-page .corp-current-tasks .lb-block-filter,
.transactions-page .corp-batch-transfer .lb-block-filter {
  padding: 1.25rem 3rem;
}
.transactions-page .corp-current-tasks .lb-block-filter .filter-details .lb-form-group:first-child,
.transactions-page .corp-batch-transfer .lb-block-filter .filter-details .lb-form-group:first-child {
  width: 100%;
}
.transactions-page .corp-current-tasks .lb-block-filter .filter-details .lb-form-group:not(:nth-child(4)),
.transactions-page .corp-batch-transfer .lb-block-filter .filter-details .lb-form-group:not(:nth-child(4)) {
  margin-right: 0;
}
.transactions-page .corp-current-tasks .lb-block-filter .lb-form-group-footer .lb-input,
.transactions-page .corp-batch-transfer .lb-block-filter .lb-form-group-footer .lb-input {
  height: 2.8125rem !important;
}
.transactions-page .corp-current-tasks .lb-block-filter .lb-form-group-footer .batch-details-btn .sendcode-box .lb-input,
.transactions-page .corp-batch-transfer .lb-block-filter .lb-form-group-footer .batch-details-btn .sendcode-box .lb-input {
  padding: 0;
}
.transactions-page .corp-current-tasks .lb-block-filter .lb-form-group-footer .batch-details-btn .lb-newsms-code,
.transactions-page .corp-batch-transfer .lb-block-filter .lb-form-group-footer .batch-details-btn .lb-newsms-code {
  margin-top: 0 !important;
}
.transactions-page .corp-current-tasks .lb-block-filter .lb-form-group-footer .batch-details-btn .loader-authoriz,
.transactions-page .corp-batch-transfer .lb-block-filter .lb-form-group-footer .batch-details-btn .loader-authoriz {
  top: -21px !important;
}
.transactions-page .corp-current-tasks .lb-block-filter.current-tasksfilter .lb-search-group,
.transactions-page .corp-batch-transfer .lb-block-filter.current-tasksfilter .lb-search-group {
  height: 65px !important;
}
.transactions-page .corp-current-tasks .lb-block-filter.current-tasksfilter .lb-form-group,
.transactions-page .corp-batch-transfer .lb-block-filter.current-tasksfilter .lb-form-group {
  height: 100px;
}
.transactions-page .corp-current-tasks .lb-block-filter.current-tasksfilter .lb-select.is-open .Select-control,
.transactions-page .corp-batch-transfer .lb-block-filter.current-tasksfilter .lb-select.is-open .Select-control {
  background: #f3f3f7;
}
.transactions-page .corp-current-tasks .lb-block-filter.current-tasksfilter .lb-select.is-open:not(.no-border) .Select-control,
.transactions-page .corp-batch-transfer .lb-block-filter.current-tasksfilter .lb-select.is-open:not(.no-border) .Select-control {
  border-top: 1px solid #d9d9e6 !important;
  border-left: 1px solid #d9d9e6 !important;
  border-right: 1px solid #d9d9e6 !important;
  border-bottom: 1px solid #d9d9e6 !important;
}
.transactions-page .corp-current-tasks .lb-block-filter.batch-filter .lb-search-group,
.transactions-page .corp-batch-transfer .lb-block-filter.batch-filter .lb-search-group {
  height: 65px !important;
}
.transactions-page .corp-current-tasks .lb-block-filter.batch-filter .lb-form-group,
.transactions-page .corp-batch-transfer .lb-block-filter.batch-filter .lb-form-group {
  height: 100px;
}
.transactions-page .corp-current-tasks .lb-block-filter.batch-filter .lb-form-group .lbd-inputs .datepicker-out,
.transactions-page .corp-batch-transfer .lb-block-filter.batch-filter .lb-form-group .lbd-inputs .datepicker-out {
  width: 100%;
}
.transactions-page .corp-current-tasks .lb-block-filter.batch-filter .lb-select.is-open .Select-control,
.transactions-page .corp-batch-transfer .lb-block-filter.batch-filter .lb-select.is-open .Select-control {
  background: #f3f3f7;
}
.transactions-page .corp-current-tasks .lb-block-filter.batch-filter .lb-select.is-open:not(.no-border) .Select-control,
.transactions-page .corp-batch-transfer .lb-block-filter.batch-filter .lb-select.is-open:not(.no-border) .Select-control {
  border-top: 1px solid #d9d9e6 !important;
  border-left: 1px solid #d9d9e6 !important;
  border-right: 1px solid #d9d9e6 !important;
  border-bottom: 1px solid #d9d9e6 !important;
}
.transactions-page .corp-current-tasks .lb-block-filter .country-select,
.transactions-page .corp-batch-transfer .lb-block-filter .country-select {
  z-index: 4;
}
.transactions-page .corp-current-tasks .lb-block-filter .country-select .Select-input,
.transactions-page .corp-batch-transfer .lb-block-filter .country-select .Select-input {
  height: auto !important;
}
.transactions-page .corp-current-tasks .lb-form-group-out,
.transactions-page .corp-batch-transfer .lb-form-group-out {
  display: flex;
  align-items: center;
}
.transactions-page .corp-current-tasks .lb-form-group-out .datepicker-out .lb-datepicker.lb-input,
.transactions-page .corp-batch-transfer .lb-form-group-out .datepicker-out .lb-datepicker.lb-input {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
  padding: 0 0.875rem;
}
.transactions-page .corp-current-tasks .lb-form-group-out .lb-input-search .lb-input,
.transactions-page .corp-batch-transfer .lb-form-group-out .lb-input-search .lb-input {
  font-size: 0.875rem;
  line-height: 50px;
  padding-right: 35px;
}
.transactions-page .corp-current-tasks .lb-form-group-out .lb-input-search .lb-input::-webkit-input-placeholder,
.transactions-page .corp-batch-transfer .lb-form-group-out .lb-input-search .lb-input::-webkit-input-placeholder {
  color: #5d6163;
}
.transactions-page .corp-current-tasks .lb-form-group-out .lb-input-search .lb-input::-moz-placeholder,
.transactions-page .corp-batch-transfer .lb-form-group-out .lb-input-search .lb-input::-moz-placeholder {
  color: #5d6163;
}
.transactions-page .corp-current-tasks .lb-form-group-out .lb-input-search .lb-input:-ms-input-placeholder,
.transactions-page .corp-batch-transfer .lb-form-group-out .lb-input-search .lb-input:-ms-input-placeholder {
  color: #5d6163;
}
.transactions-page .corp-current-tasks .lb-form-group-out .lb-input-search .lb-input:-moz-placeholder,
.transactions-page .corp-batch-transfer .lb-form-group-out .lb-input-search .lb-input:-moz-placeholder {
  color: #5d6163;
}
.transactions-page .corp-current-tasks .lb-form-group-out .checkbox-wrapper,
.transactions-page .corp-batch-transfer .lb-form-group-out .checkbox-wrapper {
  font-size: 0.875rem;
}
.transactions-page .corp-current-tasks .lb-form-group,
.transactions-page .corp-batch-transfer .lb-form-group {
  width: 22%;
}
.transactions-page .corp-current-tasks .lb-form-group:first-child, .transactions-page .corp-current-tasks .lb-form-group:nth-child(3),
.transactions-page .corp-batch-transfer .lb-form-group:first-child,
.transactions-page .corp-batch-transfer .lb-form-group:nth-child(3) {
  width: 23%;
}
.transactions-page .corp-current-tasks .lb-form-group:not(:nth-child(4)),
.transactions-page .corp-batch-transfer .lb-form-group:not(:nth-child(4)) {
  margin-right: 2.9%;
}
.transactions-page .corp-current-tasks .lb-form-group:nth-child(4),
.transactions-page .corp-batch-transfer .lb-form-group:nth-child(4) {
  margin-left: auto;
}
.transactions-page .corp-current-tasks .lb-input-out,
.transactions-page .corp-batch-transfer .lb-input-out {
  display: block;
}
.transactions-page .corp-current-tasks .account-select-filter,
.transactions-page .corp-batch-transfer .account-select-filter {
  width: auto;
  max-width: none;
}
.transactions-page .corp-current-tasks .currency-select,
.transactions-page .corp-batch-transfer .currency-select {
  width: auto;
  max-width: none;
}
.transactions-page .corp-current-tasks .status-select,
.transactions-page .corp-batch-transfer .status-select {
  width: 100%;
}
.transactions-page .corp-current-tasks .block-filter-footer,
.transactions-page .corp-batch-transfer .block-filter-footer {
  display: flex;
  align-items: center;
}
.transactions-page .corp-current-tasks .block-filter-footer .amountRange-input .lb-input,
.transactions-page .corp-batch-transfer .block-filter-footer .amountRange-input .lb-input {
  background: #fff;
}
.transactions-page .corp-current-tasks .icn-corp-filter,
.transactions-page .corp-batch-transfer .icn-corp-filter {
  position: relative;
  top: 2px;
}
.transactions-page .corp-current-tasks .icn-calendar,
.transactions-page .corp-batch-transfer .icn-calendar {
  transition: 0.3s;
}
.transactions-page .corp-current-tasks .icn-calendar svg,
.transactions-page .corp-batch-transfer .icn-calendar svg {
  width: 19px;
  height: 17px;
}
.transactions-page .corp-current-tasks .lb-btn,
.transactions-page .corp-batch-transfer .lb-btn {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.875rem;
}
.transactions-page .corp-current-tasks .checkbox-wrapper .checkmark,
.transactions-page .corp-batch-transfer .checkbox-wrapper .checkmark {
  width: 16px;
  height: 16px;
}
.transactions-page .corp-current-tasks .lb-toolbar,
.transactions-page .corp-batch-transfer .lb-toolbar {
  padding: 32px;
}
.transactions-page .corp-current-tasks .lb-toolbar .lb-btn.btn-border.btn-white:disabled,
.transactions-page .corp-batch-transfer .lb-toolbar .lb-btn.btn-border.btn-white:disabled {
  color: #cbcbcb !important;
  background: #ececec !important;
  border: 1px solid transparent !important;
}
.transactions-page .corp-current-tasks .lb-toolbar .lb-btn.btn-red:disabled,
.transactions-page .corp-batch-transfer .lb-toolbar .lb-btn.btn-red:disabled {
  color: #cbcbcb !important;
  background: #ececec !important;
  border: 1px solid transparent !important;
}
.transactions-page .corp-current-tasks .lb-toolbar .amount-range,
.transactions-page .corp-batch-transfer .lb-toolbar .amount-range {
  width: 30%;
}
.transactions-page .corp-current-tasks .lb-toolbar .lb-input.lb-input-grey,
.transactions-page .corp-batch-transfer .lb-toolbar .lb-input.lb-input-grey {
  border: 1px solid #d9d9e6;
}
.transactions-page .corp-current-tasks .icn-future-date,
.transactions-page .corp-batch-transfer .icn-future-date {
  position: absolute;
  top: 50%;
  left: 1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-right: 0 !important;
  height: 17px;
  width: 17px;
}
.transactions-page .corp-current-tasks .icn-abso,
.transactions-page .corp-batch-transfer .icn-abso {
  position: absolute;
  top: 54%;
  left: 55px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-right: 0 !important;
}
.transactions-page .corp-current-tasks .lb-block-filter-result,
.transactions-page .corp-batch-transfer .lb-block-filter-result {
  display: flex;
  align-items: flex-start;
  background: #fff;
}
.transactions-page .corp-current-tasks .icn.icn-admin,
.transactions-page .corp-batch-transfer .icn.icn-admin {
  width: 21px;
  height: 21px;
}
.transactions-page .corp-current-tasks .lb-manage-user,
.transactions-page .corp-batch-transfer .lb-manage-user {
  margin: 1.375rem 1.875rem;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group {
  min-height: 102px;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group .lb-input,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group .lb-input {
  height: 50px;
  line-height: 50px;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group .lb-input::-webkit-input-placeholder, .transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group .lb-input::-webkit-input-placeholder {
  color: #242424;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group .lb-input::placeholder,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group .lb-input::placeholder {
  color: #242424;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group .lb-input.lb-input-error,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group .lb-input.lb-input-error {
  border: 1px solid #db0011 !important;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group.lb-username-group,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group.lb-username-group {
  width: auto;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group.lb-username-group .lb-input,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group.lb-username-group .lb-input {
  height: 50px;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group.lb-username-group .lb-input.lb-username,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group.lb-username-group .lb-input.lb-username {
  width: 410px;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group.lb-username-group .is-warrning,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group.lb-username-group .is-warrning {
  width: 410px;
  display: inline-block;
}
.transactions-page .corp-current-tasks .lb-manage-user .lb-form-group .lb-select,
.transactions-page .corp-batch-transfer .lb-manage-user .lb-form-group .lb-select {
  height: 50px !important;
}
.transactions-page .corp-current-tasks .lb-block-wizard,
.transactions-page .corp-batch-transfer .lb-block-wizard {
  padding: 1.375rem 1.875rem;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard {
  position: relative;
  align-self: flex-start;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item {
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  position: relative;
  display: flex;
  flex: 1 1;
  min-width: 120px;
  height: 60px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-right: 0;
  transition: 0.2s;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:first-child,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:first-child {
  padding-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1),
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1) {
  z-index: 4;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 100%;
  top: 50%;
  border-left-color: #f0f0f0;
  transition: 0.2s;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 100%;
  border-left-color: white;
  transition: 0.2s;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1) .nav-link:first-child,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1) .nav-link:first-child {
  padding-left: 25px;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2),
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) {
  margin: 0 15px;
  border-left: 0;
  z-index: 3;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 100%;
  top: 50%;
  border-left-color: #f0f0f0;
  transition: 0.2s;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 100%;
  border-left-color: white;
  transition: 0.2s;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 0;
  top: 50%;
  border-left-color: #f0f0f0;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 0;
  border-left-color: white;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(3),
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(3) {
  border-left: none;
  z-index: 2;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child {
  padding-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #eaeaea;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 0;
  top: 50%;
  border-left-color: #f0f0f0;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 0;
  border-left-color: white;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked {
  position: relative;
  background: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:before {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:after {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active {
  position: relative;
  background: #dcdddf;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:before {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:after {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover {
  position: relative;
  background: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:before {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:after {
  border-left-color: #fff;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.checked,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.checked {
  position: relative;
  background: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.checked:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.checked:before {
  border-left-color: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.checked:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.checked:after {
  border-left-color: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.active,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.active {
  position: relative;
  background: #dcdddf;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.active:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.active:before {
  border-left-color: #dcdddf;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item.active:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item.active:after {
  border-left-color: #dcdddf;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:hover,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:hover {
  position: relative;
  background: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:hover:before,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:hover:before {
  border-left-color: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item:hover:after,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item:hover:after {
  border-left-color: #eeeeef;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item .nav-link,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 45px;
  color: #242424;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-step,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-step {
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}
.transactions-page .corp-current-tasks .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-title,
.transactions-page .corp-batch-transfer .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-title {
  font-size: 0.625rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}

.reason-cancel-modal {
  min-height: 300px;
}
.reason-cancel-modal .lb-grid .lb-block-row {
  padding: 0.8125rem 0.9375rem;
}
.reason-cancel-modal .lb-grid .lb-block-row:not(.p-0) > :nth-last-child(2) {
  padding-right: 30px;
}

.save-template-modal .lb-avatar-upload .avatar-round {
  width: 95px;
  height: 95px;
}
.save-template-modal .lb-avatar-upload.corp-avatar-upload {
  padding: 25px 0;
}
.save-template-modal .checkbox-wrapper .checkmark {
  width: 14px;
  height: 14px;
}

.batch-tasks-modal .transaction-details-col {
  font-family: "Liberty-Nus Medium" !important;
  padding-bottom: 10px;
}

.settings-details {
  overflow: hidden;
}
.settings-details .corp-manage-user #label-OpenBanking span,
.settings-details .corp-manage-user #label-marketingTemplate span {
  white-space: normal;
}
.settings-details .corp-manage-user #label-OpenBanking .checkmark,
.settings-details .corp-manage-user #label-marketingTemplate .checkmark {
  min-width: 20px;
}
.settings-details .corp-manage-user #label-OpenBanking a,
.settings-details .corp-manage-user #label-marketingTemplate a {
  color: #eb293e;
  text-decoration: underline;
}
.settings-details .corp-manage-user .loader-container {
  min-height: 40vh;
}
.settings-details .corp-manage-user .role-footer .profilename-input {
  width: 365px;
  height: 45px;
  line-height: 45px;
}
.settings-details .corp-manage-user .lb-btn.btn-add {
  padding: 0 0.875rem;
  position: absolute;
  top: -50px;
  right: 1.4%;
}
.settings-details .corp-manage-user .lb-btn.btn-add:hover {
  color: #db0011;
}
.settings-details .corp-manage-user .lb-btn.btn-dark, .settings-details .corp-manage-user .lb-btn.btn-white {
  width: auto;
}
.settings-details .corp-manage-user .lb-btn.btn-border {
  border: 1px solid #242424;
}
.settings-details .corp-manage-user .lb-btn.btn-border:hover {
  border-color: transparent;
}
.settings-details .corp-manage-user .lb-btn.btn-link {
  color: #242424;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.settings-details .corp-manage-user .lb-btn.btn-link:hover {
  color: #db0011;
}
.settings-details .corp-manage-user .checkmark {
  margin-left: 0 !important;
}
.settings-details .corp-manage-user .checkbox-wrapper span {
  order: 2 !important;
}
.settings-details .corp-manage-user .checkbox-wrapper .checkmark {
  order: 1 !important;
}
.settings-details .corp-manage-user .icn-corp-add {
  width: 0.75rem;
  height: 0.75rem;
}
.settings-details .corp-manage-user .icn-corp-settings {
  width: 1.125rem;
  height: 1.125rem;
}
.settings-details .corp-manage-user .lb-block {
  padding-bottom: 1vh;
}
.settings-details .corp-manage-user .lb-user-head {
  min-height: 60px;
  height: 60px;
  padding: 0 1.875rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #e9e9f1;
  background-color: #fff;
}
.settings-details .corp-manage-user .lb-block-subhead {
  background: #e6e6e6;
}
.settings-details .corp-manage-user .admin-row {
  background: #f5f5f5;
}
.settings-details .corp-manage-user .lb-block-filter {
  padding: 1.25rem 1.875rem;
}
.settings-details .corp-manage-user .lb-form-group-out {
  display: flex;
  align-items: center;
}
.settings-details .corp-manage-user .lb-form-group-out .datepicker-out .lb-datepicker.lb-input {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
  padding: 0 1rem;
}
.settings-details .corp-manage-user .lb-form-group-out .lb-input-search .lb-input {
  font-size: 0.875rem;
}
.settings-details .corp-manage-user .lb-form-group-out .lb-input-search .lb-input::-webkit-input-placeholder {
  color: #5d6163;
}
.settings-details .corp-manage-user .lb-form-group-out .lb-input-search .lb-input::-moz-placeholder {
  color: #5d6163;
}
.settings-details .corp-manage-user .lb-form-group-out .lb-input-search .lb-input:-ms-input-placeholder {
  color: #5d6163;
}
.settings-details .corp-manage-user .lb-form-group-out .lb-input-search .lb-input:-moz-placeholder {
  color: #5d6163;
}
.settings-details .corp-manage-user .lb-form-group-out .checkbox-wrapper {
  font-size: 0.875rem;
}
.settings-details .corp-manage-user .lb-form-group:nth-child(4) {
  margin-left: auto;
}
.settings-details .corp-manage-user .lb-input-out {
  display: block;
}
.settings-details .corp-manage-user .lb-datepicker {
  width: auto;
  max-width: 115px;
}
.settings-details .corp-manage-user .account-select-filter {
  width: auto;
  max-width: none;
}
.settings-details .corp-manage-user .currency-select {
  width: auto;
  max-width: none;
}
.settings-details .corp-manage-user .status-select {
  width: 100%;
}
.settings-details .corp-manage-user .block-filter-footer {
  display: flex;
  align-items: center;
}
.settings-details .corp-manage-user .icn-corp-filter {
  position: relative;
  top: 2px;
}
.settings-details .corp-manage-user .icn-calendar {
  color: #db0011;
}
.settings-details .corp-manage-user .icn-calendar svg {
  width: 19px;
  height: 17px;
}
.settings-details .corp-manage-user .lb-btn {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.875rem;
}
.settings-details .corp-manage-user .lb-toolbar {
  padding: 32px;
}
.settings-details .corp-manage-user .lb-toolbar .lb-btn.btn-border.btn-white:disabled {
  color: #cbcbcb !important;
  background: #ececec !important;
}
.settings-details .corp-manage-user .lb-toolbar .lb-btn.btn-red:disabled {
  color: #cbcbcb !important;
  background: #ececec !important;
  border: 1px solid transparent !important;
}
.settings-details .corp-manage-user .icn-future-date {
  padding-right: 35px;
}
.settings-details .corp-manage-user .lb-block-filter-result {
  display: flex;
  align-items: flex-start;
  background: #fff;
}
.settings-details .corp-manage-user .icn.icn-admin {
  width: 21px;
  height: 21px;
}
.settings-details .corp-manage-user .lb-manage-user {
  margin: 1.375rem 1.875rem;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group {
  min-height: 107px;
  margin-bottom: 0.3125rem;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group .lb-input {
  height: 50px;
  line-height: 50px;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group .lb-input::-webkit-input-placeholder {
  color: #242424;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group .lb-input::placeholder {
  color: #242424;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group .lb-input.lb-input-error {
  border: 1px solid #db0011 !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .lb-input {
  height: 50px;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .lb-input.lb-username {
  text-transform: uppercase;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .lb-input.pr-120 {
  padding-right: 120px;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .lb-input.pr-40 {
  padding-right: 40px;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .is-warrning {
  display: inline-block;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .icn-check-box {
  position: absolute;
  right: 10px;
  top: 10px;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .icn-check-box .btn-check {
  background: #1bb964;
  color: #fff;
  width: 104px;
  height: 30px;
  font-size: 13px !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group.lb-username-group .tooltip-parent {
  display: block !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-form-group .lb-select {
  height: 50px !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-datepicker {
  width: 100% !important;
  max-width: inherit !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-passw-block {
  padding: 0;
}
.settings-details .corp-manage-user .lb-manage-user .lb-passw-block .lb-select-out {
  max-width: none;
  width: 100%;
}
.settings-details .corp-manage-user .lb-manage-user .lb-passw-block .lb-select-out .lb-select-numbrcode {
  width: 89px !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-passw-block .lb-select-out .lb-select-numbrcode .Select-control {
  height: 50px;
  width: 89px !important;
}
.settings-details .corp-manage-user .lb-manage-user .lb-passw-block .lb-select-out .lb-phone-number {
  height: 50px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.settings-details .corp-manage-user .lb-block-wizard {
  padding: 1.375rem 1.875rem;
}
.settings-details .corp-manage-user .lb-block-wizard .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard {
  position: relative;
  align-self: flex-start;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item {
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  position: relative;
  display: flex;
  flex: 1 1;
  min-width: 120px;
  height: 60px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-right: 0;
  transition: 0.2s;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:first-child {
  padding-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1) {
  z-index: 4;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 100%;
  top: 50%;
  border-left-color: #f0f0f0;
  transition: 0.2s;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1):after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 100%;
  border-left-color: white;
  transition: 0.2s;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(1) .nav-link:first-child {
  padding-left: 25px;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) {
  margin: 0 15px;
  border-left: 0;
  z-index: 3;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 100%;
  top: 50%;
  border-left-color: #f0f0f0;
  transition: 0.2s;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2):after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 100%;
  border-left-color: white;
  transition: 0.2s;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 0;
  top: 50%;
  border-left-color: #f0f0f0;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(2) .nav-link:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 0;
  border-left-color: white;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:nth-child(3) {
  border-left: none;
  z-index: 2;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child {
  padding-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #eaeaea;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(234, 234, 234, 0);
  border-width: 31px;
  margin-top: -31px;
  left: 0;
  top: 50%;
  border-left-color: #f0f0f0;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 29px;
  margin-top: -31px;
  top: calc(50% + 2px);
  left: 0;
  border-left-color: white;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked {
  position: relative;
  background: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:before {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.checked:after {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active {
  position: relative;
  background: #dcdddf;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:before {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child.active:after {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover {
  position: relative;
  background: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:before {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:last-child:hover:after {
  border-left-color: #fff;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.checked {
  position: relative;
  background: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.checked:before {
  border-left-color: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.checked:after {
  border-left-color: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.active {
  position: relative;
  background: #dcdddf;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.active:before {
  border-left-color: #dcdddf;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item.active:after {
  border-left-color: #dcdddf;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:hover {
  position: relative;
  background: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:hover:before {
  border-left-color: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item:hover:after {
  border-left-color: #eeeeef;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 45px;
  color: #242424;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-step {
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}
.settings-details .corp-manage-user .lb-block-wizard .nav.nav-wizard .nav-item .nav-link .nav-item-title {
  font-size: 0.625rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.settings-details .corp-manage-user .lb-hint {
  background: #f8f8f8;
  border: 1px #bebebe dashed;
  padding: 0.625rem 1.25rem;
  font-size: 0.8125rem;
  margin: 1.375rem 1.875rem 0;
  border-radius: 4px;
  position: relative;
}
.settings-details .corp-manage-user .lb-hint div {
  padding: 0 0 5px 15px;
}
.settings-details .corp-manage-user .lb-hint div:before {
  content: "";
  background: #585858;
  width: 4px;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  margin-top: 8px;
}
.settings-details .userroles-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
}
.settings-details .userroles-block .checkrole-warrning {
  transition: 0.3s;
}
.settings-details .userroles-block .checkrole-warrning:hover .icn-warning {
  color: #242424;
}
.settings-details .lb-tooltip-reason h4 {
  color: #919395;
  margin-bottom: 15px;
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-weight: normal;
}
.settings-details .lb-tooltip-reason ul li {
  font-size: 0.75rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.lb-hint {
  background: #eff1f4;
  padding: 1.5625rem 1.25rem;
  font-size: 0.8125rem;
  margin: 1.375rem 1.875rem;
  border-radius: 4px;
  position: relative;
}
.lb-hint div {
  padding: 0 0 5px 15px;
}
.lb-hint div:before {
  content: "";
  background: #585858;
  width: 4px;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  left: 20px;
}

.lb-user-rights {
  padding: 0 1.875rem 0;
}
.lb-user-rights .lb-addnew {
  transition: 0.3s;
  cursor: pointer;
  position: absolute;
  top: -50px;
  right: 1.4%;
}
.lb-user-rights .lb-addnew:hover {
  color: #db0011;
}
.lb-user-rights .lb-rights-slider {
  width: 100%;
  border-radius: 4px;
  position: relative;
}
.lb-user-rights .lb-rights-slider .lb-slider-prev,
.lb-user-rights .lb-rights-slider .lb-slider-next {
  position: absolute;
  cursor: pointer;
  color: #a5a5b1;
  width: 28px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  z-index: 10;
}
.lb-user-rights .lb-rights-slider .lb-slider-prev svg,
.lb-user-rights .lb-rights-slider .lb-slider-next svg {
  width: 5px;
  height: 10px;
}
.lb-user-rights .lb-rights-slider .lb-slider-next {
  right: 0;
}
.lb-user-rights .lb-rights-slider .lb-slider-next:hover {
  color: #db0011;
}
.lb-user-rights .lb-rights-slider .lb-slider-prev {
  left: 0;
}
.lb-user-rights .lb-rights-slider .lb-slider-prev:hover {
  color: #db0011;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow {
  background: #e6e6e6;
  width: 100%;
  height: 85px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 0 30px;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 215px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block:hover {
  border: 1px solid #ccc;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block:hover span {
  color: #db0011;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block.active {
  border: 1px solid #969899;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block.active span {
  color: #db0011;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block .icn {
  margin-right: 8px;
}
.lb-user-rights .lb-rights-slider .lb-slider-overflow .lb-rights-block .icn svg {
  width: 15px;
}
.lb-user-rights .lb-rights-slider .slick-list {
  height: 85px;
  padding-top: 15px;
  width: 100%;
}
.lb-user-rights .lb-rights-slider .slick-track {
  height: 56px;
  display: flex;
  overflow: hidden;
}
.lb-user-rights .lb-rights-slider .slick-track .slick-slide div:focus {
  outline: none !important;
}

.accordion {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  overflow-y: auto;
}

.fold {
  border-bottom: 1px solid rgba(34, 36, 38, 0.35);
}
.fold .fold_trigger {
  text-align: start;
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border-bottom: 1px solid rgba(34, 36, 38, 0.35);
}
.fold .fold_trigger:before {
  font-family: FontAwesome;
  content: "\F107";
  display: block;
  float: left;
  padding-right: 1rem;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
  -webkit-transform-origin: 20%;
          transform-origin: 20%;
  color: rgba(34, 36, 38, 0.35);
}
.fold .fold_trigger.open:before {
  -webkit-transform: rotateZ(-180deg);
          transform: rotateZ(-180deg);
}
.fold .fold_content {
  display: none;
  max-height: 0;
  opacity: 0;
  transition: max-height 400ms linear;
}
.fold .fold_content.open {
  display: block;
  max-height: 400px;
  opacity: 1;
}

.lb-check-out {
  padding: 0.625rem 1.875rem;
}
.lb-check-out .lb-select-check {
  width: 126px;
}

.chek-select-all .checkbox-wrapper .checkmark {
  width: 1rem;
  height: 1rem;
}
.chek-select-all .checkbox-wrapper input:checked ~ .checkmark {
  background-color: #fff !important;
  border-color: #242424 !important;
}
.chek-select-all .checkbox-wrapper input:checked ~ .checkmark svg {
  color: #242424 !important;
}
.chek-select-all .checkbox-wrapper .checkmark {
  border: 1px solid #242424;
}

.lb-accordion {
  padding: 0 1.875rem;
}
.lb-accordion .lb-accordion-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 1px;
}
.lb-accordion .lb-accordion-block .lb-accordion-header {
  margin-bottom: 1px;
  background-color: #e6e6e6;
  height: 40px;
}
.lb-accordion .lb-accordion-block .lb-accordion-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.lb-accordion .lb-accordion-block .lb-accordion-header .lb-btn-accord {
  background: transparent;
  height: 40px !important;
  padding: 0 0.6875rem !important;
}
.lb-accordion .lb-accordion-block .lb-accordion-header .icn {
  width: 12px;
  height: 12px;
}
.lb-accordion .lb-accordion-block .checkbox-wrapper input:checked ~ .checkmark {
  border-color: #242424 !important;
  background-color: #fff !important;
}
.lb-accordion .lb-accordion-block .lb-accordion-body {
  flex: 1 1 auto;
}
.lb-accordion .lb-accordion-block .lb-accordion-body .lb-checkbox-label {
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 4.375rem 0;
}
.lb-accordion .lb-accordion-block .lb-accordion-body .lb-checkbox-label.checked {
  background: #f9f9f9;
}
.lb-accordion .lb-accordion-block .lb-accordion-body .lb-checkbox-label.notchecked {
  background: #fdfdfd;
}
.lb-accordion .lb-accordion-block .lb-accordion-body .lb-checkbox-label:last-child {
  margin-bottom: 4px;
}
.lb-accordion .lb-accordion-block .checkbox-wrapper {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-accordion .lb-accordion-block .checkbox-wrapper .checkmark {
  width: 1rem;
  height: 1rem;
  border: 1px solid #242424;
}
.lb-accordion .lb-accordion-block .checkbox-wrapper .checkmark svg {
  color: #242424;
}

.lb-profile-group {
  min-height: 100px;
}
.lb-profile-group .lb-input {
  height: 50px !important;
  width: 440px;
}
.lb-profile-group .lb-input.lb-input-error {
  border: 1px solid #db0011 !important;
}

.lb-openbank-block {
  background-color: #f8f8f8;
  border: 2px dashed #e6e6e6;
  border-radius: 4px;
  padding: 19px 16px;
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
  margin: 0 1.875rem 0.9375rem;
}
.lb-openbank-block .switch-rail {
  width: 37px;
  height: 19px;
  border-radius: 11px;
}
.lb-openbank-block .switch-rail .switch-rail-dot {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2509803922);
  top: -2px;
}
.lb-openbank-block .switch-rail.not-active {
  background: #f2f2f2;
  border: 1px solid #e5e5e5;
}
.lb-openbank-block .switch-rail.active {
  background: #db0011;
  border: 1px solid #9a121c;
}
.lb-openbank-block .switch-rail.active .switch-rail-dot {
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}
.lb-openbank-block .switch-rail.not-active .switch-rail-dot {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.filter-item {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-manage {
  display: flex;
  align-items: center;
  margin: 5px 0 0 10px;
}
.filter-manage .icn {
  border-radius: 50%;
  height: 1.3125rem;
  width: 1.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-manage .icn.icn-pen {
  margin-left: 0;
}
.filter-manage .icn.icn-pen svg {
  height: 12px;
  width: 15px;
  position: relative;
  right: -3px;
}
.filter-manage .icn.icn-trash {
  margin-left: 0;
}
.filter-manage .icn.icn-trash svg {
  height: 11px;
  width: 9px;
}
.filter-manage .icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.filter-manage .icn-dark-circle svg {
  width: 10px;
  height: 10px;
}
.filter-manage .icn-dark-circle:hover {
  background: #242424;
}
.filter-manage .icn-dark-circle:hover svg {
  color: #fff;
}
.filter-manage div:hover .icn-dark-circle {
  background: #242424;
}
.filter-manage div:hover .icn-dark-circle svg {
  color: #fff;
}

/*popup*/
.icn-warning-new:hover {
  color: #db0011;
}

.lb-grid {
  padding: 22px 7px;
}
.lb-grid .lb-block-subhead {
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium";
  color: #000;
  padding: 0.375rem 1.875rem;
}
.lb-grid .lb-block-subhead > :last-child {
  padding-right: 22px;
}
.lb-grid.history-grid .lb-block-row {
  margin-bottom: 15px;
}
.lb-grid .lb-block-row {
  background: #efefef;
  border-radius: 4px;
  margin-bottom: 2px;
  margin: 0 15px 2px 15px;
  padding: 1rem 0.9375rem;
}
.lb-grid .lb-block-row .is-title {
  font-size: 0.8125rem;
  color: #242424;
  font-family: "Liberty-Nus Medium";
}
.lb-grid .lb-block-row .is-name {
  font-size: 0.8125rem;
  color: #242424;
}
.lb-grid .lb-block-row .is-subname {
  font-size: 0.8125rem;
  color: #bfbfbf;
}
.lb-grid .lb-block-row .is-subtext {
  font-size: 0.8125rem;
  color: #242424;
  font-family: "Liberty-Nus Bold";
}
.lb-grid .lb-block-row .is-amount {
  font-size: 0.8125rem;
  color: #242424;
  font-family: "Liberty-MT Medium";
}
.lb-grid .lb-block-row .is-amount span {
  font-family: "Liberty-MT Medium";
}
.lb-grid .lb-block-row:not(.p-0) > :nth-last-child(2) {
  padding-right: 22px;
}
.lb-grid .lb-block-row .icn-grey-circle {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #242424;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transition: 0.3s;
}
.lb-grid .lb-block-row .icn-grey-circle svg {
  width: 5px;
  height: 5px;
}
.lb-grid .lb-block-row:hover {
  background: #efefef;
}
.lb-grid .lb-block-row:hover .icn-grey-circle {
  background: #db0011;
  color: #fff;
}

.lb-popup-footer .lb-trans-info {
  color: #242424;
  transition: 0.3s;
}
.lb-popup-footer .lb-trans-info:hover .icn-create-copy {
  color: #db0011;
}
.lb-popup-footer .lb-trans-info:hover span {
  color: #db0011;
}
.lb-popup-footer .lb-trans-info:hover .icn-link span {
  color: #242424;
}
.lb-popup-footer .canceled-reason {
  width: 100%;
  max-width: 314px;
}
.lb-popup-footer .canceled-reason .lb-input-grey {
  background: #edeef3;
  height: 45px;
  font-size: 0.8125rem;
}
.lb-popup-footer .canceled-reason span {
  color: #242424;
}
.lb-popup-footer .details-canceled-reason {
  width: 100%;
  max-width: 515px;
}
.lb-popup-footer .details-canceled-reason .lb-input-grey {
  background: #edeef3;
  height: 45px;
  font-size: 0.8125rem;
}
.lb-popup-footer .details-canceled-reason span {
  color: #242424;
}
.lb-popup-footer .icn.icn-bg {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6e6e6;
  transition: 0.3s;
  cursor: pointer;
}
.lb-popup-footer .icn.icn-bg.icn-pen {
  color: #242424;
  margin-left: 0;
}
.lb-popup-footer .icn.icn-bg.icn-pen svg {
  height: 12px;
  width: 15px;
  position: relative;
  right: -3px;
}
.lb-popup-footer .icn.icn-bg.icn-trash {
  color: #242424;
  margin-left: 0;
}
.lb-popup-footer .icn.icn-bg.icn-trash svg {
  height: 11px;
  width: 9px;
}
.lb-popup-footer .icn-link {
  cursor: pointer;
  transition: 0.3s;
}
.lb-popup-footer .icn-link:hover .icn.icn-bg {
  background: #242424;
  color: #fff;
}

.transaction-details-links {
  padding: 2rem 1.8125rem 0 1.8125rem;
  color: #242424;
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.transaction-details-links .btn-link {
  margin-right: 7.8%;
}
.transaction-details-links .btn-link:nth-child(2) {
  text-align: center;
}
.transaction-details-links .btn-link:nth-child(3) {
  text-align: right;
  margin-right: 0;
}
.transaction-details-links .btn-link span {
  cursor: pointer;
}
.transaction-details-links .btn-link span:hover {
  color: #db0011;
}

.transaction-details-warning {
  background: #fff;
  border: 2px dashed #e6e6e6;
  border-radius: 4px;
  padding: 10px 15px;
  width: auto;
  margin: 0.3125rem 1.8125rem;
}

.lb-popup-slider {
  position: relative;
  overflow: inherit;
}
.lb-popup-slider .slider-block {
  min-height: 300px;
}
.lb-popup-slider .lb-block {
  margin-bottom: 0;
}
.lb-popup-slider .lb-slider-prev,
.lb-popup-slider .lb-slider-next {
  position: absolute;
  top: 40%;
  cursor: pointer;
  background: #e3e3e3;
  border-radius: 50%;
  color: #fff;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.lb-popup-slider .lb-slider-prev:not(.disabled):hover,
.lb-popup-slider .lb-slider-next:not(.disabled):hover {
  background: #db0011;
}
.lb-popup-slider .lb-slider-prev:not(.disabled):hover svg,
.lb-popup-slider .lb-slider-next:not(.disabled):hover svg {
  color: #fff;
}
.lb-popup-slider .lb-slider-prev svg,
.lb-popup-slider .lb-slider-next svg {
  max-width: 0.625rem;
  max-height: 50%;
  position: relative;
  bottom: -1px;
  color: #242424;
}
.lb-popup-slider .lb-slider-prev.disabled,
.lb-popup-slider .lb-slider-next.disabled {
  opacity: 0.4;
  cursor: default;
}
.lb-popup-slider .lb-slider-prev {
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
  right: 97.5%;
}
.lb-popup-slider .lb-slider-next {
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
  left: 97.5%;
}

.lb-passw-block.addnew-password {
  padding: 1.5625rem 1.875rem 0;
}
.lb-passw-block .lb-passw-hint {
  padding: 0 0;
  font-size: 0.75rem;
  color: #242424;
  border-radius: 4px;
  position: relative;
}
.lb-passw-block .lb-passw-hint div {
  padding: 0 0 5px 15px;
}
.lb-passw-block .lb-passw-hint div:before {
  content: "";
  background: #585858;
  width: 4px;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  left: 0;
}
.lb-passw-block .lb-input-passw {
  max-width: 139px;
  width: 100%;
  height: 46px;
  background: #ececec;
  border: 1px dashed #d5d5d5;
  color: #db0011 !important;
  font-size: 1rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-passw-block .lb-input-passw::-webkit-input-placeholder {
  color: #db0011;
}
.lb-passw-block .lb-input-passw::placeholder {
  color: #db0011;
}
.lb-passw-block .lb-input-passw:focus {
  color: #db0011 !important;
}
.lb-passw-block .lb-select-out {
  max-width: 300px;
  width: 100%;
}
.lb-passw-block .lb-select-out .lb-select-numbrcode {
  width: 80px;
}
.lb-passw-block .lb-select-out .lb-select-numbrcode .Select-control {
  height: 40px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #d9d9e6;
}
.lb-passw-block .lb-select-out .lb-select-numbrcode .Select-placeholder {
  font-size: 0.75rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #242424;
}
.lb-passw-block .lb-select-out .lb-select-numbrcode .Select-arrow-zone .icn-arrow-down {
  width: 10px;
  top: 0;
  color: #bebebe;
}
.lb-passw-block .lb-select-out .lb-phone-number {
  width: 215px;
  height: 40px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.lb-passw-block .lb-select-out .lb-phone-number::-webkit-input-placeholder {
  color: #585858;
}
.lb-passw-block .lb-select-out .lb-phone-number::placeholder {
  color: #585858;
}

.user-details {
  padding: 1.875rem;
}
.user-details .user-details-row {
  width: 100%;
}
.user-details .user-details-row .user-details-col-group {
  display: flex;
  align-items: flex-start;
  padding-bottom: 0.625rem;
}
.user-details .user-details-row .user-details-col-group .user-details-col-lable {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.user-details .user-details-row .user-details-col-group .user-details-col {
  font-size: 0.8125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}

.reset-password {
  cursor: pointer;
  transition: 0.3s;
}
.reset-password:hover {
  color: #db0011;
}

.lb-passw-block .lb-select-numbrcode .Select-control {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.lb-passw-block .lb-select-numbrcode .Select-menu-outer {
  max-height: 100px !important;
}
.lb-passw-block .lb-select-numbrcode .Select-menu {
  max-height: 100px !important;
}
.lb-passw-block .lb-select-numbrcode .select-option {
  min-height: 10px !important;
}
.lb-passw-block .lb-phone .lb-phone-number {
  border-left: 1px solid #d9d9e6 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.lb-passw-block .lb-phone .lb-phone-number.has-error {
  border-left: 1px solid #db0011 !important;
}

/*popup end*/
.table-management {
  padding: 1.25rem 1.5625rem 1.875rem;
  background: #f7f8f9;
}
.table-management h3 {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  text-align: center;
  margin-bottom: 5px;
}
.table-management .table-manage {
  width: 250px;
  position: relative;
}
.table-management .table-manage.table-manage-left {
  width: 264px;
}
.table-management .table-manage.table-manage-left .table-manage-tbl {
  border: 1px dashed #dcdcdd;
  padding-right: 10px;
}
.table-management .table-manage.table-manage-left .table-manage-tbl .table-manage-row .rotated-element {
  background: #dcdcdd;
  color: #000;
  font-size: 0.75rem;
}
.table-management .table-manage.table-manage-left .table-manage-tbl .table-manage-row.is-dragging .rotated-element {
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
}
.table-management .table-manage.table-manage-right .table-manage-tbl {
  border: 1px solid #dcdcdd;
}
.table-management .table-manage.table-manage-right .table-manage-tbl .amount-row {
  background: #db0011;
  color: #fff;
  min-height: 30px;
  border-radius: 4px;
  padding: 0.28rem 0.7rem;
}
.table-management .table-manage.table-manage-right .table-manage-tbl .table-manage-row .rotated-element {
  background: #db0011;
  color: #fff;
  font-size: 0.75rem;
}
.table-management .table-manage.table-manage-right .table-manage-tbl .table-manage-row.is-dragging .rotated-element {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}
.table-management .table-manage .table-manage-tbl {
  padding: 1rem 0.875rem;
  border-radius: 4px;
  height: 216px;
  overflow: hidden;
}
.table-management .table-manage .table-manage-tbl .lb-scrollbar .table-manage-row {
  margin-right: 20px;
}
.table-management .table-manage .table-manage-tbl .amount-row-out {
  height: 250px;
}
.table-management .table-manage .table-manage-tbl .table-manage-row {
  -webkit-user-select: none;
          user-select: none;
  outline: none;
  margin-bottom: 8px;
}
.table-management .table-manage .table-manage-tbl .table-manage-row .rotated-element {
  min-height: 30px;
  border-radius: 4px;
  padding: 0.28rem 0.6rem;
}
.table-management .table-manage .table-manage-tbl .table-manage-row .rotated-element .icn-close {
  height: 6px;
  width: 6px;
  color: #fff;
  opacity: 0;
  transition: 0.2s;
}
.table-management .table-manage .table-manage-tbl .table-manage-row .rotated-element:hover .icn-close {
  opacity: 1;
}
.table-management .table-manage .table-manage-tbl .table-manage-row.translate {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: translateY(38px);
          transform: translateY(38px);
}
.table-management .table-manage-icon {
  align-self: center;
  text-align: center;
  opacity: 0.3;
}
.table-management .table-manage-icon .icn-drag {
  width: 47px;
  height: 47px;
}
.table-management .lb-btn:not(.btn-link) {
  height: 40px;
}

/*Batch transfer*/
.corp-batch-transfer .lb-block-filter .icn.icn-file {
  width: 26px;
  height: 30px;
}
.corp-batch-transfer .icn-warning-new svg {
  width: 16px;
}
.corp-batch-transfer .batch-details-col-group {
  margin-bottom: 8px;
}
.corp-batch-transfer .batch-details-col-group .batch-details-col-lable {
  color: #969899;
  font-size: 0.8125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  width: 200px;
}
.corp-batch-transfer .batch-details-col-group .batch-details-col {
  color: black;
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.corp-batch-transfer .sms-input {
  padding: 0;
}
.corp-batch-transfer .lb-input-reason {
  width: 500px;
}
.corp-batch-transfer .lb-list-type div {
  position: relative;
  padding-left: 10px;
  margin: 8px 0;
}
.corp-batch-transfer .lb-list-type div:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
}
.corp-batch-transfer .lb-upload-block {
  padding: 26px;
}
.corp-batch-transfer .lb-upload-block .lb-input {
  height: 2.5rem;
}
.corp-batch-transfer .lb-upload-block .lb-select {
  height: 2.5rem !important;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file {
  width: 940px;
  min-height: 557px;
  background: #f5f5f5;
  padding: 35px 30px;
  position: relative;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .upload-box {
  background: #fff;
  border: 2px dashed #e6e6e6;
  border-radius: 4px;
  height: 40px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-upload .lb-input {
  width: 205px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-upload i.icn-close {
  right: 0.9375rem;
  width: 10px;
  height: 10px;
  font-size: 0;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out .lb-input {
  padding-right: 25px;
  width: 205px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out .lb-input::-webkit-input-placeholder {
  font-size: 0.75rem;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out .lb-input::-moz-placeholder {
  font-size: 0.75rem;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out .lb-input:-ms-input-placeholder {
  font-size: 0.75rem;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out .lb-input:-moz-placeholder {
  font-size: 0.75rem;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out i.icn-close {
  right: 0.9375rem;
  width: 10px;
  height: 10px;
  font-size: 0;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-out i.icn-close:hover {
  color: #db0011;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .icn-upload-block {
  background: #fff;
  border: 1px dashed #d9d9e6;
  padding: 9px 5px;
  border-radius: 4px;
  width: 210px;
  transition: 0.3s;
  justify-content: center;
  margin-left: 10px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .icn-upload-block:hover {
  color: #db0011;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .icn-upload-block .icn-upload svg {
  width: 16px;
  height: 16px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-form-group:first-child {
  width: 48%;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-form-group:nth-child(2) {
  width: 48%;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-form-group .lb-input {
  line-height: 40px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .format-file {
  background: #fff;
  border: 2px dashed #e6e6e6;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-change-block .lb-btn-change {
  margin-top: -7px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-change-block .icn.icn-upload {
  width: 18px;
  height: 17px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-group-date {
  width: 43.3% !important;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-group-date .icn-calendar {
  transition: 0.3s;
  cursor: pointer;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-group-date .icn-calendar:hover {
  color: #db0011;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-group-date .lb-datepicker:disabled,
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-group-date .lb-datepicker.filled:disabled {
  color: #5d6163 !important;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .upload-btn {
  position: absolute;
  bottom: 36px;
  right: 51px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-input-grey {
  background: #fff !important;
  border: 1px solid #d9d9e6;
}
.corp-batch-transfer .lb-upload-block .lb-upload-file .lb-select .disabled:hover .template-details {
  opacity: 1;
}
.corp-batch-transfer .lb-upload-block .lb-upload-resources {
  padding: 30px 20px 30px 40px;
  width: 500px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-resources .file-list-block .file-list {
  padding-bottom: 25px;
  cursor: pointer;
}
.corp-batch-transfer .lb-upload-block .lb-upload-resources .file-list-block .file-list .title-xml:hover {
  color: #db0011;
}
.corp-batch-transfer .lb-upload-block .lb-upload-resources .file-list-block .file-list .icn.icn-xls {
  height: 19px;
}
.corp-batch-transfer .lb-upload-block .lb-upload-resources .loader-file {
  position: absolute;
  left: -2px;
}
.corp-batch-transfer .lb-toolbar .checkbox-wrapper:not(:last-child) {
  margin-right: 2rem;
}
.corp-batch-transfer .lb-input-search .lb-input {
  padding-right: 2.375rem;
}
.corp-batch-transfer .progress-bar__track {
  width: 124px;
  height: 4px;
  background-color: #d9d9e6;
  position: relative;
  border-radius: 2px;
}
.corp-batch-transfer .progress-bar__bar {
  height: 4px;
  background-color: #db0011;
  position: absolute;
  border-radius: 2px;
}

.lb-edit-block {
  padding: 10px 22px 0;
}
.lb-edit-block + .lb-popup-footer {
  padding-top: 0;
}
.lb-edit-block .lb-note {
  background: #efefef;
  border: 2px dashed #bcbcbc;
  border-radius: 4px;
  padding: 7px 22px 0;
  width: 100%;
}
.lb-edit-block .lb-select.product-select .Select-value {
  padding: 0 2rem 0 1rem !important;
}
.lb-edit-block .country-select.is-focused, .lb-edit-block .country-select.has-value,
.lb-edit-block .bank-select.is-focused,
.lb-edit-block .bank-select.has-value {
  background: #fff;
  border: 1px solid #d9d9e6;
}
.lb-edit-block .country-select .Select-arrow-zone .icn-arrow-down,
.lb-edit-block .bank-select .Select-arrow-zone .icn-arrow-down {
  color: #717073;
}
.lb-edit-block .country-select .Select-control .Select-value .Select-value-label,
.lb-edit-block .bank-select .Select-control .Select-value .Select-value-label {
  padding: 0 10px;
}
.lb-edit-block .lb-select.bank-select .Select-menu-outer {
  z-index: 9;
}
.lb-edit-block .lb-select.bank-select .Select-menu-outer {
  z-index: 9;
}
.lb-edit-block .lb-form-group {
  width: 22%;
  height: 70px;
}
.lb-edit-block .lb-form-group .lb-input {
  background: #fff;
  border: 1px solid #d9d9e6;
  height: 45px;
  line-height: 45px;
  padding: 0 1.25rem 0 0.5625rem;
}
.lb-edit-block .lb-form-group .lb-input.lb-input-error {
  border: 1px solid #db0011;
}
.lb-edit-block .lb-form-group:first-child, .lb-edit-block .lb-form-group:nth-child(2) {
  width: 50%;
}
.lb-edit-block .lb-form-group:first-child {
  margin-right: 3%;
}
.lb-edit-block .lb-form-group .type-select .Select-control {
  border: none;
}
.lb-edit-block .lb-form-group .type-select.focus-red {
  height: 45px;
}
.lb-edit-block .lb-form-group .type-select .Select-input input {
  padding: 9px 0;
}
.lb-edit-block .lb-form-group .lb-select {
  height: 45px;
}
.lb-edit-block .lb-table-trans {
  border-collapse: separate;
}
.lb-edit-block .lb-table-trans .lb-table-tr {
  font-size: 0.75rem;
  color: #242424;
  border-top: 1px solid #707070;
  border-bottom: none;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.lb-edit-block .lb-table-trans .lb-table-tr:last-child {
  border-bottom: 1px solid #707070;
}
.lb-edit-block .lb-table-trans .lb-table-tr .lb-table-td {
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  padding: 8px 13px;
}
.lb-edit-block .lb-table-trans .lb-table-tr .lb-table-td:last-child {
  border-left: none;
  border-right: none;
}
.lb-edit-block .lb-table-trans .lb-table-tr .lb-table-td:first-child {
  border-left: none;
  border-right: none;
}
.lb-edit-block .amount-block .lb-form-group {
  width: 48.2%;
}
.lb-edit-block .amount-block .lb-input-out .lb-input {
  width: 165px;
}
.lb-edit-block .amount-block .lb-amount-block {
  margin: -25px 0 0 23px;
}
.lb-edit-block .amount-block .lb-amount-block > div {
  margin-bottom: 0;
}
.lb-edit-block .amount-block .lb-amount-block .radio-label:first-child {
  padding-right: 20px;
}
.lb-edit-block .amount-block .lb-amount-block .currency-info-block {
  width: 160px;
  margin-top: -5px;
}
.lb-edit-block .amount-block .lb-amount-block .currency-info-block > div span:first-child {
  display: block;
}

.edit-block-modal .loader-out.full-size {
  z-index: 7;
}
.edit-block-modal .country-select.lb-select.is-open:not(.no-border) .Select-control {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

.exchange-page.container .chart-wrap {
  padding: 5vh 1.875rem 10vh;
}
.exchange-page.container .chart-wrap.chart-block {
  padding: 0;
}
.exchange-page.container .chart-wrap .recharts-surface {
  padding: 0 1.25rem 1.5rem 0;
  box-sizing: content-box;
}
.exchange-page.container .chart-wrap .axis-x-label {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.8125rem;
  text-align: center;
}
.exchange-page.container .chart-wrap .axis-y-label {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1.1rem;
}
.exchange-page.container .chart-wrap .chart-tooltip {
  background: rgba(29, 38, 47, 0.85);
  border-radius: 4px;
  color: #fff;
  font-size: 0.8125rem;
  padding: 0.5rem 0.8rem;
}
.exchange-page.container .chart-wrap circle {
  fill: rgba(29, 38, 47, 0.85) !important;
  width: 10px;
  height: 10px;
  stroke-width: 0;
}
.exchange-page.container .chart-wrap .chart-filters .lb-select {
  height: 2.8125rem !important;
}

.my-functions .lb-block-row {
  border-left: 2px solid transparent;
}
.my-functions .lb-block-row .icn {
  width: 40px;
}
.my-functions .lb-block-row:hover {
  background: rgba(243, 243, 243, 0.44);
  border-left: 2px solid #db0011;
  color: #db0011;
}
.my-functions .lb-block-row .lb-notification {
  background: #db0011;
  color: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
  padding-right: 0 !important;
}
.my-functions .all-myfunctions span {
  position: relative;
  display: inline-block;
}
.my-functions .all-myfunctions span:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #db0011;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
}
.my-functions .all-myfunctions span:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.my-functions .all-myfunctions span.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.my-functions .all-myfunctions .icn.icn-arr-right {
  height: 10px;
}
.my-functions .all-myfunctions:hover {
  color: #db0011;
}
.my-functions .all-myfunctions:hover .icn.icn-arr-right {
  color: #db0011;
}

.current-tasks-slider .lb-slider-prev,
.current-tasks-slider .lb-slider-next {
  z-index: 5;
}
.current-tasks-slider .lb-slider-prev svg,
.current-tasks-slider .lb-slider-next svg {
  max-width: 0.625rem;
  max-height: 50%;
  position: relative;
  bottom: -1px;
}
.current-tasks-slider .lb-tooltip {
  max-width: 400px;
}

.corp-mobile {
  color: #000;
  font-size: 1rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.corp-mobile .empty-mobile-block {
  padding: 50px;
}
.corp-mobile .empty-mobile-block .empty-mobile {
  border: 1px dashed #dfdfdf;
  width: 100%;
  min-height: 400px;
  padding-top: 35px;
}
.corp-mobile .empty-mobile-block .empty-mobile-bg {
  background-position: center center;
  background-repeat: no-repeat;
  height: 350px;
}
.corp-mobile .empty-mobile-block .empty-mobile-bg .lb-btn:hover .icn.icn-red-circle svg {
  color: #fff;
}
.corp-mobile .empty-mobile-block .empty-mobile-bg .lb-btn:hover span {
  color: #fff;
}
.corp-mobile .empty-mobile-block .template-add-box {
  font-size: 0.875rem;
  color: #000;
  outline: none;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  transition: 0.3s;
  width: 100% !important;
  cursor: pointer;
}
.corp-mobile .empty-mobile-block .template-add-box .icn {
  margin-right: 2px;
  margin-top: 4px;
}
.corp-mobile .empty-mobile-block .template-add-box .icn-red-circle svg {
  width: 10px;
  height: 10px;
}
.corp-mobile .empty-mobile-block .template-add-box:hover {
  color: #db0011;
}
.corp-mobile .empty-mobile-block .template-add-box:hover svg {
  color: #db0011;
}
.corp-mobile .loader-mobile .loader-out {
  left: 83%;
  background: transparent;
}
.corp-mobile .option-provider-select .Select-value {
  font-size: 0.75rem;
}
.corp-mobile .option-provider-select .Select-value .Select-value-label > div {
  padding-right: 8px;
}
.corp-mobile .option-provider-select .Select-menu {
  font-size: 0.75rem;
}
.corp-mobile .option-provider-select .select-option {
  min-height: 30px !important;
}
.corp-mobile .utility-block .utility-input-out .lb-input-grey {
  height: 36px !important;
}
.corp-mobile .utility-block .utility-input-out .symbol-l {
  top: 10px !important;
}
.corp-mobile .utility-block .utility-input-out .icn-close {
  height: 36px !important;
}
.corp-mobile .utility-block .utility-block-row .utility-row-info {
  max-width: 324px;
}
.corp-mobile .utility-block .utility-block-row .w-315 {
  width: 315px;
}

.mobil-row-info {
  max-width: 324px !important;
}

@media screen and (max-width: 1366px) {
  .corp-mobile .utility-block .utility-block-row .utility-row-info {
    max-width: 260px;
  }
}
.add-newnumber {
  padding: 25px 25px 0 25px;
}
.add-newnumber .enter-number.lb-input-err {
  margin-top: -14px;
  margin-bottom: 10px;
}
.add-newnumber .lb-form-group {
  margin-bottom: 1rem;
}
.add-newnumber .lb-form-group .lb-label {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.add-newnumber .lb-select {
  height: 3.125rem;
}
.add-newnumber .service-select-out {
  width: 100%;
  max-width: 220px;
}
.add-newnumber .lb-input.lb-input-error {
  border: 1px solid #db0011 !important;
}
.add-newnumber .lb-input.lb-input-error + .lb-input-err {
  display: block;
  color: #db0011;
}

.lb-steps-head {
  justify-content: center !important;
}

.lb-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a3a3;
}
.lb-steps li {
  display: flex;
  align-items: center;
  font-family: "Liberty-Nus Medium";
  font-size: 0.8125rem;
  transition: 0.3s;
}
.lb-steps li:after {
  content: "";
  height: 1px;
  width: 120px;
  background: #a5a5a5;
}
.lb-steps li .icn-step {
  color: #a5a5a5;
  margin: 0 15px;
}
.lb-steps li:last-child:after {
  display: none;
}
.lb-steps li span {
  padding-right: 15px;
}
.lb-steps .is-checked {
  cursor: pointer;
}
.lb-steps .is-checked:after {
  background: #242424;
}
.lb-steps .is-checked .icn-step {
  color: #242424;
}
.lb-steps .is-checked span {
  color: #242424;
}
.lb-steps .is-checked:hover .icn-step {
  color: #db0011;
}
.lb-steps .is-checked:hover span {
  color: #db0011;
}
.lb-steps .is-active .icn-step {
  color: #db0011;
}
.lb-steps .is-active span {
  color: #db0011;
}

.future-block .lb-form-group {
  margin-bottom: 0;
}
.future-block .future-text {
  margin-top: auto;
  height: 45px;
  color: #e3e3e3;
  text-align: left;
}
.future-block .icn.icn-calendar {
  width: 19px;
  height: 17px;
}
.future-block .medium-grey {
  color: #fff !important;
}
.future-block .lb-group-date .lb-datepicker.lb-input {
  max-width: 208px;
  width: 100%;
}
.future-block .future-date {
  margin-left: 30px;
  position: relative;
  width: 100%;
}
.future-block .future-date .lb-group-date {
  position: absolute;
  left: 0;
  top: -42px;
  width: 100%;
}
.future-block .future-date .lb-datepicker.lb-input {
  max-width: 236px;
  width: 100%;
}

.progrees-c {
  border: 2px dashed #e6e6e6;
  background-color: #fff;
  padding: 0.8125rem 1.25rem;
  margin: 2.1875rem 1.125rem;
}
.progrees-c svg {
  width: 48px;
  height: 48px;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #f2d2d5;
}

.circle-progress {
  stroke: #db0011;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.calc-block .lb-form-group {
  width: auto !important;
  margin-bottom: 20px !important;
}
.calc-block .lb-form-group:nth-child(2) {
  margin-right: 0 !important;
}

.payroll-details .batch-details-col-group .batch-details-col-lable {
  width: 280px;
}
.payroll-details .warrning-block {
  position: absolute;
  right: 0;
  bottom: 0;
}

.lb-payroll-block .filter-details .lb-form-group:first-child {
  width: auto !important;
}
.lb-payroll-block .lb-btn:not(.btn-link) {
  height: 40px !important;
}
.lb-payroll-block .lb-form-group {
  width: auto !important;
}
.lb-payroll-block .lb-total-amount {
  width: 400px;
}
.lb-payroll-block .lb-total-commission {
  width: 400px;
}
.lb-payroll-block .amount-sum .batch-details-col {
  font-size: 25px;
  font-family: "Liberty-Nus Bold";
}
.lb-payroll-block .batch-detail-left .batch-details-col .icn {
  width: 13px;
  height: 13px;
}
.lb-payroll-block .batch-detail-right .batch-details-col-group {
  margin-bottom: 15px;
}
.lb-payroll-block .batch-detail-right .batch-details-col-group .lb-input {
  height: 40px !important;
  color: #242424 !important;
}
.lb-payroll-block .batch-detail-right .batch-details-col-group .lb-input.lb-input-fill {
  background-color: #ececec;
}
.lb-payroll-block .batch-detail-right .lb-input-disable {
  opacity: 0.5;
}

.lb-employee-filter .datepicker-out .lb-datepicker.lb-input {
  width: 108px;
}

.payroll-footer .btn-wide-35 {
  margin-right: 0 !important;
}

.lb-project-filter .datepicker-out .lb-datepicker.lb-input {
  width: 108px;
}

.lb-project-toolbar {
  padding: 20px !important;
}
.lb-project-toolbar .icn.icn-dark-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lb-project-toolbar .icn.icn-dark-circle svg {
  width: 12px;
  height: 12px;
}
.lb-project-toolbar .icn.icn-dark-circle.icn-trash {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.lb-project-toolbar .icn.icn-dark-circle.icn-trash svg {
  width: 10px;
  height: 10px;
}
.lb-project-toolbar .lb-employee-info {
  background: #fafafa;
  border: 2px dashed #e5e5e5;
  border-radius: 4px;
  padding: 10px 15px;
  width: auto;
}

.lb-employee-toolbar {
  padding: 20px !important;
}
.lb-employee-toolbar .icn.icn-dark-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lb-employee-toolbar .icn.icn-dark-circle svg {
  width: 12px;
  height: 12px;
}
.lb-employee-toolbar .icn.icn-dark-circle.icn-trash {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.lb-employee-toolbar .icn.icn-dark-circle.icn-trash svg {
  width: 10px;
  height: 10px;
}
.lb-employee-toolbar .loader-container {
  min-height: auto !important;
}

.lb-employee-info {
  background: #fafafa;
  border: 2px dashed #e5e5e5;
  border-radius: 4px;
  padding: 0 15px 6px;
  width: 320px;
}

.lb-employee-add {
  padding: 30px 45px 0;
}
.lb-employee-add .lb-form-group-out .lb-form-group {
  width: 31% !important;
}
.lb-employee-add .lb-form-group-out .lb-form-group:nth-child(3) {
  width: 31% !important;
}
.lb-employee-add .lb-form-group-out .lb-form-group:last-child {
  margin-right: 0 !important;
}
.lb-employee-add .nowrap {
  white-space: normal !important;
  flex: 1 1;
}
.lb-employee-add .lb-btn.btn-white {
  height: 45px !important;
}

.lb-appsend-form .lb-form-group {
  width: 100% !important;
}

@media (min-width: 1280px) and (max-height: 650px) {
  .edit-block-modal .lb-edit-block {
    overflow-y: scroll;
    height: 450px;
  }
  .payroll-transfer .corp-batch-transfer .lb-block-row.block-pointer > :nth-last-child(2) {
    padding-right: 35px;
  }
}
@media (max-width: 1280px) {
  .corp-batch-transfer .lb-block-row.block-pointer > :nth-last-child(2) {
    padding-right: 22px;
  }
}
.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

/* normalize user agent stylesheet */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  overflow-y: scroll;
  scrollbar-base-color: #d9d9d9;
  scrollbar-arrow-color: #e9e9e9;
  scrollbar-track-color: #e9e9e9;
  scrollbar-shadow-color: #d9d9d9;
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
}

html,
body {
  min-width: 1270px;
}

body {
  margin: 0;
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #242424;
  text-align: left;
  background-color: #eff1f4;
  position: relative;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  outline: 0;
  color: #242424;
  text-decoration: none;
  background-color: transparent; /* Remove the gray background on active links in IE 10. */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */
}

a:hover {
  color: #171f27;
  text-decoration: none;
}

img {
  max-width: 100%;
}

button::-moz-focus-inner {
  border: 0;
}

input::-moz-focus-inner {
  outline: 0;
}

@font-face {
  font-family: "dotsfont";
  src: url(/static/media/dotsfont.13d522c1.eot);
  src: url(/static/media/dotsfont.13d522c1.eot?#iefix) format("embedded-opentype"), url(/static/media/dotsfont.451ebc2c.svg#font) format("svg"), url(/static/media/dotsfont.0f4af9dc.woff) format("woff"), url(/static/media/dotsfont.e85a11a9.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BPG DejaVu Sans";
  src: url(/static/media/BPGDejaVuSans.b24c907e.eot);
  src: url(/static/media/BPGDejaVuSans.b24c907e.eot?#iefix) format("embedded-opentype"), url(/static/media/BPGDejaVuSans.c17cb3e0.woff) format("woff"), url(/static/media/BPGDejaVuSans.2b62448c.ttf) format("truetype"), url(/static/media/BPGDejaVuSans.775dfc99.svg#BPGDejaVuSans) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BPG DejaVu Sans";
  src: url(/static/media/BPGDejaVuSans-Bold.f4f4821a.eot);
  src: url(/static/media/BPGDejaVuSans-Bold.f4f4821a.eot?#iefix) format("embedded-opentype"), url(/static/media/BPGDejaVuSans-Bold.4ef21a3a.woff) format("woff"), url(/static/media/BPGDejaVuSans-Bold.eec290c3.ttf) format("truetype"), url(/static/media/BPGDejaVuSans-Bold.d7d9f6f8.svg#BPGDejaVuSans-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-Nus";
  src: url(/static/media/Liberty-Nus-Regular.6b697843.eot);
  src: url(/static/media/Liberty-Nus-Regular.6b697843.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-Nus-Regular.95694069.woff2) format("woff2"), url(/static/media/Liberty-Nus-Regular.f0d38281.woff) format("woff"), url(/static/media/Liberty-Nus-Regular.56b4e27f.ttf) format("truetype"), url(/static/media/Liberty-Nus-Regular.8f53f020.svg#Liberty-Nus-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-Nus Medium";
  src: url(/static/media/Liberty-Nus-Medium.4a74f639.eot);
  src: url(/static/media/Liberty-Nus-Medium.4a74f639.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-Nus-Medium.58217047.woff2) format("woff2"), url(/static/media/Liberty-Nus-Medium.e0a06ad8.woff) format("woff"), url(/static/media/Liberty-Nus-Medium.ae4e7988.ttf) format("truetype"), url(/static/media/Liberty-Nus-Medium.2aa15010.svg#Liberty-Nus-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-Nus Bold";
  src: url(/static/media/Liberty-Nus-Bold.e55f6065.eot);
  src: url(/static/media/Liberty-Nus-Bold.e55f6065.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-Nus-Bold.c2b58709.woff2) format("woff2"), url(/static/media/Liberty-Nus-Bold.6c71f928.woff) format("woff"), url(/static/media/Liberty-Nus-Bold.6c61263b.ttf) format("truetype"), url(/static/media/Liberty-Nus-Bold.645dd950.svg#Liberty-Nus-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-MT";
  src: url(/static/media/Liberty-MT-Regular.ca46c12f.eot);
  src: url(/static/media/Liberty-MT-Regular.ca46c12f.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-MT-Regular.d3c2a255.woff2) format("woff2"), url(/static/media/Liberty-MT-Regular.6e9e3406.woff) format("woff"), url(/static/media/Liberty-MT-Regular.41a7d313.ttf) format("truetype"), url(/static/media/Liberty-MT-Regular.6f6e9a07.svg#Liberty-MT-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-MT Medium";
  src: url(/static/media/Liberty-MT-Medium.6068f917.eot);
  src: url(/static/media/Liberty-MT-Medium.6068f917.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-MT-Medium.befeb11e.woff2) format("woff2"), url(/static/media/Liberty-MT-Medium.6efe0a52.woff) format("woff"), url(/static/media/Liberty-MT-Medium.12b58582.ttf) format("truetype"), url(/static/media/Liberty-MT-Medium.b1ad0f8e.svg#Liberty-MT-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Liberty-MT Bold";
  src: url(/static/media/Liberty-MT-Bold.44bee5fa.eot);
  src: url(/static/media/Liberty-MT-Bold.44bee5fa.eot?#iefix) format("embedded-opentype"), url(/static/media/Liberty-MT-Bold.0d2683e5.woff2) format("woff2"), url(/static/media/Liberty-MT-Bold.9ab75ea7.woff) format("woff"), url(/static/media/Liberty-MT-Bold.12f95004.ttf) format("truetype"), url(/static/media/Liberty-MT-Bold.0460941e.svg#Liberty-MT-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "DJB Get Digital";
  src: url(/static/media/DJBGetDigital.5e0fc922.eot);
  src: url(/static/media/DJBGetDigital.5e0fc922.eot?#iefix) format("embedded-opentype"), url(/static/media/DJBGetDigital.b301209d.woff2) format("woff2"), url(/static/media/DJBGetDigital.6d6d3452.woff) format("woff"), url(/static/media/DJBGetDigital.3bcdfcea.ttf) format("truetype"), url(/static/media/DJBGetDigital.48c0e86f.svg#DJBGetDigital) format("svg");
  font-weight: normal;
  font-style: normal;
}
/* ========== container ========== */
.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1320px;
}

.lb-container {
  min-height: 100vh;
  padding: 116px 0 5vh;
  overflow: hidden;
  position: relative;
}

@media (min-width: 1280px) and (max-height: 700px) {
  .lb-container {
    padding: 116px 0 150px;
  }
}
@media (min-width: 1280px) {
  .scrollbars {
    height: 100vh !important;
  }
}
.track-vertical {
  position: absolute;
  z-index: 10;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.thumb-vertical {
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ========== END of container ========== */
/* ========== inactive-header ========== */
.inactive-header {
  position: static !important;
}

.inactive-container {
  margin: 0;
  padding: 0;
  min-height: auto;
}

/* ========== END of inactive-header ========== */
/* ========== header ========== */
.lb-header {
  margin-bottom: 30px;
}
.lb-header.scroll {
  box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
}

.lb-header-in {
  margin-bottom: 0;
  position: fixed;
  z-index: 11;
  width: 100%;
  min-width: 1270px;
}

div.lb-top-header {
  background: #000;
  color: #fff;
  width: 100%;
}

div.lb-top-header .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 0;
}

div.lb-top-header .helpfull-links a {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #fff;
}
div.lb-top-header .helpfull-links a:hover {
  color: #fff;
}
div.lb-top-header .helpfull-links a .icn-help {
  color: #fff;
}

.lb-header-in .lb-top-header .container {
  padding: 0;
  height: 36px;
}

.lb-main-header {
  background-color: #fff;
  padding: 30px;
}

.lb-header-in .lb-main-header {
  padding: 0;
}

.lb-main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lb-header-in .lb-main-header .container {
  height: 80px;
  justify-content: flex-start;
}

.lb-bottom-header {
  background: #ffffff;
  margin-bottom: 3vh;
  position: relative;
  z-index: 4;
}
.lb-bottom-header.open + .lb-dashboard {
  margin-top: calc(50px + 3vh);
}
.lb-bottom-header > .container {
  padding: 0;
  height: 50px;
}
.lb-bottom-header.lb-header-dashboard {
  background-color: #e6e6e6;
  background-position: 100% 0;
  background-repeat: no-repeat;
  height: 50px;
}
.lb-bottom-header:not(.lb-header-dashboard) {
  box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
  border-top: 1px solid #e6e8eb;
}

.lb-bottom-header .container {
  display: flex;
  align-items: center;
}

.lb-header-dashboard .container {
  padding: 10px 0;
}

/* =========== END of header =========== */
/* =========== Footer =========== */
.lb-footer {
  background: #ffffff;
  color: #1d262f;
}

.lb-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

/* =========== END of Footer =========== */
.lb-dashboard .lb-block {
  margin-bottom: 0;
}

.lb-block {
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 30px;
}
.lb-block.st-mixed {
  display: flex;
}
.lb-block.st-mixed > :first-child {
  width: 30%;
}
.lb-block.st-mixed > :last-child {
  width: 70%;
}
.lb-block.st-mixed .lb-block-row {
  padding: 0.9375rem 1.875rem 0.9375rem 1.875rem;
}
.lb-block.st-mixed .account-list {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-block.st-mixed .account-list .draggable-item:first-child {
  border-top-right-radius: 4px;
}
.lb-block.st-mixed .account-list .draggable-item:first-child .lb-block-row {
  border-top-right-radius: 4px;
}
.lb-block.st-mixed .account-list .draggable-item:last-child {
  border-bottom-right-radius: 4px;
}
.lb-block.st-mixed .account-list .draggable-item:last-child .lb-block-row {
  border-bottom-right-radius: 4px;
}
.lb-block .lb-subhead-statment {
  background: none !important;
  color: #464a4e !important;
  padding: 1.0625rem 1.875rem !important;
}
.lb-block .lb-subhead-statment .lb-select .Select-input {
  height: 40px;
}
.lb-block .lb-block-subhead {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.8125rem;
  padding: 0.9375rem 1.5rem;
  border-bottom: 1px solid #e9ecf2;
  background: #f5f5f5;
  color: #555c63;
}
.lb-block .lb-block-subhead .is-card-title {
  font-size: 1rem;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  color: #242424;
  text-transform: uppercase;
}
.lb-block .lb-block-subhead .total-debt {
  font-size: 0.8125rem;
  font-family: "Liberty-MT", Arial, sans-serif;
  color: #242424;
  text-transform: uppercase;
  margin-right: 20px;
}
.lb-block .lb-block-row > :not(:last-child),
.lb-block .lb-block-subhead > :not(:last-child) {
  padding-right: 15px;
}

.App.ka .is-card-title,
.App.ka .total-debt {
  text-transform: none;
}

.App.en-US .is-card-title,
.App.en-US .total-debt {
  text-transform: none;
}

.lb-block-head {
  display: flex;
  min-height: 60px;
  height: 60px;
  padding: 0 1.875rem;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #e9e9f1;
  background-color: #fafafa;
}
.lb-block-head.hidden-head {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-block-row,
.lb-block-subhead,
.lb-block-head {
  display: flex;
  align-items: center;
}

.lb-block-row {
  padding: 1rem 1.5rem;
  position: relative;
  overflow: hidden;
}
.lb-block-row:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}
.lb-block-row.pointer-default {
  transition: 0.2s;
}
.lb-block-row.pointer-default .icn-arr-right {
  transition: 0.2s;
}
.lb-block-row.pointer-default:hover, .lb-block-row.pointer-default.active {
  background: rgba(243, 243, 243, 0.44);
}
.lb-block-row.pointer-default:hover .icn-arr-right, .lb-block-row.pointer-default.active .icn-arr-right {
  color: #db0011;
}
.lb-block-row.block-pointer {
  transition: 0.2s;
}
.lb-block-row.block-pointer .icn-arr-right {
  transition: 0.2s;
}
.lb-block-row.block-pointer:hover, .lb-block-row.block-pointer.active {
  background: rgba(243, 243, 243, 0.44);
}
.lb-block-row.block-pointer:hover .icn-arr-right, .lb-block-row.block-pointer.active .icn-arr-right {
  color: #db0011;
}

.lb-block-row:last-child {
  border-bottom: none;
}

.lb-block:not(.st-mixed) .lb-summary-top {
  width: calc(35% - 0.9375rem);
}
.lb-block:not(.st-mixed) .lb-summary-top .lb-block-title {
  flex: 1 1;
}
.lb-block:not(.st-mixed) .lb-summary-top .hidden-icon {
  margin-right: 20px;
}

.lb-block:not(.st-mixed) .Other-Blocked {
  width: calc(40% - 0.9375rem);
}

@media screen and (max-width: 1366px) {
  .container {
    width: 92%;
  }
  .lb-header-in {
    margin-bottom: 0;
    position: fixed;
  }
}
@media screen and (max-width: 1024px) {
  .lb-header-in {
    position: relative;
    margin-bottom: 1px;
  }
  .lb-container {
    padding-top: 0;
  }
}
.welcome-screen-slider-enter {
  opacity: 0.01;
}

.welcome-screen-slider-enter-active {
  opacity: 1;
  transition: opacity 0.9s ease-in-out;
}

.welcome-screen-slider-exit {
  display: none;
}

.welcome-screen-slider-exit-active {
  display: none;
}

.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.ml-0 {
  margin-left: 0px !important;
}

.p-0 {
  padding: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.m-1 {
  margin: 1px;
}

.mt-1 {
  margin-top: 1px !important;
}

.mb-1 {
  margin-bottom: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.ml-1 {
  margin-left: 1px !important;
}

.p-1 {
  padding: 1px;
}

.pt-1 {
  padding-top: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.m-2 {
  margin: 2px;
}

.mt-2 {
  margin-top: 2px !important;
}

.mb-2 {
  margin-bottom: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.ml-2 {
  margin-left: 2px !important;
}

.p-2 {
  padding: 2px;
}

.pt-2 {
  padding-top: 2px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pr-2 {
  padding-right: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.m-3 {
  margin: 3px;
}

.mt-3 {
  margin-top: 3px !important;
}

.mb-3 {
  margin-bottom: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.ml-3 {
  margin-left: 3px !important;
}

.p-3 {
  padding: 3px;
}

.pt-3 {
  padding-top: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.pl-3 {
  padding-left: 3px;
}

.m-4 {
  margin: 4px;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.ml-4 {
  margin-left: 4px !important;
}

.p-4 {
  padding: 4px;
}

.pt-4 {
  padding-top: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px !important;
}

.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.m-6 {
  margin: 6px;
}

.mt-6 {
  margin-top: 6px !important;
}

.mb-6 {
  margin-bottom: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.ml-6 {
  margin-left: 6px !important;
}

.p-6 {
  padding: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.m-7 {
  margin: 7px;
}

.mt-7 {
  margin-top: 7px !important;
}

.mb-7 {
  margin-bottom: 7px;
}

.mr-7 {
  margin-right: 7px;
}

.ml-7 {
  margin-left: 7px !important;
}

.p-7 {
  padding: 7px;
}

.pt-7 {
  padding-top: 7px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.pl-7 {
  padding-left: 7px;
}

.m-8 {
  margin: 8px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px !important;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.m-9 {
  margin: 9px;
}

.mt-9 {
  margin-top: 9px !important;
}

.mb-9 {
  margin-bottom: 9px;
}

.mr-9 {
  margin-right: 9px;
}

.ml-9 {
  margin-left: 9px !important;
}

.p-9 {
  padding: 9px;
}

.pt-9 {
  padding-top: 9px;
}

.pb-9 {
  padding-bottom: 9px;
}

.pr-9 {
  padding-right: 9px;
}

.pl-9 {
  padding-left: 9px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.m-11 {
  margin: 11px;
}

.mt-11 {
  margin-top: 11px !important;
}

.mb-11 {
  margin-bottom: 11px;
}

.mr-11 {
  margin-right: 11px;
}

.ml-11 {
  margin-left: 11px !important;
}

.p-11 {
  padding: 11px;
}

.pt-11 {
  padding-top: 11px;
}

.pb-11 {
  padding-bottom: 11px;
}

.pr-11 {
  padding-right: 11px;
}

.pl-11 {
  padding-left: 11px;
}

.m-12 {
  margin: 12px;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-12 {
  margin-left: 12px !important;
}

.p-12 {
  padding: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.m-13 {
  margin: 13px;
}

.mt-13 {
  margin-top: 13px !important;
}

.mb-13 {
  margin-bottom: 13px;
}

.mr-13 {
  margin-right: 13px;
}

.ml-13 {
  margin-left: 13px !important;
}

.p-13 {
  padding: 13px;
}

.pt-13 {
  padding-top: 13px;
}

.pb-13 {
  padding-bottom: 13px;
}

.pr-13 {
  padding-right: 13px;
}

.pl-13 {
  padding-left: 13px;
}

.m-14 {
  margin: 14px;
}

.mt-14 {
  margin-top: 14px !important;
}

.mb-14 {
  margin-bottom: 14px;
}

.mr-14 {
  margin-right: 14px;
}

.ml-14 {
  margin-left: 14px !important;
}

.p-14 {
  padding: 14px;
}

.pt-14 {
  padding-top: 14px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pr-14 {
  padding-right: 14px;
}

.pl-14 {
  padding-left: 14px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px !important;
}

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px !important;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.m-17 {
  margin: 17px;
}

.mt-17 {
  margin-top: 17px !important;
}

.mb-17 {
  margin-bottom: 17px;
}

.mr-17 {
  margin-right: 17px;
}

.ml-17 {
  margin-left: 17px !important;
}

.p-17 {
  padding: 17px;
}

.pt-17 {
  padding-top: 17px;
}

.pb-17 {
  padding-bottom: 17px;
}

.pr-17 {
  padding-right: 17px;
}

.pl-17 {
  padding-left: 17px;
}

.m-18 {
  margin: 18px;
}

.mt-18 {
  margin-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px;
}

.mr-18 {
  margin-right: 18px;
}

.ml-18 {
  margin-left: 18px !important;
}

.p-18 {
  padding: 18px;
}

.pt-18 {
  padding-top: 18px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pr-18 {
  padding-right: 18px;
}

.pl-18 {
  padding-left: 18px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px !important;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.m-22 {
  margin: 22px;
}

.mt-22 {
  margin-top: 22px !important;
}

.mb-22 {
  margin-bottom: 22px;
}

.mr-22 {
  margin-right: 22px;
}

.ml-22 {
  margin-left: 22px !important;
}

.p-22 {
  padding: 22px;
}

.pt-22 {
  padding-top: 22px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pr-22 {
  padding-right: 22px;
}

.pl-22 {
  padding-left: 22px;
}

.m-24 {
  margin: 24px;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-24 {
  margin-left: 24px !important;
}

.p-24 {
  padding: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-25 {
  margin-left: 25px !important;
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.m-26 {
  margin: 26px;
}

.mt-26 {
  margin-top: 26px !important;
}

.mb-26 {
  margin-bottom: 26px;
}

.mr-26 {
  margin-right: 26px;
}

.ml-26 {
  margin-left: 26px !important;
}

.p-26 {
  padding: 26px;
}

.pt-26 {
  padding-top: 26px;
}

.pb-26 {
  padding-bottom: 26px;
}

.pr-26 {
  padding-right: 26px;
}

.pl-26 {
  padding-left: 26px;
}

.m-28 {
  margin: 28px;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px;
}

.mr-28 {
  margin-right: 28px;
}

.ml-28 {
  margin-left: 28px !important;
}

.p-28 {
  padding: 28px;
}

.pt-28 {
  padding-top: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

.pr-28 {
  padding-right: 28px;
}

.pl-28 {
  padding-left: 28px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-30 {
  margin-left: 30px !important;
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.m-32 {
  margin: 32px;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.ml-32 {
  margin-left: 32px !important;
}

.p-32 {
  padding: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.m-34 {
  margin: 34px;
}

.mt-34 {
  margin-top: 34px !important;
}

.mb-34 {
  margin-bottom: 34px;
}

.mr-34 {
  margin-right: 34px;
}

.ml-34 {
  margin-left: 34px !important;
}

.p-34 {
  padding: 34px;
}

.pt-34 {
  padding-top: 34px;
}

.pb-34 {
  padding-bottom: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.pl-34 {
  padding-left: 34px;
}

.m-36 {
  margin: 36px;
}

.mt-36 {
  margin-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px;
}

.mr-36 {
  margin-right: 36px;
}

.ml-36 {
  margin-left: 36px !important;
}

.p-36 {
  padding: 36px;
}

.pt-36 {
  padding-top: 36px;
}

.pb-36 {
  padding-bottom: 36px;
}

.pr-36 {
  padding-right: 36px;
}

.pl-36 {
  padding-left: 36px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.ml-35 {
  margin-left: 35px !important;
}

.p-35 {
  padding: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-40 {
  margin-left: 40px !important;
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.m-42 {
  margin: 42px;
}

.mt-42 {
  margin-top: 42px !important;
}

.mb-42 {
  margin-bottom: 42px;
}

.mr-42 {
  margin-right: 42px;
}

.ml-42 {
  margin-left: 42px !important;
}

.p-42 {
  padding: 42px;
}

.pt-42 {
  padding-top: 42px;
}

.pb-42 {
  padding-bottom: 42px;
}

.pr-42 {
  padding-right: 42px;
}

.pl-42 {
  padding-left: 42px;
}

.m-44 {
  margin: 44px;
}

.mt-44 {
  margin-top: 44px !important;
}

.mb-44 {
  margin-bottom: 44px;
}

.mr-44 {
  margin-right: 44px;
}

.ml-44 {
  margin-left: 44px !important;
}

.p-44 {
  padding: 44px;
}

.pt-44 {
  padding-top: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

.pr-44 {
  padding-right: 44px;
}

.pl-44 {
  padding-left: 44px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.ml-45 {
  margin-left: 45px !important;
}

.p-45 {
  padding: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.m-46 {
  margin: 46px;
}

.mt-46 {
  margin-top: 46px !important;
}

.mb-46 {
  margin-bottom: 46px;
}

.mr-46 {
  margin-right: 46px;
}

.ml-46 {
  margin-left: 46px !important;
}

.p-46 {
  padding: 46px;
}

.pt-46 {
  padding-top: 46px;
}

.pb-46 {
  padding-bottom: 46px;
}

.pr-46 {
  padding-right: 46px;
}

.pl-46 {
  padding-left: 46px;
}

.m-48 {
  margin: 48px;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-48 {
  margin-bottom: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.ml-48 {
  margin-left: 48px !important;
}

.p-48 {
  padding: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-50 {
  margin-left: 50px !important;
}

.p-50 {
  padding: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.m-56 {
  margin: 56px;
}

.mt-56 {
  margin-top: 56px !important;
}

.mb-56 {
  margin-bottom: 56px;
}

.mr-56 {
  margin-right: 56px;
}

.ml-56 {
  margin-left: 56px !important;
}

.p-56 {
  padding: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.m-58 {
  margin: 58px;
}

.mt-58 {
  margin-top: 58px !important;
}

.mb-58 {
  margin-bottom: 58px;
}

.mr-58 {
  margin-right: 58px;
}

.ml-58 {
  margin-left: 58px !important;
}

.p-58 {
  padding: 58px;
}

.pt-58 {
  padding-top: 58px;
}

.pb-58 {
  padding-bottom: 58px;
}

.pr-58 {
  padding-right: 58px;
}

.pl-58 {
  padding-left: 58px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.ml-60 {
  margin-left: 60px !important;
}

.p-60 {
  padding: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.m-64 {
  margin: 64px;
}

.mt-64 {
  margin-top: 64px !important;
}

.mb-64 {
  margin-bottom: 64px;
}

.mr-64 {
  margin-right: 64px;
}

.ml-64 {
  margin-left: 64px !important;
}

.p-64 {
  padding: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pr-64 {
  padding-right: 64px;
}

.pl-64 {
  padding-left: 64px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.ml-65 {
  margin-left: 65px !important;
}

.p-65 {
  padding: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.ml-70 {
  margin-left: 70px !important;
}

.p-70 {
  padding: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.ml-75 {
  margin-left: 75px !important;
}

.p-75 {
  padding: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.m-76 {
  margin: 76px;
}

.mt-76 {
  margin-top: 76px !important;
}

.mb-76 {
  margin-bottom: 76px;
}

.mr-76 {
  margin-right: 76px;
}

.ml-76 {
  margin-left: 76px !important;
}

.p-76 {
  padding: 76px;
}

.pt-76 {
  padding-top: 76px;
}

.pb-76 {
  padding-bottom: 76px;
}

.pr-76 {
  padding-right: 76px;
}

.pl-76 {
  padding-left: 76px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.ml-80 {
  margin-left: 80px !important;
}

.p-80 {
  padding: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.ml-85 {
  margin-left: 85px !important;
}

.p-85 {
  padding: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.ml-90 {
  margin-left: 90px !important;
}

.p-90 {
  padding: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.ml-95 {
  margin-left: 95px !important;
}

.p-95 {
  padding: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.m-96 {
  margin: 96px;
}

.mt-96 {
  margin-top: 96px !important;
}

.mb-96 {
  margin-bottom: 96px;
}

.mr-96 {
  margin-right: 96px;
}

.ml-96 {
  margin-left: 96px !important;
}

.p-96 {
  padding: 96px;
}

.pt-96 {
  padding-top: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.pr-96 {
  padding-right: 96px;
}

.pl-96 {
  padding-left: 96px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.ml-100 {
  margin-left: 100px !important;
}

.p-100 {
  padding: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.m-120 {
  margin: 120px;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-120 {
  margin-bottom: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.ml-120 {
  margin-left: 120px !important;
}

.p-120 {
  padding: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.m-150 {
  margin: 150px;
}

.mt-150 {
  margin-top: 150px !important;
}

.mb-150 {
  margin-bottom: 150px;
}

.mr-150 {
  margin-right: 150px;
}

.ml-150 {
  margin-left: 150px !important;
}

.p-150 {
  padding: 150px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-150 {
  padding-right: 150px;
}

.pl-150 {
  padding-left: 150px;
}

.m-200 {
  margin: 200px;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-200 {
  margin-bottom: 200px;
}

.mr-200 {
  margin-right: 200px;
}

.ml-200 {
  margin-left: 200px !important;
}

.p-200 {
  padding: 200px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pr-200 {
  padding-right: 200px;
}

.pl-200 {
  padding-left: 200px;
}

.slider-overflow {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 40px;
  min-height: 210px;
}

.lb-slider-block {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.sl-right-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 500ms ease-in-out;
}
.sl-right-enter + .sl-left-exit {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 500ms ease-in-out;
}

.sl-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 500ms ease-in-out;
}
.sl-left-enter + .sl-right-exit {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.sl-left-enter-active,
.sl-right-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: all 500ms ease-in-out;
}

.sl-right-exit {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 500ms ease-in-out;
}

.sl-left-exit {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

/* ========== loading ========== */
.loader-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-ring {
  position: relative;
  margin: 1rem auto;
  width: 3rem;
  height: 3rem;
}

.loader-ring-light {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  box-shadow: 0 4px 0 #db0011 inset;
  -webkit-animation: rotate-360 2s linear infinite;
          animation: rotate-360 2s linear infinite;
}

.loader-ring-track {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3) inset;
}

@-webkit-keyframes rotate-360 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-360 {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-container {
  min-height: 200px;
  width: 100%;
  position: relative;
}

.loader-container-min {
  min-height: 60px;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.lb-dashboard .loader-container {
  min-height: 50px;
}

.loader-out.full-size {
  position: absolute;
  top: 0;
  z-index: 3;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
}
.loader-out.fixed {
  position: fixed;
  top: 116px;
}
.loader-out.decimal-loader {
  height: 50px !important;
}

.main-loader {
  width: 100px;
  height: 100px;
  position: relative;
}

.main-loader .dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
          animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
}

.main-loader .dot:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.main-loader .dot:nth-child(2) {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}

.main-loader .dot:nth-child(3) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}

.main-loader .dot:nth-child(4) {
  -webkit-animation-delay: 0.55s;
          animation-delay: 0.55s;
}

.main-loader .dot:after,
.main-loader .dot .first {
  content: "";
  position: absolute;
  width: 10%;
  height: 10%;
  background: #db0011;
  border-radius: 50%;
  left: 50%;
  margin-left: -5%;
}

.main-loader .dot .first {
  background: #db0011;
  margin-top: -5%;
  -webkit-animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
          animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

@-webkit-keyframes dotrotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes dotrotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dotscale {
  0%, 10% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
  50% {
    width: 10%;
    height: 10%;
    margin-left: -5%;
    margin-top: 0;
  }
  90%, 100% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
}
@keyframes dotscale {
  0%, 10% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
  50% {
    width: 10%;
    height: 10%;
    margin-left: -5%;
    margin-top: 0;
  }
  90%, 100% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
}
.loader {
  width: 100px;
  height: 100px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #db0011;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
.loader.loader-xxs {
  width: 20px;
  height: 20px;
  border-width: 2px;
}
.loader.loader-xs {
  width: 30px;
  height: 30px;
  border-width: 2px;
}
.loader.loader-xsm {
  width: 40px;
  height: 40px;
  border-width: 3px;
}
.loader.loader-sm {
  width: 60px;
  height: 60px;
  border-width: 3px;
}

.loader-out.loader-white .loader {
  border-top: 4px solid #fff;
}
.loader-out.loader-white .loader.loader-xxs {
  border-top: 2px solid #fff;
}
.loader-out.loader-white .loader.loader-xs {
  border-top: 2px solid #fff;
}
.loader-out.loader-white .loader.loader-xsm {
  border-top: 3px solid #fff;
}
.loader-out.loader-white .loader.loader-sm {
  border-top: 3px solid #fff;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.LoadingMask .loader {
  margin: auto;
}

.error-box {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.2rem;
}
.error-box .icn.icn-warning {
  color: #db0011;
  margin-right: 15px;
  width: 25px;
  height: 22px;
}
.error-box .error-icon {
  padding-top: 5px;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
}
.error-box .error-icon .icn.icn-warning {
  color: #fff;
  margin-right: 15px;
  width: 25px;
  height: 22px;
}
.error-box .error-currency {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #db0011;
  padding: 15px;
  padding: 0 5px 0 15px;
  min-height: 20vh;
}
.error-box .error-currency .icn.icn-warning {
  margin-top: 4px;
}
.error-box .error-currency .icn.icn-warning svg {
  width: 23px;
  height: 23px;
}
.error-box .error-accoount {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  color: #db0011;
}
.error-box .error-lg {
  padding: 40px 25px;
  display: flex;
  align-items: center;
  color: #db0011;
  justify-content: center;
  min-height: 20vh;
  height: 20vh;
}
.error-box .error-md {
  display: flex;
  align-items: center;
  color: #db0011;
  justify-content: flex-start;
  margin-top: -25px;
}
.error-box .error-md .icn.icn-warning {
  color: #db0011;
  margin-right: 5px;
  width: 19px;
  height: 22px;
}
.error-box .error-modal {
  padding: 40px;
  display: inline-block;
  align-items: center;
  color: #db0011;
  justify-content: center;
  min-height: 20vh;
  height: 20vh;
  text-align: center;
  width: 100%;
}
.error-box .error-modal .icn.icn-warning {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.error-box .error-quick {
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #db0011;
  justify-content: center;
  font-size: 0.8125rem;
}
.error-box .error-quick svg {
  width: 23px;
  height: 23px;
}
.error-box .error-quick .icn.icn-warning {
  margin-left: 0;
  margin-right: 5px;
}
.error-box .error-sm {
  font-size: 0.75rem;
  display: flex;
  align-items: flex-start;
  color: #fff;
  padding-top: 10px;
}
.error-box .error-sm .icn.icn-warning {
  margin-top: 4px;
  color: #fff;
}
.error-box .error-sm .icn.icn-warning svg {
  width: 23px;
  height: 23px;
}
.error-box .error-DecimalInput {
  font-size: 0.8125rem;
  width: 85%;
  display: flex;
  align-items: center;
  text-align: left;
  color: #db0011;
  padding-top: 10px;
}
.error-box .error-DecimalInput .icn.icn-warning {
  margin-left: 0;
  margin-right: 5px;
}
.error-box .error-DecimalInput .icn.icn-warning svg {
  width: 20px;
  height: 20px;
}

.transfers-block.active .error-quick {
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  left: 0;
  top: 55px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  text-align: left;
}
.transfers-block.active .error-quick svg {
  width: 23px;
  height: 23px;
}
.transfers-block.active .error-quick .icn.icn-warning {
  color: #fff;
}

@-webkit-keyframes circle-loader-spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes circle-loader-spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
/* ========== loading end ========== */
/* ========== liberty block's modules ========== */
.lb-card {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  padding: 48px;
}

.lb-card-block .lb-block-body {
  padding: 32px 0;
}
.lb-card-block .lb-loan-pdf {
  padding: 25px 32px 0;
}
.lb-card-block .lb-card-details {
  max-width: 695px;
  min-height: 40vh;
  margin: 0 auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #e6e8eb;
  border-radius: 8px;
}
.lb-card-block .lb-card-details .agree-rules {
  position: relative;
  background-color: #eff1f4;
  border: none;
  font-size: 0.6875rem;
  padding: 18px 24px;
  border-radius: 12px;
}
.lb-card-block .lb-card-details .icn-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.lb-card-block .lb-card-details .icn-close svg {
  width: 20px;
  height: 20px;
}
.lb-card-block .lb-card-details .icn-tick {
  width: 40px;
  height: 40px;
}
.lb-card-block .lb-card-details .lb-lbslider-block {
  margin-top: 23px;
}
.lb-card-block .lb-card-details .lb-form-group {
  width: 58%;
}
.lb-card-block .lb-card-details .lb-form-group .lb-select {
  height: 48px;
}
.lb-card-block .lb-card-details .lb-form-group .lb-label {
  font-family: "LIberty-Nus Medium", Arial, sans-serif;
}
.lb-card-block .lb-card-details .lb-form-group .lb-input-err {
  display: block !important;
}
.lb-card-block .lb-foot-form {
  padding: 0 32px 32px !important;
}

.lb-card-info {
  display: flex;
  align-items: center;
}
.lb-card-info .lb-image-out {
  height: 40px;
  width: 65px;
  margin-right: 1rem;
  position: relative;
}
.lb-card-info .lb-image-out.lb-image-2 .lb-card-image {
  position: absolute;
}
.lb-card-info .lb-image-out.lb-image-2 .lb-card-image:first-child {
  top: -5px;
  left: -5px;
}
.lb-card-info .lb-image-out.lb-image-2 .lb-card-image:last-child {
  top: 5px;
  left: 5px;
}
.lb-card-info .lb-card-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lb-card-info .lb-card-image .lb-card-img {
  max-width: 60%;
  max-height: 55%;
}
.lb-card-info .lb-card-image + div {
  width: calc(100% - 1rem - 65px);
}
.lb-card-info i.lb-card-image {
  color: #fff;
}
.lb-card-info i.lb-card-image svg {
  height: 1.125rem;
  max-width: 1.5rem;
}

.lb-block-subhead.block-pointer > :last-child {
  padding-right: 35px;
}

.lb-block .lb-block-subhead .w-100 {
  padding-right: 0;
}

.lb-block-row.lb-blocked-row {
  cursor: default;
}
.lb-block-row .lb-block-inrow {
  display: flex;
  align-items: center;
}
.lb-block-row.pointer-default {
  cursor: default;
}
.lb-block-row.pointer-default > :nth-last-child(2) {
  padding-right: 35px;
}
.lb-block-row.pointer-default .icn-arr-right:last-child {
  position: absolute;
  top: 50%;
  right: 1.875rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  height: 13px;
  width: 9px;
}
.lb-block-row.block-pointer {
  cursor: pointer;
}
.lb-block-row.block-pointer > :nth-last-child(2) {
  padding-right: 35px;
}
.lb-block-row.block-pointer .icn-arr-right:last-child {
  position: absolute;
  top: 50%;
  right: 1.875rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  height: 13px;
  width: 9px;
}
.lb-block-row > .icn-tick {
  text-align: left;
}
.lb-block-row > .icn-tick svg {
  height: 10px;
  width: 14px;
  margin-left: 7%;
}
.lb-block-row > .icn-tick .tooltip-parent {
  margin-left: 7%;
}
.lb-block-row > .icn-circle-tick {
  text-align: left;
}
.lb-block-row > .icn-circle-tick svg {
  height: 20px;
  width: 20px;
  margin-left: 7%;
}
.lb-block-row > .icn-circle-tick .tooltip-parent {
  margin-left: 7%;
}
.lb-block-row > .icn-circle-close {
  text-align: left;
}
.lb-block-row > .icn-circle-close svg {
  height: 20px;
  width: 20px;
  margin-left: 7%;
}
.lb-block-row > .icn-circle-close .tooltip-parent {
  margin-left: 7%;
}
.lb-block-row > .icn-circle-clock {
  text-align: left;
}
.lb-block-row > .icn-circle-clock svg {
  height: 20px;
  width: 20px;
  margin-left: 7%;
}
.lb-block-row > .icn-circle-clock .tooltip-parent {
  margin-left: 7%;
}
.lb-block-row > .icn-clock {
  text-align: left;
}
.lb-block-row > .icn-clock svg {
  height: 16px;
  width: 16px;
  margin-left: 7%;
}
.lb-block-row .icn-circle-clock {
  text-align: left;
}
.lb-block-row .icn-circle-clock svg {
  height: 20px;
  width: 20px;
  margin-left: 7%;
}
.lb-block-row .icn-circle-clock .tooltip-parent {
  margin-left: 7%;
}
.lb-block-row .is-name {
  font-size: 0.9375rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-subname {
  font-size: 0.75rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #74777a;
}
.lb-block-row .is-iban {
  font-size: 13px;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-amount {
  font-size: 1.0625rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-amount-sm {
  font-size: 0.9375rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-amount-root {
  font-size: 0.9375rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-info {
  font-size: 0.9375rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-subamount {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #8d9195;
}
.lb-block-row .is-date {
  font-size: 0.8125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #8d9195;
}
.lb-block-row .is-subdate {
  font-size: 0.8125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #8d9195;
}
.lb-block-row .is-longdate {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-description {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-category {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-amount-n {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-cardtype {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-reason {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-admin {
  font-size: 0.875rem;
  font-family: "Liberty-Nus bold", Arial, sans-serif;
  color: #242424;
}
.lb-block-row .is-simple {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #555c63;
}
.lb-block-row .is-blocked {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #db0011;
}
.lb-block-row .is-deleted {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #a5a5b1;
}
.lb-block-row .is-green {
  color: #3ab23a !important;
}

.rows-wrap {
  padding: 1.5625rem 1.875rem;
}
.rows-wrap + .lb-block-subhead {
  border-top: 1px solid #e9ecf2;
}

.lb-show {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lb-show span {
  color: #74777a;
}

.lb-show .is-red {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}

.lb-show div {
  cursor: pointer;
  margin: 0 0 0 15px;
  position: relative;
  width: 16px;
}
.lb-show div:hover {
  color: #db0011;
}

.lb-show .is-red:after {
  content: "";
  position: absolute;
  width: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: calc(100% + 18px);
  height: 2px;
  background: #db0011;
}

.lb-block .lb-block-subhead + .lb-block-subhead {
  border-top: 0;
}

.lb-block-title {
  font-size: 1rem;
  line-height: normal;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  min-height: 25px;
}
.lb-block-title .fa-pencil-alt {
  opacity: 0;
  transition: 0.2s;
}
.lb-block-title:hover .fa-pencil-alt {
  opacity: 1;
}

.lb-block-mixed .lb-block-title.block-title-pointer {
  cursor: pointer;
}

.lb-block-row .lb-image-circle {
  height: 36px;
  width: 36px;
  background: #db0011;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.lb-block-row .lb-image-circle svg {
  height: 1.125rem;
  max-width: 1.5rem;
}

.product-list .lb-block-row {
  padding: 1.5625rem 1.875rem;
}

.lb-block .lb-block-row.no-info {
  padding: 2rem 25px;
  justify-content: center;
}

.lb-block-expand .fnt-13 {
  color: #242424;
}

.lb-block-expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 25px 0 30px;
  cursor: pointer;
}
.lb-block-expand .icn.icn-wallet2 {
  width: 26px;
  height: 21px;
}
.lb-block-expand .icn.icn-credits {
  width: 30px;
  height: 30px;
}
.lb-block-expand .icn.icn-loans {
  width: 32px;
}
.lb-block-expand .icn.icn-deposit {
  width: 25px;
  height: 25px;
}
.lb-block-expand .icn.icn-warnings-red {
  width: 17px;
}

.lb-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lb-info-row > :first-child {
  width: 75%;
  padding-right: 10px;
}
.lb-info-row > :last-child {
  width: 25%;
  text-align: right;
}
.lb-info-row .is-card-info {
  font-size: 0.875rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #242424;
}

.half-row {
  display: flex;
  justify-content: space-between;
}
.half-row > :first-child {
  width: 50%;
  padding-right: 10px;
}
.half-row > :last-child {
  width: 50%;
  text-align: right;
}
.half-row .is-card-info {
  font-size: 0.875rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #242424;
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.875rem;
}
.checkbox-row:first-child {
  margin-top: 0.625rem;
}
.checkbox-row:last-child {
  margin-bottom: 0.625rem;
}

/* ========== END liberty block's modules ========== */
/* ========== Header top elements ========== */
.lb-header-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding-right: 0.6875rem;
  font-size: 0.75rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}
.lb-header-user:after {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  background: #464e57;
  margin-left: 0.6875rem;
}
.lb-header-user .icn-arrow-down {
  color: #dcdee2;
  height: 7px;
  width: 10px;
}
.lb-header-user .lb-sub-navbar {
  min-width: 170px;
  box-shadow: none;
  background: #f6f6f6;
  border: 1px solid #e9e9f0;
  padding: 0.5rem 0.3rem;
  left: 13px;
  font-size: 0.8125rem;
  font-family: "Liberty-MT", Arial, sans-serif;
}
.lb-header-user:hover .lb-sub-navbar {
  opacity: 1;
  visibility: visible;
}
.lb-header-user .lb-subnav-item {
  display: block;
  color: #000;
  border-left: 1px solid #fff;
  transition: 0.3s;
  position: relative;
}
.lb-header-user .lb-subnav-item:after {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: -1px;
  width: 1px;
  background: #db0011;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: 0.3s ease;
}
.lb-header-user .lb-subnav-item:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.lb-header-user .lb-subnav-item.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.lb-header-user .lb-subnav-item:not(.disabled):hover {
  background: transparent;
  color: #db0011;
  border-left: 1px solid #db0011;
}
.lb-header-user .lb-subnav-item.is-notify {
  background: transparent;
  color: #db0011;
  border-left: 1px solid #db0011;
}
.lb-header-user .new-wrapper {
  display: inline-block;
  position: relative;
}
.lb-header-user .new-wrapper .newnotif-block {
  top: -16px;
  right: -21px;
}

.lb-head-avatar {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.625rem;
  border-radius: 50%;
  color: #ffffff;
  transition: 0.5s;
}

.head-avatar-circle {
  width: 21px;
  height: 21px;
  color: #fff;
  border-radius: 50%;
  background: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-avatar-circle svg {
  height: 13px;
  color: #000;
}

.lb-head-tips {
  padding: 0 1.25rem 0 1.5625rem;
  border-left: 1px solid #464e57;
  line-height: 1rem;
  display: flex;
  align-items: center;
}
.lb-head-tips i {
  color: #dcdee2;
  cursor: pointer;
  transition: 0.3s;
}
.lb-head-tips i:hover {
  color: #db0011;
}
.lb-head-tips .icn-mail {
  height: 0.75rem;
  position: relative;
}
.lb-head-tips i:not(:last-child) {
  margin: 0 1.125rem 0 0;
}
.lb-head-tips .mail-counter {
  position: absolute;
  bottom: 55%;
  left: 55%;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: 0.625rem;
  text-align: center;
  line-height: 1rem;
  background: #ca2827;
  color: #fff;
}

.lb-faq {
  height: 100%;
  padding-right: 0.6875rem;
  display: flex;
  align-items: center;
  color: #b2afb2;
  cursor: pointer;
  transition: 0.3s;
}
.lb-faq:after {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  background: #464e57;
  margin-left: 0.6875rem;
}
.lb-faq .icn-question {
  transition: 0.3s;
  width: 20px;
  height: 20px;
}
.lb-faq .icn-question:hover {
  color: #fff;
}

.lb-logout {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  color: #b2afb2;
}
.lb-logout .icn-exit {
  height: 1.25rem;
  width: 1.25rem;
  color: #b2afb2;
  margin-right: 0.5rem;
  transition: 0.3s;
}
.lb-logout:hover {
  color: #fff;
}
.lb-logout:hover .icn-exit {
  color: #fff;
}

/* ========== END of Header top elements ========== */
/* ========== Header bottom elements ========== */
.lb-bottom-header .lb-subnav-item,
.lb-container .lb-subnav-item {
  padding: 0 0.3125rem;
  height: 100%;
  margin-right: 0.9375rem;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}
.lb-bottom-header .lb-subnav-item:after,
.lb-container .lb-subnav-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transition: 0.2s;
}
.lb-bottom-header .lb-subnav-item:hover,
.lb-container .lb-subnav-item:hover {
  background: transparent;
  color: #db0011;
}
.lb-bottom-header .lb-subnav-item.active,
.lb-container .lb-subnav-item.active {
  color: #db0011;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}
.lb-bottom-header .lb-subnav-item.active:after,
.lb-container .lb-subnav-item.active:after {
  background: #db0011;
}

/* ========== END of Header bottom elements ========== */
/* ========== Logos ========== */
.lb-logo-main {
  margin: 0 2.8125rem 0 0;
  font-size: 0;
  cursor: pointer;
  width: 163px;
}

/* ========== END of Logos ========== */
/* ========== navigation ========== */
.lb-navbar ul li {
  display: inline-block;
  margin-left: 30px;
  text-transform: uppercase;
}

.App.ka .lb-navbar ul li {
  text-transform: lowercase;
}

.lb-navbar {
  display: flex;
}
.lb-navbar .icn-dropdown {
  color: inherit;
  width: 10px;
  height: 5px;
  margin: -1px 0 0 0.85rem;
}
.lb-navbar .lb-subnav-item {
  font-size: 0.85rem;
}
.lb-navbar .lb-sub-navbar {
  top: calc(100% - 1rem);
  padding-top: 0.625rem;
}
.lb-navbar .lb-navbar-item:hover .lb-sub-navbar:after {
  transition: 0.15s ease-out 0.15s;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.lb-navbar .lb-subnav-item {
  position: relative;
}
.lb-navbar .lb-subnav-item:after {
  content: "";
  position: absolute;
  left: -1px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  background: #db0011;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: 0.3s ease;
}
.lb-navbar .lb-subnav-item:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.lb-navbar .lb-subnav-item.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.lb-navbar .lb-subnav-item:not(.disabled):hover {
  color: #db0011;
}
.lb-navbar .lb-subnav-item:after {
  left: 0;
}
.lb-navbar .lb-subnav-item.disabled {
  opacity: 0.4 !important;
}
.lb-navbar .lb-subnav-item.disabled:after {
  content: none;
}

.lb-header-in .lb-navbar {
  height: 100%;
}

.lb-navbar-item {
  position: relative;
  padding: 0 1.25rem;
}
.lb-navbar-item:hover .nav-item {
  color: #db0011;
}
.lb-navbar-item .nav-item.active {
  color: #db0011;
}
.lb-navbar-item .lb-sub-navbar.navbar-show {
  opacity: 1;
  visibility: visible;
}
.lb-navbar-item .nav-item {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1rem;
  color: #000;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}
.lb-navbar-item .nav-item.active {
  color: #db0011;
}

.newnotif-block {
  position: absolute;
  top: 11px;
  right: 20px;
  z-index: 2;
}
.newnotif-block .add-new {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.6875rem;
  color: #db0011;
  border: 1px solid #242424;
  border-radius: 3px;
  line-height: 10px;
  padding: 2px 4px 0;
}
.newnotif-block .icn {
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 3px;
}

.lb-sub-navbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  position: absolute;
  z-index: 1030;
  top: 100%;
  left: 15px;
  min-width: 230px;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 -15px 0 #ffffff, 0 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.lb-subnav-wrap:not(:last-child) {
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
}
.lb-subnav-wrap:not(:last-child):after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: #e9ecf2;
}

.lb-subnav-item {
  white-space: nowrap;
  padding: 0.625rem 2.2rem 0.625rem 1.25rem;
  color: #000;
  font-family: "Liberty-MT", Arial, sans-serif;
  cursor: pointer;
}

.newnotif-block {
  position: absolute;
  top: 11px;
  right: 20px;
  z-index: 2;
}
.newnotif-block .add-new {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.6875rem;
  color: #db0011;
  border: 1px solid #242424;
  border-radius: 3px;
  line-height: 10px;
  padding: 2px 4px 0;
}
.newnotif-block .icn {
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 3px;
}

/* ========== END of navigation ========== */
/* ========== END header exchange ========== */
.lb-exchange {
  margin: 0 0 0 auto;
  cursor: pointer;
}
.lb-exchange .icn-exchange {
  display: block;
  height: 26px;
  width: 46px;
  position: relative;
}
.lb-exchange .icn-exchange svg {
  transition: 0.3s;
}
.lb-exchange .icn-exchange svg:last-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.lb-exchange .icn-exchange:hover svg:first-child {
  opacity: 0;
}
.lb-exchange .icn-exchange:hover svg:last-child {
  opacity: 1;
}

/* ========== END of header exchange ========== */
/* =========== icon modules ========== */
.icn {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  font-size: 0;
  align-items: center;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.icn-download {
  color: #242424;
}

.icn-warning {
  color: #242424;
}

.icn-warning:hover {
  color: #db0011;
}

.icn-warning.icn-red {
  color: #db0011;
}

.icn-exchange {
  color: #db0011;
}

.icn-arrow-down {
  color: #717073;
}

.icn-arr-right {
  color: #717073;
}

.icn-eye {
  color: #afb2b5;
}

.icn-eye-slash {
  color: #afb2b5;
}

.icn-info {
  color: #1d262f;
  border-radius: 50%;
  border: 1px solid currentColor;
  font-size: 0.8rem;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

span + .icn-warning {
  position: relative;
  top: 0;
}

.deposit-date {
  overflow: visible !important;
}
.deposit-date .icn-expires {
  position: absolute;
  top: 2px;
  right: -13px;
}

.inOverdue .icn-warning {
  color: #db0011;
}

.icn-warning.warn-lg {
  height: 1.3rem;
  width: 1.3rem;
  margin-left: 1rem;
}

.icn.icn-red {
  color: #db0011;
}

.icn-arr-right.arrow-left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.icn.icn-round {
  border-radius: 50%;
  border: 3px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finalize-block {
  margin-bottom: 0;
  width: 100%;
  height: 187px;
}
.finalize-block .is-warrning a {
  color: #db0011;
  text-decoration: underline;
}
.finalize-block .is-warrning a:hover {
  color: #0c0c0c;
}
.finalize-block .icon-notfoundaccount {
  width: 51px;
  height: 37px;
}
.finalize-block .icn-notfoundloan {
  width: 45px;
  height: 48px;
}
.finalize-block .icon-notfounddeposit {
  width: 51px;
  height: 37px;
}
.finalize-block .icon-notfoundcredit {
  width: 51px;
  height: 37px;
}
.finalize-block .icn-clock {
  width: 44px;
  height: 44px;
}
.finalize-block.lb-check-template {
  box-shadow: none;
  padding: 69px 69px 69px 0;
  height: 187px;
}

.finalize-block .icn.icn-round {
  height: 44px;
  width: 44px;
}
.finalize-block .icn.icn-round svg {
  width: 45%;
  height: 45%;
}

.correct-message {
  background: #fff;
  padding: 63px 0;
  text-align: center;
  color: #626a71;
}
.correct-message .icn.cash-error {
  width: 53px;
  height: 53px;
  color: #858b91;
}

a .icn-arrow-left {
  position: relative;
  top: 0.05rem;
  margin-right: 10px;
  height: 10px;
  width: 13px;
}

.icn-cog {
  opacity: 0;
  transition: 0.2s;
}

.lb-block-head:hover .icn-cog {
  opacity: 1;
}

.icn.icn-18 {
  height: 18px;
  width: 18px;
}

.icn.icn-20 {
  height: 20px;
  width: 20px;
}

.icn.icn-w18 {
  width: 18px;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}
.icn.icn-w18.icn-phone svg, .icn.icn-w18.icn-lamp svg {
  width: 12px;
}

.icn.icn-w25 {
  width: 25px;
  height: 2rem;
  line-height: 2.2rem;
  text-align: center;
}
.icn.icn-w25.icn-lock svg {
  width: 16px;
}

.icn.icn-15 {
  width: 15px;
  height: 15px;
}

.icn.icn-13 {
  width: 13px;
  height: 13px;
}

.icn.icn-12 {
  width: 12px;
  height: 12px;
}

.change-name-out {
  height: 3.125rem;
}
.change-name-out .lb-btn.btn-red {
  background: #db0011;
  height: 2.5rem;
  margin-left: 0.625rem;
}
.change-name-out .lb-input {
  line-height: 50px;
  height: 2.5rem;
}
.change-name-out .hidden-input {
  font-size: 1rem;
  font-family: "liberty-MT Bold", Arial, sans-serif;
}
.change-name-out .credit-limit {
  font-size: 0.875rem;
  margin-top: -6px;
}
@media screen and (max-width: 1280px) {
  .change-name-out .credit-limit {
    font-size: 0.8125rem;
  }
}

.btn-transfer {
  height: 50px !important;
}

.change-name .icn-pen {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 24px;
  height: 24px;
  position: relative;
  top: 0.07rem;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}
.change-name .icn-pen:hover {
  color: #fff;
}
.change-name .icn-pen svg {
  width: 22px;
  height: auto;
  padding-top: 4px;
  padding-left: 7px;
}
.change-name:hover .icn-pen {
  opacity: 1;
  background: #242424;
}
.change-name:hover .icn-pen svg {
  color: #fff;
}

.lb-btn .icn-print {
  height: 1rem;
  width: 17px;
  color: #242424;
  position: relative;
  top: 2px;
}

/* icon sizes */
.icn.icn-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.icn.icn-md {
  width: 2rem;
  height: 2rem;
}

.icn.icn-lg {
  width: 4rem;
  height: 4rem;
}

/* icon sizes END */
/* =========== END of icon modules ========== */
/* =========== button modules ========== */
.lb-btn {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 1.56rem;
  height: 45px;
  min-width: 4.75rem;
  text-align: center;
  font-size: 15px;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  border-radius: 4px;
  transition: 0.3s;
}
.lb-btn + .lb-btn {
  margin-left: 15px;
}
.lb-btn:disabled {
  background: #e7e7e9 !important;
  color: #fff !important;
  cursor: default;
  border: none !important;
}

.lb-btn .icn.icn-arrow-left {
  margin-right: 10px;
}

.lb-btn .icn.icn-arrow-right {
  margin-left: 10px;
}

.lb-btn.btn-glassy {
  border: 1px solid #1d262f;
}

.lb-btn.btn-full {
  width: 100%;
}

.lb-btn.btn-dark {
  color: #fff;
  background-color: #1d262f;
  font-size: 0.9375rem;
}
.lb-btn.btn-dark:hover {
  background: #444c54;
}

.lb-btn.btn-confirm {
  width: 90px;
}

.trust-worthy .lb-confirm-text {
  padding-bottom: 7px;
}
.trust-worthy .lb-btn.btn-confirm {
  width: auto;
}

.lb-btn.btn-md {
  padding: 1rem 2.1875rem;
}

.lb-btn.btn-border {
  border: 1px solid #646464;
  font-size: 0.9375rem;
}
.lb-btn.btn-border i {
  transition: 0.3s;
}
.lb-btn.btn-border:not(.btn-link):hover {
  background: #db0011;
  border-color: transparent;
  color: #fff !important;
}
.lb-btn.btn-border:not(.btn-link):hover i {
  color: #fff;
}
.lb-btn.btn-border:not(.btn-link):hover .icn-corp-filter svg {
  color: #fff;
}

.lb-btn.btn-border-grey {
  border: 1px solid #dedede;
  transition: 0.3s !important;
}
.lb-btn.btn-border-grey:hover {
  color: #fff;
  border: 1px solid transparent;
  background: #1d262f;
}

.lb-btn.btn-white {
  background-color: #fff;
  color: #000;
}

.btn-download {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -webkit-user-select: none;
          user-select: none;
  padding: 0 0.875rem;
  height: 35px;
  min-width: 4.75rem;
  text-align: center;
  border: 1px solid #646464;
  background-color: #fff;
  border-radius: 4px;
  transition: 0.3s;
  font-size: 0.8125rem;
  font-family: "LIberty-Nus Medium", Arial, sans-serif;
}
.btn-download:hover {
  color: #db0011;
}

.lb-btn.btn-border-2 {
  border-width: 2px;
}

.lb-btn.btn-red {
  background: #db0011;
  color: #fff;
  border: 1px solid #db0011;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.9375rem;
  transition: 0.3s;
}
.lb-btn.btn-red:not(.btn-link):hover {
  background: #cc0111;
}

.lb-btn.btn-black {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 0.9375rem;
  transition: 0.3s;
}
.lb-btn.btn-black:not(.btn-link):hover {
  background: #000;
}

.lb-btn.btn-black-soft {
  background-color: #000;
  color: #fff;
  font-size: 0.875rem;
  letter-spacing: 0.28px;
  line-height: 16px;
  padding: 8px 16px 9px 16px;
  border: none;
  height: auto;
}

.btn-add {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 12px !important;
  height: 30px;
  padding: 0 16px;
}
.btn-add svg {
  width: 8px !important;
  height: 8px !important;
}

.lb-btn.btn-red:disabled {
  color: #fff !important;
  background: #8e9397 !important;
  border: 1px solid transparent !important;
}

.lb-btn.btn-link {
  font-size: 0.875rem;
  padding: 0;
  min-width: auto;
  height: auto !important;
  background: transparent;
  display: inline-flex;
  align-items: center;
  color: #1d262f;
}
.lb-btn.btn-link .icn-close {
  height: 7px;
  width: 7px;
  transition: 0s;
}
.lb-btn.btn-link:hover {
  color: #db0011;
}
.lb-btn.btn-link.btn-red {
  color: #db0011;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-btn.btn-link.btn-white {
  color: #fff;
}
.lb-btn.btn-link i {
  color: inherit;
}
.lb-btn.btn-link .icn-arr-right {
  height: 7px;
  width: 4px;
  margin-right: 15px;
  position: relative;
  top: 1px;
}

.btn-custom-dis:disabled {
  background: #e3e3e3 !important;
  border: 1px solid transparent !important;
}

.btn-wide-35 {
  min-width: 35%;
}

.btn-wide-40 {
  min-width: 40%;
}

.btn-wide-45 {
  min-width: 45%;
}

.btn-wide-50 {
  min-width: 50%;
}

/* =========== END of button modules ========== */
/* =========== input modules ========== */
.largest-form-group .input-block .lb-input-out.amount-input-out {
  height: 6.25rem;
  padding: 1.25rem 1.5625rem;
  border-radius: 4px;
}
.largest-form-group .input-block .lb-input-out.amount-input-out .lb-input {
  padding: 0 2.2rem 0 1.5625rem;
  font-size: 1rem;
}
.largest-form-group .input-block .lb-input-out.amount-input-out i.amount-symbol {
  right: 1.5625rem;
}
.largest-form-group .input-block .lb-btn {
  border-radius: 4px;
}
.largest-form-group .lb-select {
  height: 5.625rem;
}
.largest-form-group .lb-select.type-select.is-open {
  height: 3.25rem;
}
.largest-form-group .lb-select.type-select.is-open .Select-placeholder {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.9375rem;
  padding: 0 1.375rem;
}
.largest-form-group .lb-select.type-select.is-open .Select-input {
  padding: 0 1.375rem;
}
.largest-form-group .lb-select .Select-value,
.largest-form-group .lb-select .Select-input {
  padding: 0 3.5rem 0 2.5rem !important;
  font-size: 0.9375rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.largest-form-group .lb-select .Select-placeholder {
  padding: 0 2.5rem;
  font-size: 1.25rem;
}
.largest-form-group .lb-select.is-open .Select-input {
  padding: 0 1.375rem !important;
}
.largest-form-group .lb-label {
  display: inline-block;
  font-size: 1rem;
  margin: 0 0 1.0625rem;
}
.largest-form-group .Select-value {
  padding-right: 60px !important;
}
.largest-form-group .Select-arrow-zone {
  width: 50px;
  text-align: left;
}
.largest-form-group .Select-arrow-zone .icn.icn-arrow-down {
  color: #fff;
  width: 15px;
  height: 8px;
}
.largest-form-group .lb-btn:not(.btn-link) {
  height: 4.0625rem;
}

.symbol-calendar {
  position: absolute;
  top: 60% !important;
  -webkit-transform: translateY(-60%) !important;
          transform: translateY(-60%) !important;
}
.symbol-calendar svg {
  width: 20px;
  height: 18px;
  color: #242424;
}

.larger-form-group .lb-input {
  height: 50px;
  padding: 0 1.0625rem;
  font-size: 0.9375rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  background-color: #fff;
}
.larger-form-group .lb-select {
  height: 3.125rem;
}
.larger-form-group .lb-select .Select-value,
.larger-form-group .lb-select .Select-placeholder,
.larger-form-group .lb-select .Select-input {
  padding: 0 1.0625rem !important;
  font-size: 0.9375rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.larger-form-group .lb-btn:not(.btn-link) {
  height: 50px;
}
.middle-form-group .lb-input {
  height: 3.125rem;
  padding: 0 1.6875rem;
  font-size: 0.9375rem;
}
.middle-form-group .lb-select {
  height: 4.0625rem;
}
.middle-form-group .lb-select .Select-value,
.middle-form-group .lb-select .Select-placeholder,
.middle-form-group .lb-select .Select-input {
  padding: 0 1.6875rem;
  font-size: 0.9375rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.middle-form-group .lb-btn:not(.btn-link) {
  height: 3.125rem;
}
.middle-form-group .treasury-code-input {
  color: #242424 !important;
}
.middle-form-group .treasury-code-input::-webkit-input-placeholder {
  color: #242424 !important;
}

.hint {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
}

.small-form-group .lb-input {
  height: 3.125rem;
  padding: 0 0.9375rem;
  font-size: 0.875rem;
}
.small-form-group .lb-select.account-select {
  height: auto;
}
.small-form-group .lb-select {
  height: 3.125rem;
}
.small-form-group .lb-select.select-transparent {
  height: 3.1875rem;
}
.small-form-group .lb-select {
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.small-form-group .lb-select .Select-input {
  height: 3.125rem;
}
.small-form-group .lb-select .Select-input input {
  height: 100%;
  padding: 0;
}
.small-form-group .lb-select .Select-value {
  padding: 0 2rem 0 1.0625rem !important;
  font-size: 0.875rem;
}
.small-form-group .lb-select .Select-input {
  padding: 0 2rem 0 1.0625rem !important;
  font-size: 0.875rem;
}
.small-form-group .lb-select .Select-option {
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  font-size: 0.875rem;
}
.small-form-group .lb-select .Select-placeholder {
  padding: 0 2.2em 0 1.0625rem;
  font-size: 0.875rem;
}
.small-form-group .Select-arrow-zone {
  padding-right: 1.0625rem !important;
}
.small-form-group .lb-btn:not(.btn-link) {
  height: 3.125rem;
  min-width: 50%;
}

.smaller-form-group .lb-input {
  height: 2.8125rem;
  padding: 0 1.0625rem;
  font-size: 0.875rem;
}
.smaller-form-group .lb-select {
  height: 2.8125rem !important;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.smaller-form-group .lb-select .Select-value,
.smaller-form-group .lb-select .Select-input {
  padding: 0 2rem 0 1.0625rem !important;
  font-size: 0.875rem;
}
.smaller-form-group .lb-select .Select-option,
.smaller-form-group .lb-select .select-option {
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  font-size: 0.875rem;
}
.smaller-form-group .lb-select .Select-placeholder {
  padding: 0 2.2em 0 1.0625rem;
  font-size: 0.875rem;
}
.smaller-form-group .lb-btn:not(.btn-link) {
  height: 2.8125rem;
}

.inputs-grey .lb-input:not(:disabled) {
  border-color: #e4e6ea;
}
.inputs-grey .lb-input:not(:disabled),
.inputs-grey .lb-input:focus {
  background: #f4f4f8 !important;
  color: #6d6d6d !important;
}
.inputs-grey .lb-input:focus {
  border: 1px solid #a5a5b1 !important;
}
.inputs-grey .lb-input::-webkit-input-placeholder {
  color: #6d6d6d !important;
}
.inputs-grey .lb-input::placeholder {
  color: #6d6d6d !important;
}

.lb-label {
  font-size: 0.875rem;
}

.lb-form .btn-submit {
  width: 50%;
}

.lb-form-group {
  position: relative;
  text-align: left;
  margin-bottom: 1.875rem;
}
.lb-form-group.mb-0 {
  margin-bottom: 0;
}
.lb-form-group .lb-label {
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-form-group .lb-input {
  margin: 0;
  transition: 0.2s;
  line-height: 3.1875rem;
}
.lb-form-group .lb-input-info {
  font-size: 0.75rem;
  color: #c9cacc;
  margin-top: 5px;
}
.lb-form-group .lb-input-err {
  font-size: 0.8125rem;
  display: none;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.lb-form-group .lb-input-err .error-custom {
  color: #fff;
  margin: 0.75rem 0 0.2rem;
}
.lb-form-group .lb-input-out.bordered-error {
  width: calc(100% + 0.875rem);
  left: -0.4375rem;
  padding: 0.4375rem;
  border-radius: 4px;
}
.lb-form-group .lb-input-out.bordered-error.error {
  background: #db0011;
}
.lb-form-group .lb-input-out.bordered-error.error .lb-input-err {
  color: #fff;
}
.lb-form-group .lb-input-out.bordered-error.error .lb-input {
  background: #fff;
  color: #242424;
}
.lb-form-group .lb-input-out.bordered-error.error .lb-input:-webkit-autofill,
.lb-form-group .lb-input-out.bordered-error.error .lb-input:-webkit-autofill:hover,
.lb-form-group .lb-input-out.bordered-error.error .lb-input:-webkit-autofill:focus,
.lb-form-group .lb-input-out.bordered-error.error .lb-input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #000 !important;
}
.lb-form-group .lb-input-out.bordered-error .lb-input-err {
  display: block;
  padding: 0.625rem 0 0.125rem;
}
.lb-form-group .lb-input-out:not(.bordered-error).error .lb-input-err {
  color: #e70d2f;
  margin: 10px 0 25px;
}
.lb-form-group .lb-input-out.error .lb-input-err {
  display: block;
  margin: 5px 5px;
  padding-top: 0;
}

.lb-input-out {
  position: relative;
}
.lb-input-out.lb-input-search .lb-input {
  padding-right: 35px;
  font-size: 0.75rem;
  padding-right: 35px;
}
.lb-input-out i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.lb-input-out i.icn-search {
  right: 0.9375rem;
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0;
  color: #b7b6b8;
}
.lb-input-out i.icn-search + .lb-input {
  height: 2.8rem;
  padding: 0 2.375rem 0 1.25rem;
}
.lb-input-out i.amount-symbol {
  right: 0;
  padding: 0 15px;
  color: #242424;
  font-style: normal;
}
.lb-input-out i.amount-symbol + .lb-input {
  padding-right: 40px;
}
.lb-input-out i.month-symbol {
  right: 0;
  padding: 0 15px;
  color: #242424;
  font-style: normal;
}
.lb-input-out i.month-symbol + .lb-input {
  padding-right: 40px;
}
.lb-input-out .loader-out {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lb-input {
  display: block;
  width: 100%;
  padding: 0 1.25rem;
  height: 3.125rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1em;
  color: #5d6163 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9e6;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
.lb-input::-ms-clear {
  display: none;
}

.lb-input.lb-input-grey {
  background-color: #fafafb;
  color: #919395;
  border: 1px solid #fafafb;
  transition: 0.3s;
}
.lb-input.lb-input-grey::-webkit-input-placeholder {
  color: #969899;
}
.lb-input.lb-input-grey::-moz-placeholder {
  color: #969899;
}
.lb-input.lb-input-grey:-ms-input-placeholder {
  color: #969899;
}
.lb-input.lb-input-grey:-moz-placeholder {
  color: #969899;
}

.lb-input.input-grey-fill {
  background-color: #edeef3;
  color: #1d262f;
  border: 1px solid #edeef3;
  transition: 0.3s;
}
.lb-input.input-grey-fill::-webkit-input-placeholder {
  color: #969899;
}
.lb-input.input-grey-fill::-moz-placeholder {
  color: #969899;
}
.lb-input.input-grey-fill:-ms-input-placeholder {
  color: #969899;
}
.lb-input.input-grey-fill:-moz-placeholder {
  color: #969899;
}
.lb-input.input-grey-fill:focus,
.lb-input.input-grey-fill .filled {
  background-color: #fff !important;
  color: #000;
  border: 1px solid #969899 !important;
}

.lb-input.transparent-input:focus {
  border-color: #fff;
}
.lb-input.transparent-input:focus + i.amount-symbol {
  color: #5d6163;
}
.lb-input.transparent-input.filled + i.amount-symbol {
  color: #5d6163;
}
::-webkit-input-placeholder {
  color: #969899;
}
::placeholder {
  color: #969899;
}

::-webkit-input-placeholder {
  color: #969899;
}

::-moz-placeholder {
  color: #969899;
}

:-ms-input-placeholder {
  color: #969899;
}

:-moz-placeholder {
  color: #969899;
}

.datepicker-out .lb-datepicker.lb-input {
  width: 110px;
  height: 40px;
  padding: 0 0.75rem;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 0.9375rem;
}
.datepicker-out.datepicker-single .lb-datepicker.lb-input {
  width: 130px;
}
.datepicker-out.datepicker-single .icn-calendar {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
.datepicker-out .min-date {
  color: #db0011;
  font-size: 0.75rem;
  padding-top: 3px;
  bottom: -20px;
}

.additional-filters .lb-input {
  width: 130px;
}

.icn-calendar.pointer {
  transition: 0.3s;
}
.icn-calendar.pointer:hover {
  color: #db0011;
}

.sms-input {
  width: 32px;
  height: 50px;
  margin-left: 10px;
  padding: 0;
  text-align: center;
  font-size: 1.3rem;
  text-shadow: 0 0 0 #5d6163 !important;
  font-size: 15px !important;
  border: 1px solid #242424;
}
.sms-input:focus {
  outline: none !important;
  border: 1px solid #d9d9e6;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
  border: 1px solid #242424;
}
.sms-input.empty:focus {
  background: #fff !important;
}
.sms-input.lb-input.filled, .sms-input.lb-input:focus {
  color: transparent !important;
}
.sms-input::selection {
  background: transparent;
}

.sms-input.empty {
  border: 1px solid #242424;
}
.sms-input.empty:focus {
  background: #fff !important;
}

/* =========== END of input modules ========== */
/* =========== Checkbox ========== */
.checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.checkbox-wrapper input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #171827;
  border-color: #171827;
}
.checkbox-wrapper input:checked ~ .checkmark svg {
  opacity: 1;
}
.checkbox-wrapper.dark-red input:checked ~ .checkmark {
  background-color: #db0011;
  border-color: #db0011;
}
.checkbox-wrapper.black input:checked ~ .checkmark {
  background-color: #171827;
  border-color: #171827;
}
.checkbox-wrapper span {
  order: 2;
}
.checkbox-wrapper.dark-red .checkmark {
  background: transparent;
}
.checkbox-wrapper .checkmark {
  position: relative;
  margin: 0 10px 0 0;
  order: 1;
  height: 20px;
  width: 20px;
  background-color: #fff;
  color: #fff;
  border: 1px solid #d9d9e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-wrapper .checkmark svg {
  width: 50%;
  height: 50%;
  opacity: 0;
}

.checkbox-br {
  position: relative;
}
.checkbox-br input {
  position: absolute;
  opacity: 0;
  z-index: -10;
  transition: 0.2s;
}
.checkbox-br input:checked + .checkmark {
  border: 4px solid #db0011;
}
.checkbox-br .checkmark {
  display: block;
  height: 1.125rem;
  width: 1.125rem;
  background: #fff;
  border: 2px solid #d9d9e6;
  border-radius: 4px;
}

/* =========== Checkbox End ========== */
/* =========== breadcrumb ========== */
.lb-breadcrumb {
  display: flex;
  align-items: left;
}

.lb-breadcrumb li {
  margin-right: 40px;
}

.lb-breadcrumb li .icon {
  margin-right: 14px;
}

/* =========== END of breadcrumb ========== */
/* =========== tooltip ========== */
/* =========== END of tooltip ========== */
/* =========== END of language switcher ========== */
/* =========== popup ========== */
.httperror-modal {
  z-index: 20 !important;
}

.transaction-modal .lb-block-body {
  min-height: 440px;
}
.transaction-modal .error-box {
  padding: 100px 0;
}
.transaction-modal .loader-container {
  min-height: 460px;
}

.currenttask-modal .lb-block-body {
  min-height: 320px;
}
.currenttask-modal .error-box {
  padding: 100px 0;
}
.currenttask-modal .loader-container {
  min-height: 340px;
}
.currenttask-modal .transaction-details .transaction-details-row .transaction-details-box:last-child {
  margin-bottom: 20px;
}

.lb-popup {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lb-popup .lb-block-head {
  padding: 20px 25px;
  color: #1d262f;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  font-size: 1rem;
}
.lb-popup .lb-block-head .flex-1 {
  margin-top: 5px;
}
.lb-popup .icn-close {
  color: #000;
  height: 0.75rem;
  width: 0.75rem;
  cursor: pointer;
  transition: 0.3s;
}
.lb-popup .icn-close:hover {
  color: #db0011;
}
.lb-popup .details-print {
  padding: 1.25rem 1.5rem;
  border-top: 1px solid #e9e9f1;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.lb-popup .details-print .print-box .icn.icn-print {
  transition: 0.2s;
  cursor: pointer;
}
.lb-popup .details-print .print-box:hover .icn.icn-print {
  color: #db0011;
}
.lb-popup .details-print .print-box:hover .icn.icon-swift {
  color: #db0011;
}
.lb-popup .details-print .print-box .loader-out.full-size {
  right: auto !important;
  left: -2px;
}

.calculate-popup {
  min-width: 150px !important;
}

.lb-popup-body {
  width: 80%;
  max-width: 500px;
  min-width: 350px;
  background: #f7f8f9;
  border-radius: 4px;
  overflow: hidden;
  outline: none;
}
.lb-popup-body .lb-block-head {
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid #dedee4;
  background: #e3e3e3;
}
.lb-popup-body.lb-popup-slider {
  overflow: visible;
}

.lb-popup-row {
  display: flex;
  align-items: center;
  padding: 0.9375rem 1.5625rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-popup-row.popup-row-lg {
  padding: 0.9375rem 1.5625rem;
}
.lb-popup-row:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}
.lb-popup-row span {
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.lb-popup-row .popup-img-out {
  width: 15%;
  margin-right: 10%;
}
.lb-popup-row .lb-popup-img {
  max-width: 100%;
  max-height: 60px;
}
.lb-popup-row .popup-label {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  color: #242424;
}
.lb-popup-row .popup-value {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.lb-popup-row .popup-value.in-span span {
  white-space: nowrap;
  position: relative;
  left: 3px;
}
.lb-popup-row .popup-value.in-span span > span {
  left: 20px;
}
.lb-popup-row .send-code {
  font-family: "Liberty-MT Bold", Arial, sans-serif !important;
}
.lb-popup-row .lb-input {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.75rem;
}

.sms-input-error .sms-input {
  border: 1px solid #eb293e;
}

.sms-input:not(.empty) {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.dot-input {
  font-family: "dotsfont" !important;
  font-size: 10px !important;
}

.sendcode-box {
  display: block;
}

.lb-utility-modal {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lb-utility-modal .message-block-icn svg {
  width: 48px;
  height: 48px;
}

.code-error {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: #eb293e;
  font-family: "Liberty-NUS Bold", Arial, sans-serif !important;
  font-size: 0.75rem;
}
.code-error .icn.icn-warning {
  margin-right: 1px;
}
.code-error .icn.icn-warning svg {
  color: #eb293e;
}

.lb-avatar-upload {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 30px 0;
}
.lb-avatar-upload .avatar-round-dark, .lb-avatar-upload .avatar-round, .lb-avatar-upload img {
  width: 125px;
  height: 125px;
  margin-bottom: 6px;
  border-radius: 50%;
}
.lb-avatar-upload.corp-avatar-upload {
  padding: 30px 0;
}
.lb-avatar-upload .avatar-round {
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
}
.lb-avatar-upload .avatar-round-dark {
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
}
.lb-avatar-upload .or {
  font-size: 12px !important;
}
.lb-avatar-upload p {
  font-size: 0.9375rem;
  line-height: 25px;
  color: #000;
}
.lb-avatar-upload .avatar-upload-box {
  margin-top: 9px;
}

.cropping-image-body {
  padding: 1.125rem 1.5625rem 1.875rem;
}

.cropping-image-footer {
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 1.5625rem 1.875rem;
  border-top: 1px solid #e9ecf2;
  align-items: center;
}

.lb-popup-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5625rem 1.5625rem 1.5625rem;
}
.lb-popup-footer .icn-refresh {
  color: #626263;
}

.popup-error .icn-clock {
  height: 45px;
  width: 45px;
}
.popup-error a {
  color: #e70d2f;
  text-decoration: underline;
}

.popup-success {
  color: #535a5e;
}
.popup-success .icn.icn-tick {
  height: 45px;
  width: 45px;
}
.popup-success .icn-clock {
  height: 45px;
  width: 45px;
}
.popup-success .is-green {
  color: #34ac34;
}
.popup-success a {
  color: #e70d2f;
  text-decoration: underline;
}

.popup-error {
  color: #535a5e;
}
.popup-error .icn.icn-round {
  height: 45px;
  width: 45px;
}
.popup-error .icn.icn-round svg {
  width: 45%;
  height: 45%;
}
.popup-error .is-warrning {
  color: #e70d2f;
}

/* =========== END of popup ========== */
/* =========== Header Widget ========== */
.widget-container {
  align-items: unset !important;
}
.widget-container .icn-warning-red svg {
  height: 20px;
}

.lb-widget {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  position: relative;
}
.lb-widget .widget-item {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  border: 0;
}
.lb-widget .widget-item.opac-4 {
  cursor: default;
}
.lb-widget .widget-item.opac-4 .icn-warning:hover {
  color: #242424;
}
.lb-widget .widget-item.opac-4:hover {
  background: transparent;
}
.lb-widget .widget-item.opac-4:hover .icn-circle {
  color: #242424;
}
.lb-widget .widget-item .icn-warning {
  width: 100%;
  height: 100%;
  color: #242424;
}
.lb-widget .widget-item .icn-warning:hover {
  color: #db0011;
}
.lb-widget .widget-item:hover {
  background: #fff;
}
.lb-widget .widget-item:hover .icn-circle {
  color: #db0011;
}
.lb-widget .widget-item:hover .icn-widget {
  color: #242424;
}
.lb-widget .widget-item.item-loading:hover {
  background: none;
}
.lb-widget .widget-item .loader-out + .icn-circle {
  display: none;
}
.lb-widget .widget-warning {
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  height: 1.1rem;
  width: 1.1rem;
  color: #fff;
  background: #1d262f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lb-widget .widget-warning svg {
  height: 55%;
}
.lb-widget .icn-info {
  position: absolute;
  top: -0.2rem;
  right: -0.3rem;
  background: #1d262f;
  color: #fff;
  border: 1px solid #1d262f;
}
.lb-widget .icn-widget {
  width: 100%;
  height: 100%;
  color: #242424;
  transition: 0.1s;
}
.lb-widget .icn-widget svg {
  width: 70%;
}
.lb-widget .icn-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #242424;
  transition: 0.3s;
  opacity: 0.9;
}
.lb-widget .icn-avatar-box .icn-widget svg {
  width: 40%;
}
.lb-widget .icn-phone-box .icn-widget svg {
  width: 33%;
}
.lb-widget .icn-arrows-box .icn-widget svg {
  width: 53%;
}
.lb-widget .icn-utility-box .icn-widget svg {
  width: 62%;
}
.lb-widget .icn-add-box .icn-widget svg {
  width: 54%;
}
.lb-widget .widget-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
}
.lb-widget .pay-abbr {
  background: transparent;
  width: 100%;
  height: 100%;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.lb-widget + .lb-tooltip {
  margin-top: 16px !important;
  line-height: 15px;
}

.opac-4 + .lb-tooltip {
  visibility: hidden !important;
}

.lb-widget.active .widget-item {
  background: #fff;
}
.lb-widget.active .widget-item .icn-circle {
  color: #db0011;
}
.lb-widget.active .widget-img {
  opacity: 0.7;
}

.lb-widget:not(:first-child) {
  margin: 0 0 0 10px;
}

.delete-widget {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 52%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 75%;
  width: 75%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.delete-widget svg {
  height: 40%;
  width: 40%;
}

.tooltip-delet.lb-tooltip {
  margin-top: 20px !important;
}

/* =========== END of Header Widget ========== */
/* =========== Slider ========== */
.lb-slider {
  position: relative;
}
.lb-slider .lb-block {
  margin-bottom: 0;
}
.lb-slider .lb-slider-prev,
.lb-slider .lb-slider-next {
  position: absolute;
  top: 50%;
  cursor: pointer;
  background: #1d262f;
  border-radius: 50%;
  color: #fff;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.lb-slider .lb-slider-prev:not(.disabled):hover,
.lb-slider .lb-slider-next:not(.disabled):hover {
  background: #db0011;
}
.lb-slider .lb-slider-prev svg,
.lb-slider .lb-slider-next svg {
  max-width: 0.87rem;
  max-height: 50%;
  position: relative;
  bottom: -1px;
}
.lb-slider .lb-slider-prev.disabled,
.lb-slider .lb-slider-next.disabled {
  opacity: 0.4;
  cursor: default;
}
.lb-slider .lb-slider-prev {
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
  right: 103%;
}
.lb-slider .lb-slider-next {
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
  left: 103%;
}
.lb-slider .slider-dots {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 0;
}
.lb-slider .slider-dot {
  background-color: #b7b7b7;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.lb-slider .slider-dot:not(:last-child) {
  margin: 0 5px 0 0;
}
.lb-slider .slider-dot.active {
  background: #db0011;
}

/* =========== END of Slider ========== */
.round-flag {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  overflow: hidden;
  background: red;
}

/* =========== Templates - avatar  ========== */
.avatar-round {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  color: #242424;
  background: #e3e3e3;
  cursor: pointer;
}
.avatar-round svg {
  width: 40%;
  height: 40%;
}

.img-border {
  border: 1px solid #949494;
  background: transparent;
}

.lb-select .avatar-round {
  height: 55px;
  width: 55px;
  margin-right: 0.9375rem;
  color: #242424;
}

/* =========== Templates - avatar end  ========== */
/* =========== Templates - avatar dark ========== */
.avatar-round-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  color: #242424;
  background: #e3e3e3;
  cursor: pointer;
}
.avatar-round-dark svg {
  width: 55%;
  height: 45%;
}

.lb-select .avatar-round-dark {
  height: 55px;
  width: 55px;
  margin-right: 0.9375rem;
}

.avatar-text {
  margin-left: 1.25rem;
  cursor: pointer;
}

/* =========== Templates - avatar dark end  ========== */
/* =========== Templates - tooltip  ========== */
.__react_component_tooltip.tip-blocked {
  pointer-events: all;
}

.__react_component_tooltip.type-dark {
  opacity: 1;
}
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: #8d9195;
}

.tooltip-parent {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.tooltip-parent-grid {
  display: block;
  max-width: 100%;
  height: 100%;
}

.lb-tooltip {
  max-width: 50%;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.lb-tooltip.type-darker {
  background: #414141;
  color: #fff;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  border-radius: 4px;
  font-size: 0.75rem;
  font-style: normal;
  opacity: 1 !important;
}
.lb-tooltip.type-darker.place-top:after {
  border-top-color: #414141 !important;
}
.lb-tooltip.type-darker.place-bottom:after {
  border-bottom-color: #414141 !important;
}
.lb-tooltip.type-darker.place-right:after {
  border-right-color: #414141 !important;
}
.lb-tooltip.type-darker.place-left:after {
  border-left-color: #414141 !important;
}
.lb-tooltip.type-light {
  background: #d3d3d3;
  color: #000;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  border-radius: 4px;
  font-size: 0.75rem;
  font-style: normal;
}
.lb-tooltip.type-light.place-top:after {
  border-top-color: #d3d3d3 !important;
}
.lb-tooltip.type-light.place-bottom:after {
  border-bottom-color: #d3d3d3 !important;
}
.lb-tooltip.type-light.place-right:after {
  border-right-color: #d3d3d3 !important;
}
.lb-tooltip.type-light.place-left:after {
  border-left-color: #d3d3d3 !important;
}
.lb-tooltip.tip-blocked {
  width: 430px;
  padding-right: 10px;
}
.lb-tooltip.tip-waittrust {
  width: 255px;
}
.lb-tooltip.tip-trust {
  width: 140px;
}
.lb-tooltip.tip-trustworthy {
  width: 361px;
  padding-right: 10px;
}
.lb-tooltip.tip-mminbalance {
  width: 372px;
}
.lb-tooltip .blocked-amounts-tooltip .thumb-vertical {
  background-color: #919395;
}
.lb-tooltip .blocked-amounts-tooltip .tip-container {
  margin-right: 20px;
}
.lb-tooltip .tip-title {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  border-bottom: 1px solid #242424;
  padding: 0.5rem 0;
  font-size: 0.6875rem;
}
.lb-tooltip .tip-body {
  padding: 0.3rem 0;
}
.lb-tooltip .tip-body .thumb-vertical {
  background-color: #919395;
}
.lb-tooltip .tip-row {
  display: flex;
  align-items: flex-start;
  padding: 0.3rem 0;
}
.lb-tooltip .is-red,
.lb-tooltip .is-green {
  transition: none;
}

.lb-main .lb-tooltip {
  max-width: 400px;
}

/* =========== Templates - tooltip end  ========== */
/* =========== DAYPICKER ========== */
.DayPicker {
  min-height: 295px;
  /* temporary or permanent; purpose: to visually disable outside and disabled days highlight on hover */
}
.DayPicker div.DayPicker-Day--disabled.DayPicker-Day--outside:hover {
  background-color: #eff1f1 !important;
  outline: none !important;
}
.DayPicker .DayPicker-wrapper {
  outline: none !important;
  padding-bottom: 20px;
  margin-top: 5px;
}
.DayPicker .DayPicker-wrapper .DayPicker-Months {
  min-height: 290px;
  display: table-cell !important;
}
.DayPicker .DayPicker-Caption {
  padding: 0;
  margin-bottom: 0.95rem;
}
.DayPicker .DayPicker-Caption > div {
  font-size: 0.75rem;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}
.DayPicker .DayPicker-Day {
  padding: 0;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 0.75rem;
  color: #1d262f;
  border: 1px solid #e9e9f1;
  outline: none;
  border-radius: 0;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}
.DayPicker .DayPicker-Day.DayPicker-Day--outside {
  color: #ccc;
}
.DayPicker .DayPicker-Day.DayPicker-Day--outside:hover {
  background-color: transparent !important;
  border-radius: 0;
  outline: 1px solid #db0011;
  border-radius: 0 !important;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent !important;
  border-radius: 0;
  outline: 1px solid #db0011;
  border-radius: 0 !important;
}
.DayPicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff;
  background-color: #db0011 !important;
  border-radius: 0;
}
.DayPicker .DayPicker-Day--disabled {
  background-color: #eff1f1 !important;
  border-radius: 0;
}
.DayPicker .calendar-arrow {
  height: 12px;
  width: 7px;
  color: #717073;
  cursor: pointer;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  text-transform: uppercase;
}
.DayPicker .App.ka .calendar-arrow {
  text-transform: lowercase;
}
.DayPicker .DayPicker-Day--today {
  font-weight: normal !important;
}
.DayPicker .month-block {
  width: 78px;
  text-align: center;
}
.DayPicker .year-block {
  width: 40px;
  text-align: center;
}

/* =========== DAYPICKER END ========== */
.icn-export-wrap .icn {
  transition: 0.2s;
  cursor: pointer;
}
.icn-export-wrap .icn:hover {
  color: #db0011 !important;
}

.icn-wrap {
  border: 1px solid #646464;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.icn-wrap:hover {
  border: 1px solid #db0011;
  color: #db0011;
  cursor: pointer;
}
.icn-wrap:hover .icn-download,
.icn-wrap:hover .icn-print,
.icn-wrap:hover .icon-swift {
  color: #db0011;
}

.download-outer {
  width: 45px;
  height: 45px;
}

.icn-download {
  transition: 0.2s;
}

.transfers-block .currency-info-block {
  position: absolute;
  left: 0;
  top: -31px;
}
.transfers-block .lb-input::selection {
  background: none;
}
.transfers-block .info-txt {
  font-family: "Liberty-Nus Regular", Arial, sans-serif !important;
  font-size: 14px;
  color: #d9d9d9;
}

.currency-info-block {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.75rem;
}

.btn-back {
  position: relative;
  z-index: 4;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.btn-back.lb-btn {
  height: auto !important;
}

.lb-input-err {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.8125rem;
  text-align: left;
}
.lb-input-err.show-error {
  display: block !important;
  color: #db0011 !important;
}

.popover {
  transition: opacity 0.2s;
  position: absolute;
  z-index: 1;
  top: calc(50% + 15px);
  right: 0;
  min-width: 260px;
  padding: 0.35rem 0;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.popover-action {
  padding: 0.5rem 1rem;
  color: #5d6163;
  font-family: "Liberty-Nus", Arial, sans-serif;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.1s;
}
.popover-action:hover {
  background: #efefef;
}

.no-items {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  font-size: 0.875rem;
  text-align: center;
  background: #1d262f;
  color: #fff;
}

.lb-confirm-text {
  padding: 1.5rem 1.5rem;
  font-size: 0.875rem;
}

.home-btn {
  color: white;
  font-size: 14px;
}

.home-btn:hover {
  color: white;
}

/*dashboard banner*/
.banner-liberty img,
.Personal-banker img {
  max-width: 100%;
}

.draggable-block {
  outline: none;
  margin-bottom: 30px;
  max-width: 980px;
}
.draggable-block .lb-image-out {
  cursor: grab;
}

.draggable-item {
  background: #fff;
  outline: none;
}
.draggable-item:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}
.draggable-item:hover {
  background: rgba(243, 243, 243, 0.44);
}

.welcome-page {
  width: 100%;
  min-height: 625px;
  position: relative;
}
.welcome-page .lb-block-head {
  min-height: 280px;
  background-color: #f6f6f6;
  border-bottom: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.welcome-page .slidre-block {
  position: absolute;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.welcome-page .slidre-block .slider-logo {
  width: 127px;
  height: 22px;
  margin-bottom: 30px;
}
.welcome-page .slidre-block .todo-list {
  max-width: 680px;
  padding: 0 25px;
}
.welcome-page .slidre-block .slider-title {
  font-size: 0.875rem;
  font-family: "Liberty-MT Bold", Arial, sans-serif;
  text-align: center;
}
.welcome-page .slidre-block .slider-title .line-dec {
  height: 1px;
  width: 38px;
  border-radius: 10px;
  background: #db0011;
  margin: 5px 0;
}
.welcome-page .slidre-block .slider-description {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  text-align: center;
  height: 42px;
}
.welcome-page .slidre-block .slider-footer {
  width: 100%;
  padding: 25px 30px;
}
.welcome-page .slidre-block .slider-footer .slider-num div:first-child {
  width: 10px;
}
.welcome-page .slidre-block .slider-footer .slider-num div:last-child {
  color: #cbcac9;
}
.welcome-page .slidre-block .slider-footer .slider-dots {
  text-align: center;
  line-height: 0;
}
.welcome-page .slidre-block .slider-footer .slider-dots .slider-dot {
  background-color: #e1e1e1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.welcome-page .slidre-block .slider-footer .slider-dots .slider-dot:not(:last-child) {
  margin: 0 13px 0 0;
}
.welcome-page .slidre-block .slider-footer .slider-dots .slider-dot.active {
  background: #db0011;
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 414px) {
  .welcome-page .slidre-block .slider-footer .slider-dots .slider-dot {
    width: 12px;
    height: 12px;
  }
  .welcome-page .slidre-block .slider-footer .slider-dots .slider-dot.active {
    background: #db0011;
    width: 14px;
    height: 14px;
  }
}
.lb-banner {
  display: block;
  border-radius: 4px;
  line-height: 0;
  overflow: hidden;
}

.record-notfound {
  border: 2px dashed #e9ecf2;
  border-radius: 4px;
  padding-bottom: 55px;
}
.record-notfound .icon-notfoundrecord {
  width: 27px;
  height: 32px;
}

.maintenace-page {
  min-height: 550px !important;
}
.maintenace-page .icon-404 {
  width: 63px;
  height: 53px;
  margin-top: 40px;
}
.maintenace-page .text-line {
  font-size: 1.125rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #1d262f;
}
.maintenace-page .text-geo::after {
  content: "";
  background: #db0011;
  height: 1px;
  max-width: 55px;
  width: 100%;
  border-radius: 4px;
  display: inline-block;
}
.maintenace-page .additional-info {
  font-size: 0.75rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #464a4e;
  margin-top: 10vh;
  line-height: 18px;
}
.maintenace-page .additional-info span {
  color: #db0011;
}

@media screen and (max-width: 1550px) {
  .lb-slider .lb-slider-next,
  .lb-slider .lb-slider-prev {
    width: 2rem;
    height: 2rem;
  }
  .lb-slider .lb-slider-prev {
    right: 101.5%;
  }
  .lb-slider .lb-slider-next {
    left: 101.5%;
  }
  .lb-block.details-block .lb-block-head .lb-subnav-item {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1366px) {
  .lb-block-title {
    font-size: 0.875rem;
  }
  .lb-block-row .is-name {
    font-size: 0.875rem;
  }
  .lb-block-row .is-amount {
    font-size: 0.9375rem;
    font-family: "Liberty-Nus Medium", Arial, sans-serif;
    color: #242424;
  }
  .lb-block-row .is-amount-sm {
    font-size: 0.8125rem;
    font-family: "Liberty-Nus Medium", Arial, sans-serif;
    color: #242424;
  }
  .lb-block-row .is-info {
    font-size: 0.9375rem;
    font-family: "Liberty-Nus Medium", Arial, sans-serif;
    color: #242424;
  }
  .lb-btn {
    height: 2.5rem;
    font-size: 0.85rem;
    padding: 0 1.2rem;
  }
  .lb-btn.btn-dark, .lb-btn.btn-border, .lb-btn.btn-red {
    font-size: 0.85rem;
  }
  .lb-account-details .lb-btn {
    padding: 0 1rem !important;
  }
  .lb-slider .lb-slider-prev {
    right: 100.5%;
  }
  .lb-slider .lb-slider-next {
    left: 100.5%;
  }
  .lb-block.details-block .lb-block-head .lb-subnav-item {
    font-size: 0.75rem;
  }
  .draggable-block {
    max-width: 945px;
  }
}
.lb-account-summary .available-balance {
  font-size: 0.75rem;
}
.lb-account-summary .blocked-amount {
  font-size: 0.75rem;
}
.lb-account-summary .balance {
  font-size: 0.75rem;
}

.available-balance {
  font-size: 0.8125rem;
}

.blocked-amount {
  font-size: 0.8125rem;
}

.balance {
  font-size: 0.8125rem;
}

@media screen and (max-width: 1450px) {
  .available-balance {
    font-size: 0.75rem;
  }
  .blocked-amount {
    font-size: 0.75rem;
  }
  .balance {
    font-size: 0.75rem;
  }
  .lb-account-summary {
    padding: 1rem;
  }
}
@media screen and (max-width: 1280px) {
  .draggable-block {
    max-width: 885px;
  }
  .lb-block-title {
    font-size: 0.8125rem;
  }
}
.block-unblock .popup-row-style i {
  width: 30px;
  height: 25px;
}
.block-unblock i.top-card {
  height: 16px;
}
.block-unblock i.limit-card {
  height: 18px;
}

.card-update .popup-row-style i {
  width: 16px;
  height: 16px;
}

.choose-cards-wrapper {
  display: inline-block;
  width: 100%;
}
.choose-cards-wrapper .card-row-item {
  position: relative;
}
.choose-cards-wrapper .card-row-item .card-img {
  width: 80px;
  position: relative;
}
.choose-cards-wrapper .card-row-item .card-img .label-digital {
  position: absolute;
  width: 30px;
  height: auto;
  left: -7px;
  top: -12px;
}
.choose-cards-wrapper .card-row-item .card-img .label-digital img {
  width: 16px;
}
.choose-cards-wrapper .card-row-item .card-img img {
  width: 100%;
}
.choose-cards-wrapper .card-row-item .card-info {
  padding-left: 15px;
  width: 85%;
  display: inline-block;
}
.choose-cards-wrapper .card-row-item .card-info h4 {
  font-size: 14px;
  margin: 0;
  color: #484848;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.choose-cards-wrapper .card-row-item .card-info p {
  margin-top: 3px;
  font-size: 12px;
  line-height: 1;
  color: #484848;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.choose-cards-wrapper .card-row-item .card-info span {
  font-size: 12px;
  display: inline-flex;
  line-height: 1;
  margin: 0;
  color: #484848;
  position: relative;
  bottom: 2px;
}

.popup-row-style {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 1px solid #e9e9f1;
  border-left: 1px solid transparent;
}
.popup-row-style:last-child {
  border-bottom: none;
}
.popup-row-style:hover {
  background: #fff;
  border-left: 1px solid #db0011;
  cursor: pointer;
}
.popup-row-style i.icn-arr-right {
  height: 14px;
  width: 10px;
  opacity: 0;
  color: #db0011;
}
.popup-row-style:hover i.icn-arr-right {
  opacity: 1;
}
.popup-row-style span {
  flex: 1 1;
  font-size: 0.9375rem;
  color: #000;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.popup-row-style i {
  text-align: center;
  color: #1d262f;
  transition: 0.2s;
  width: 25px;
  height: 26px;
}
.popup-row-style .icn-list-template {
  margin-right: 1.5625rem;
}
.popup-row-style.choose-card {
  padding: 0.75rem 1.5rem;
}
.popup-row-style.choose-card .img-template {
  margin-right: 1.5625rem;
  width: 77px;
  height: 49px;
  position: relative;
}
.popup-row-style.choose-card .img-template .label-digital {
  position: absolute;
  width: 30px;
  height: auto;
  left: -7px;
  top: -12px;
}
.popup-row-style.choose-card .img-template .label-digital img {
  width: 16px;
}
.popup-row-style.choose-card .img-template img {
  width: 100%;
}
.popup-row-style.choose-card .card-name {
  color: #000;
}
.popup-row-style.choose-card .card-iban {
  color: #484848;
}
.popup-row-style.choose-card:first-child {
  border-top: 1px solid #e9e9f1;
  margin-top: 20px;
}
.popup-row-style.choose-card:last-child {
  border-bottom: 1px solid #e9e9f1;
  margin-bottom: 20px;
}

.choose-action .icn-warnings-red {
  width: 22px;
  height: 19px;
}

.fixed-notification {
  position: fixed;
  bottom: 3vh;
  right: 4vh;
  padding: 1.25rem 2.6rem 1.25rem 1rem;
  color: #fff;
  border-radius: 4px;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 11;
}
.fixed-notification.show {
  -webkit-animation-name: animScale;
          animation-name: animScale;
}
.fixed-notification.hide {
  -webkit-animation-name: animScaleOut;
          animation-name: animScaleOut;
}
.fixed-notification .icn.icn-close {
  position: absolute;
  top: 0.9rem;
  right: 0.75rem;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.fixed-notification .icn.icn-status {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 1rem;
}
.fixed-notification .notif-text {
  font-size: 0.875rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}

.Toastify__toast-container {
  width: auto !important;
  max-width: 450px;
}

.Toastify__close-button {
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none;
  border: none;
}

.Toastify__toast--warning {
  padding: 8px !important;
}

.Toastify__toast {
  min-height: 80px !important;
  border-radius: 4px;
}

.Toastify__progress-bar {
  height: 10px !important;
}

.global-notification {
  padding: 5px;
  color: #fff;
}
.global-notification .icn.icn-close {
  position: absolute;
  top: 0.9rem;
  right: 0.75rem;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.global-notification .icn.icn-status {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 1rem;
}
.global-notification .notif-text {
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}

@-webkit-keyframes animScale {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
            transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@keyframes animScale {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
            transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
@-webkit-keyframes animScaleOut {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
            transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
}
@keyframes animScaleOut {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
            transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
}
.global-settings {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 5;
  font-size: 0.5rem;
}
.global-settings .bb {
  border-bottom: 1px solid #969899;
}

.br-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@media screen and (max-height: 649px) {
  .utility-popup .lb-popup-row.popup-row-lg {
    padding: 0.5rem 1.5625rem;
  }
  .utility-popup .lb-popup-footer {
    padding: 0.5rem 1.5625rem 1.5625rem;
  }
}
@media screen and (max-height: 550px) {
  .transfer-modal .lb-block-body {
    height: 400px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 414px) {
  .never-showgain {
    flex-direction: column;
  }
  .never-showgain_btn {
    margin-top: 10px;
  }
  .lb-popup-row.popup-row-lg {
    padding: 0.9375rem 0.9375rem;
  }
}
.lb-api-err {
  font-size: 0.875rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  color: #db0011;
  text-align: left;
  line-height: 1.3;
}
.lb-api-err .icn {
  width: 30px;
  height: 30px;
}

.icn.icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 21px;
  height: 21px;
  padding-top: 3px;
  cursor: pointer;
  padding-left: 4px;
  transition: 0.3s;
}
.icn.icn-dark-circle svg {
  height: 14px;
}

.lb-newsms-code {
  margin-bottom: 15px;
}
.lb-newsms-code .icn {
  width: 19px;
  height: 19px;
  color: #707070;
}
.lb-newsms-code .lb-btn-newcode {
  font-size: 0.8125rem;
  font-family: "Liberty-MT Medium", Arial, sans-serif;
  color: #626263;
  border: none;
  background-color: transparent;
  padding: 0;
  height: auto;
}

.lb-btn.lb-btn-new {
  min-width: 160px;
}

.authorization-block .flex-col {
  flex-direction: row !important;
}
.authorization-block .flex-col .flex {
  order: 2;
}
.authorization-block .flex-col .lb-newsms-code {
  order: 1;
  margin-top: 0 !important;
  margin-right: 20px;
}

.icn.icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 21px;
  height: 21px;
  padding-top: 3px;
  cursor: pointer;
  padding-left: 4px;
  transition: 0.3s;
}
.icn.icn-dark-circle svg {
  height: 14px;
}
.icn.icn-dark-circle:hover {
  background: #242424;
}
.icn.icn-dark-circle:hover svg {
  color: #fff;
}

.switch-rail {
  width: 2rem;
  height: 1rem;
  background: #e3e3e3;
  border-radius: 8px;
  margin: 0 0;
  position: relative;
  cursor: pointer;
}
.switch-rail .switch-rail-dot {
  position: absolute;
  top: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  box-shadow: 15px 13px 34px 0 rgba(0, 0, 0, 0.1);
}
.switch-rail.active .switch-rail-dot {
  background: #242424;
  -webkit-transform: translateX(1rem);
          transform: translateX(1rem);
}
.switch-rail.not-active .switch-rail-dot {
  background: #fff;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.grid-details {
  justify-content: space-between;
  padding: 0 25px;
}
.grid-details .grid-details-box {
  padding: 10px 0 3px;
  border-bottom: 1px solid #e6e6e6;
}
.grid-details .grid-details-box h3 {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 6px;
}
.grid-details .grid-details-box:last-child {
  border-bottom: none;
  padding: 10px 0 3px;
}
.grid-details .grid-details-box .grid-details-col-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
}
.grid-details .grid-details-box .grid-details-col-group .icn-chat svg {
  width: 27px;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col-lable {
  font-size: 0.9375rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #242424;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col {
  line-height: 23px;
  color: #242424;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col .lb-input {
  height: 40px;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col .print-box {
  padding: 1.5625rem 1.875rem;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col .print-box span {
  font-weight: bold;
  color: #1d262f;
  font-size: 0.875rem;
}
.grid-details .grid-details-box .grid-details-col-group .grid-details-col .lb-tooltip {
  max-width: 400px;
}

.loader-authoriz {
  position: relative;
  width: 100%;
  top: -27px;
}

/* =========== Mobile Check modal  ========== */
.lb-mobile-check {
  padding: 22px 24px;
}
.lb-mobile-check .lb-check-left {
  background-color: #eaeaea;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin: 5px 20px 0 0;
}
.lb-mobile-check .lb-check-left .icn-mobile-check {
  width: 40px;
  height: 40px;
}
.lb-mobile-check .lb-check-right h3 {
  font-family: "libery-Nus bold", Arial, sans-serif;
  font-size: 0.9375rem;
  margin-bottom: 5px;
}
.lb-mobile-check .lb-check-right ul {
  font-size: 0.875rem;
  line-height: 24px;
}
.lb-mobile-check .lb-check-right ul li::before {
  content: "";
  background: #db0011;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}

.lb-uploade-block {
  padding: 1.5rem;
}
.lb-uploade-block .dzu-dropzone {
  min-height: 163px !important;
  background-color: #fff;
  border: 2px dashed #c9ced6 !important;
  overflow: hidden !important;
  margin-bottom: 2rem;
}
.lb-uploade-block .dzu-dropzone .dzu-inputLabel {
  font-size: 14px;
  font-family: "Liberty-Nus medium", Arial, sans-serif;
  font-weight: normal !important;
  color: #000 !important;
}
.lb-uploade-block .dzu-dropzone .dzu-previewContainer {
  border: none !important;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 !important;
  justify-content: center !important;
}
.lb-uploade-block .dzu-dropzone .dzu-previewContainer .dzu-previewFileName {
  font-size: 14px;
  font-family: "Liberty-Nus medium", Arial, sans-serif;
}
.lb-uploade-block .checkbox-wrapper .checkmark {
  width: 16px;
  height: 16px;
}
.lb-uploade-block .checkbox-wrapper .nowrap {
  white-space: normal;
  flex: 1 1;
}

.cat-icn {
  width: 32px;
  height: 32px;
}
.cat-icn svg {
  width: 32px;
  height: 32px;
}

.lb-transactions-container .lb-block-subhead {
  padding: 1rem 1.5rem;
  border-bottom: none;
  font-size: 0.75rem;
  background: #f3f3f3;
}
.lb-transactions-container .lb-block-body {
  padding: 0 !important;
}
.lb-transactions-container .lb-block-body .lb-block-row {
  padding: 1rem 1.5rem;
}
.lb-transactions-container .lb-block-body .lb-block-row .icn-arr-right {
  color: #b0b0b5;
}
.lb-transactions-container .lb-block-body .lb-block-row .icn-arr-right:last-child {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  height: 24px;
  width: 24px;
  right: 16px;
  display: flex;
  justify-content: center;
}
.lb-transactions-container .lb-block-body .lb-block-row .icn-arr-right:last-child svg {
  width: 6px;
  height: 10px;
}
.lb-transactions-container .lb-block-body .lb-block-row:hover .icn-arr-right, .lb-transactions-container .lb-block-body .lb-block-row.active .icn-arr-right {
  color: #db0011;
}
.lb-transactions-container .lb-block-body .lb-block-row:not(:last-child) {
  border-bottom: none !important;
}
.lb-transactions-container .lb-block-body .lb-block-row:not(:last-child):after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e9ecf2;
  position: absolute;
  bottom: 0;
  left: 68px;
}
.lb-transactions-container .lb-block-row > :not(:last-child),
.lb-transactions-container .lb-block-subhead > :not(:last-child) {
  padding-right: 0 !important;
}

.lb-progress {
  width: 100%;
}
.lb-progress.lb-progressfin {
  width: auto;
}
.lb-progress.lb-progressfin .progress-bar__track {
  width: 626px;
  overflow: hidden;
  background-color: #e5e5e5;
}
.lb-progress .pbar-cont {
  width: 697px;
  margin-right: 12px;
}
.lb-progress .progress-bar__track {
  height: 12px;
  background-color: #d9d9e6;
  position: relative;
  border-radius: 0px 6px 6px 0px;
}
.lb-progress .progress-bar__bar {
  height: 12px;
  position: absolute;
}
.lb-progress .progress-bar__bar.green {
  background: transparent linear-gradient(270deg, #2a985a 0%, #8ecba9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 6px rgba(43, 153, 92, 0.1215686275);
  border-radius: 0px 6px 6px 0px;
  opacity: 1;
}
.lb-progress .progress-bar__bar.grey {
  background: transparent linear-gradient(270deg, #3e3d3d 0%, #9a9a9a 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px -1px 6px rgba(84, 83, 83, 0.1215686275);
  border-radius: 0px 11px 11px 0px;
  opacity: 1;
}

.lb-myfincat-modal .lb-block-body {
  padding: 26px 0 0;
}
.lb-myfincat-modal .lb-myfincat-search {
  margin-bottom: 32px;
  padding: 0 24px;
}
.lb-myfincat-modal .lb-myfincat-search .lb-input-grey {
  background-color: #f2f2f2 !important;
  height: 56px;
  border-radius: 6px;
  border: 1px solid #e4e8eb;
}
.lb-myfincat-modal .lb-myfincat-search .icn-search {
  width: 24px;
  height: 24px;
  right: 24px;
}
.lb-myfincat-modal .lb-myfincat-row {
  padding: 0 34px 0 24px;
  margin-bottom: 16px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-myfincat-title {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-myfincat-title h3 {
  font-size: 0.875rem;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  color: #000000;
  font-weight: normal;
  padding-left: 12px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-myfincat-title .cat-icn {
  width: 32px;
  height: 32px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-myfincat-title .cat-icn svg {
  width: 32px;
  height: 32px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-myfincat-title .cat-icn img {
  width: 32px;
  height: 32px;
}
.lb-myfincat-modal .lb-myfincat-row .lb-subcat-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin: 0 0 0 40px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e8eb;
}
.lb-myfincat-modal .lb-myfincat-row .lb-subcat-row .lb-subcat {
  width: auto;
  display: inline-block;
  border: 1px solid #dadddf;
  border-radius: 16px;
  padding: 6px 12px;
  margin-bottom: 8px;
  margin-right: 8px;
  font-size: 0.75rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  color: #000000;
}
.lb-myfincat-modal .lb-myfincat-row .lb-subcat-row .lb-subcat.active {
  border: 1px solid #da0110;
  background-color: #fff5f6;
  color: #da0110;
}

.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

/* =========== Select Styles ========== */
.select-wrap {
  position: relative;
  width: 100%;
}

.lb-select {
  text-align: left;
  border-radius: 4px;
  outline: none;
}
.lb-select.bank-select .Select-menu-outer {
  max-height: 170px;
}
.lb-select .Select-menu-outer {
  max-height: 260px;
}
.lb-select.hide-input .Select-input input {
  width: 0 !important;
  padding: 0 !important;
}
.lb-select:not(.no-arrow):not(.is-open) .Select-input input {
  position: absolute;
  top: -1000px;
  left: -1000px;
}
.lb-select .sl-large-bold {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.875rem;
  color: #242424;
}
.lb-select .sl-info-light {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.75rem;
  color: #74777a;
}
.lb-select .sl-info {
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.75rem;
  color: #242424;
}
.lb-select .sl-info-m {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
  font-size: 0.75rem;
  color: #242424;
}
.lb-select .sl-small-bold {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.75rem;
  color: #242424;
}
.lb-select.select-transparent .Select-value-label .sl-large-bold,
.lb-select.select-transparent .Select-value-label .sl-info-light,
.lb-select.select-transparent .Select-value-label .sl-info,
.lb-select.select-transparent .Select-value-label .sl-info-m,
.lb-select.select-transparent .Select-value-label .sl-small-bold, .lb-select.select-transparent-full .Select-value-label .sl-large-bold,
.lb-select.select-transparent-full .Select-value-label .sl-info-light,
.lb-select.select-transparent-full .Select-value-label .sl-info,
.lb-select.select-transparent-full .Select-value-label .sl-info-m,
.lb-select.select-transparent-full .Select-value-label .sl-small-bold {
  color: #fff;
}
.lb-select .disabled-text {
  line-height: normal;
  position: absolute;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
  white-space: normal;
}
.lb-select .disabled {
  cursor: default;
}
.lb-select .disabled > img, .lb-select .disabled > div, .lb-select .disabled > i {
  opacity: 0.6;
}
.lb-select .disabled .icn.toggle-favorite:hover {
  color: #242424 !important;
}
.lb-select .disabled .lb-tooltip {
  display: none;
}
.lb-select .disabled:hover .template-details {
  opacity: 0 !important;
}
.lb-select .disabled:hover .disabled-text {
  opacity: 1;
  z-index: 0;
}
.lb-select .disabled:hover .month-details {
  opacity: 0;
  transition: 0.2s;
}
.lb-select .Select-value {
  display: flex;
  align-items: center;
}
.lb-select .Select-value-label {
  width: 100%;
}
.lb-select .option-element {
  line-height: normal;
}
.lb-select .option-element:not(:last-child) {
  margin-bottom: 3px;
}
.lb-select .option-element.sl-large-bold {
  margin-bottom: 2px;
}
.lb-select .selected-option {
  line-height: normal;
}
.lb-select .selected-option .value-element {
  line-height: normal;
}
.lb-select .selected-option .value-element:not(:last-child) {
  margin-bottom: 3px;
}
.lb-select .selected-option .transfer-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  margin-right: 0.9375rem;
}
.lb-select .selected-option .icn-star {
  width: 10px;
  height: 10px;
}
.lb-select .Select-option-group-label {
  font-family: "Liberty-MT", Arial, sans-serif;
  font-size: 0.75rem;
  background: #eff1f4;
  color: #74777a;
}
.lb-select .Select-control {
  height: 100%;
  border-radius: 4px;
  box-shadow: none !important;
}
.lb-select .Select-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-family: "Liberty-Nus", Arial, sans-serif;
  cursor: pointer;
}
.lb-select .Select-placeholder .flex-space {
  height: 100%;
}
.lb-select .Select-option,
.lb-select .Select-option.is-focused {
  color: #242424;
}
.lb-select .Select-option.is-selected {
  background-color: transparent;
}
.lb-select .Select-input {
  width: 100%;
}
.lb-select.is-disabled .Select-value-label {
  color: #c9cacc !important;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.lb-select.is-disabled .Select-control {
  background: #fff !important;
}
.lb-select.bank-select.is-disabled .Select-value {
  background: #eff1f4;
  z-index: 5;
}
.lb-select.bank-select.is-disabled .Select-value-label {
  color: #828282 !important;
}
.lb-select.is-open .Select-control {
  background: #fafafa;
}
.lb-select.is-open .Select-arrow-zone .icn-arrow-down {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: relative;
  top: -2px;
}
.lb-select.is-open .Select-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.lb-select.is-open .Select-placeholder {
  font-family: "Liberty-Nus", Arial, sans-serif !important;
  color: #242424 !important;
}
.lb-select.is-open:not(.no-border) .Select-control {
  border-top: 1px solid #a5a5b1 !important;
  border-left: 1px solid #a5a5b1 !important;
  border-right: 1px solid #a5a5b1 !important;
  border-bottom: 1px solid #a5a5b1 !important;
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.lb-select.is-open:not(.no-border) .Select-menu-outer {
  border-top: 0 !important;
  top: calc(100% - 2px);
}
.lb-select.is-open:not(.no-border) .Select-menu-outer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  width: calc(100% - 2px);
  height: 1px;
  background: #d9d9e6;
}
.lb-select.is-open:not(.no-border) .lb-scrollbar {
  border-bottom: 1px solid #e4e6ea;
  border-left: 1px solid #e4e6ea;
  border-right: 1px solid #e4e6ea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-select .Select-menu-outer {
  border-radius: 4px;
  border: 0;
  box-shadow: none;
}
.lb-select .Select-menu {
  outline: none;
  max-height: 300px;
  border-radius: 4px;
  box-shadow: 0 10px 30px rgba(70, 74, 78, 0.1);
}
.lb-select .select-option {
  display: flex;
  align-items: center;
}
.lb-select .select-option,
.lb-select .select-option.is-focused {
  background: #fff;
}
.lb-select .select-option:hover {
  background: #db0011;
  color: #fff;
}
.lb-select.account-select .select-option:hover, .lb-select.no-border .select-option:hover {
  background: #fafafa;
  color: #242424;
}
.lb-select.account-select .select-option:hover .icn-add-person, .lb-select.no-border .select-option:hover .icn-add-person {
  background: #db0011;
  color: #fff;
}
.lb-select.is-open .Select-control .Select-arrow-zone {
  display: none;
}
.lb-select .Select-arrow-zone {
  font-size: 0;
  padding-right: 1rem !important;
}
.lb-select .Select-arrow-zone .icn-arrow-down {
  height: 7px;
  width: 13px;
  color: #717073;
  position: relative;
  top: 2px;
  transition: 0.2s;
}
.lb-select .Select-arrow {
  border: 0;
}
.lb-select.account-select .Select-input {
  background: transparent !important;
}
.lb-select.account-select .account-img-out {
  position: relative;
  width: 5rem;
  height: 3.125rem;
  margin-right: 0.625rem;
}
.lb-select.account-select .account-img-out .account-img {
  width: 100%;
  height: 100%;
  margin: 0;
}
.lb-select.account-select .account-img-out.lb-image-2 .account-img {
  width: 70%;
  height: auto;
  position: absolute;
}
.lb-select.account-select .account-img-out.lb-image-2 .account-img:first-child {
  top: 0;
  left: 0;
}
.lb-select.account-select .account-img-out.lb-image-2 .account-img:last-child {
  right: 0;
  bottom: 0;
}
.lb-select.account-select .account-img {
  width: 5rem;
  height: 3.125rem;
  margin-right: 0.625rem;
}
.lb-select.account-select .Select-noresults {
  display: none;
}
.lb-select.account-select .Select-arrow-zone {
  z-index: 1;
}
.lb-select.account-select .Select-control {
  outline: none;
  border: 0;
}
.lb-select.account-select .Select-menu-outer {
  left: -1px;
  width: calc(100% + 2px);
  top: 0;
  z-index: 2;
}
.lb-select.account-select .lb-scrollbar {
  border-radius: 4px;
}
.lb-select.account-select .Select-input input {
  opacity: 0;
}
.lb-select.account-select .Select-option-group-label {
  padding: 0.5rem 0.9375rem;
}
.lb-select.account-select .select-option {
  padding: 0.625rem 0.9375rem;
}
.lb-select.account-select .select-option .icn.toggle-favorite {
  align-self: center;
  font-size: 0;
  margin-left: auto;
  width: 14px;
  height: 14px;
}
.lb-select.account-select .select-option:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}
.lb-select.account-select.is-disabled .Select-value .Select-value-label {
  color: #c9cacc !important;
}
.lb-select.product-select {
  width: 100%;
  max-width: 360px;
}
.lb-select.product-select .Select-placeholder {
  color: #5d6163;
  padding: 0 2rem 0 1rem;
}
.lb-select.product-select .select-option .account_amount {
  color: #db0011;
}
.lb-select.product-select .icn.toggle-favorite {
  color: #db0011;
}
.lb-select.is-open .simple-select-option .select-option-label {
  padding: 0 1.25rem;
  font-size: 0.875rem;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-select .simple-select-option .Select-value-label {
  width: 95%;
  outline: none;
}
.lb-select.grey-border .Select-control {
  border-color: #e4e6ea;
  border: 1px solid #d9d9e6;
}
.lb-select.grey-border .Select-menu {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.lb-select.grey-border .select-option {
  min-height: 50px;
  border-left: 2px solid #fff;
}
.lb-select.grey-border .select-option:hover {
  background: #fafafa;
  color: #5d6163;
  border-left: 2px solid #db0011;
}
.lb-select .add-person {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.875rem;
}

.transfer-select .template-details {
  transition: 0.2s;
}
.transfer-select .select-option.transfer-to {
  padding: 1.25rem 0.9375rem;
}
.transfer-select .select-option.transfer-to img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #fff;
  margin-right: 0.9375rem;
  border: 1px solid #949494;
}
.transfer-select .select-option.transfer-to .icn-add-person {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  color: #242424;
  margin-right: 0.9375rem;
  background: #e3e3e3;
  transition: 0.3s;
}
.transfer-select .select-option.transfer-to .icn-add-person svg {
  width: 13px;
  height: 13px;
}
.transfer-select.is-open .Select-value {
  display: none;
}

.transfers-block .trasfarents-disabled.is-disabled .Select-control {
  background: transparent !important;
  border: 1px solid #61686e !important;
}
.transfers-block .trasfarents-disabled.is-disabled .Select-control .Select-placeholder {
  color: #61686e;
}
.transfers-block .transfer-select {
  margin-left: auto;
  margin-right: auto;
}
.transfers-block .transfer-select .Select-control {
  background: transparent !important;
  border: 1px solid #e4e6ea !important;
}
.transfers-block .transfer-select .Select-placeholder,
.transfers-block .transfer-select .Select-input {
  padding: 0 2rem 0 1.6875rem;
}
.transfers-block .transfer-select .account_amount {
  color: #242424;
}
.transfers-block .transfer-select .icn.toggle-favorite {
  color: #242424;
}
.transfers-block .transfer-select:not(.type-select).is-focused .Select-control {
  background: transparent !important;
  border: 1px solid #e4e6ea !important;
}

.transfers-block.active .Select-control {
  border: 1px solid #fff;
}
.transfers-block.active .Select-arrow-zone .icn-arrow-down {
  color: #fff;
}
.transfers-block.active .Select-placeholder {
  color: #fff;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.transfers-block.active h3 {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}

.type-select .Select-control {
  border: 1px solid #d9d9e6;
  outline: none;
}
.type-select.focus-red {
  height: 50px;
}
.type-select.focus-red.is-focused {
  background: #eff1f4 !important;
}
.type-select.focus-red.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
}
.type-select .Select-input {
  position: relative;
  z-index: 1;
  height: 100%;
}
.type-select .Select-input input {
  padding: 14px 0;
  font-size: 0.875rem;
  line-height: 1.5;
  width: 100% !important;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.type-select .Select-menu-outer {
  top: calc(100% + 1px);
  z-index: 2;
}
.type-select .icn-search {
  display: none;
  color: #b7b6b8;
}
.type-select.is-open .Select-control {
  background: #fff !important;
}
.type-select.is-open .Select-placeholder {
  color: #969899;
  font-family: "Liberty-Nus", Arial, sans-serif;
  font-size: 0.875rem;
  background: #f3f3f7;
}
.type-select.is-open .icn-arrow-down {
  display: none;
}
.type-select.is-open .Select-menu-outer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.type-select.is-open .icn-search {
  display: block;
}
.type-select .transfer-to:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}
.type-select .tax-PayerName {
  color: #74777a;
  font-family: "Liberty-Nus", Arial, sans-serif;
}
.type-select .Select-clear-zone {
  display: none;
}
.type-select .Select-option .no-templates {
  cursor: auto !important;
  margin: auto;
}

.select-transparent .Select-control {
  background: transparent;
}
.select-transparent .select-option {
  padding-left: 0.9375rem;
}
.select-transparent .Select-menu {
  border-radius: 0;
}
.select-transparent.is-focused, .select-transparent.has-value {
  background: #fff;
}
.select-transparent.is-focused .Select-arrow-zone .icn-arrow-down, .select-transparent.has-value .Select-arrow-zone .icn-arrow-down {
  color: #717073;
}

.select-transparent-full .Select-control, .select-transparent-full.account-select .Select-control {
  background: transparent !important;
}

.no-arrow .Select-arrow-zone {
  display: none;
}
.no-arrow .Select-placeholder,
.no-arrow .Select-input,
.no-arrow .Select-value {
  width: 100%;
  padding: 0 1.25rem;
}
.no-arrow .Select-loading-zone,
.no-arrow .Select-clear-zone {
  right: 1rem;
}
.no-arrow .Select-control {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.no-arrow .Select-menu-outer {
  top: calc(100% - 4px);
}

.default-select .Select-control {
  outline: none;
  border: 0;
}
.default-select .Select-menu-outer {
  left: -1px;
  width: calc(100% + 2px);
  top: 0 !important;
}
.default-select .Select-input input {
  opacity: 0;
}
.default-select .select-option {
  padding: 0.625rem 0.9375rem;
}
.default-select .icn-search {
  display: none;
}
.default-select.border .Select-control {
  border: 1px solid #e4e6ea;
}
.default-select.red-top-select.is-open:not(.no-border) .lb-scrollbar {
  border-radius: 4px;
}

.lb-popup-row .lb-select .Select-control {
  height: 3.125rem;
  border-radius: 4px;
}
.lb-popup-row .lb-select.is-focused .Select-control {
  border: 1px solid #a5a5b1 !important;
}
.lb-popup-row .lb-select.transcountry-select.has-error.is-focused .Select-control {
  border: 1px solid transparent !important;
}
.lb-popup-row .lb-select.transcountry-select.no-error {
  border: 1px solid transparent !important;
}

.no-options:hover {
  background: #fff !important;
  cursor: default;
}
.no-options .select-option-label {
  flex: 1 1;
}

.lb-select.month-select,
.lb-select.status-select {
  font-family: "Liberty-Nus", Arial, sans-serif;
}

.lb-select.custom-select .Select-menu-outer,
.lb-select.select-code .Select-menu-outer {
  top: 0 !important;
  z-index: 5;
}
.lb-select.custom-select .Select-menu-outer:after,
.lb-select.select-code .Select-menu-outer:after {
  display: none !important;
}
.lb-select.custom-select .Select-menu-outer .Select-option:first-child,
.lb-select.select-code .Select-menu-outer .Select-option:first-child {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.lb-select.custom-select .Select-menu-outer .Select-option .is-selected,
.lb-select.select-code .Select-menu-outer .Select-option .is-selected {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.product-select-out {
  width: 100%;
  max-width: 360px;
  position: relative;
}
.product-select-out .product-select,
.product-select-out .card-select-out {
  width: 100%;
  max-width: none;
}
.product-select-out + .lb-select {
  margin-left: 15px;
}
.product-select-out .select-wrap {
  z-index: 4;
}

.statment-select-out {
  width: 360px;
  position: relative;
}
.statment-select-out .product-select {
  width: 100%;
  max-width: none;
}
.statment-select-out + .lb-select {
  margin-left: 15px;
}

/* =========== ERRORS ========== */
.error-select {
  font-size: 0.875rem;
  width: 85%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: #fff;
  padding-top: 10px;
  margin-left: 45px;
}
.error-select .icn.icn-warning {
  margin-top: 4px;
  color: #fff;
}
.error-select .icn.icn-warning svg {
  width: 23px;
  height: 23px;
}

.select-wrap .lb-select.bordered-error .Select-control {
  border: 2px solid #db0011 !important;
}
.select-wrap .lb-select.bordered-error.is-open .Select-control {
  border: 3px solid #fff !important;
}
.select-wrap .select-err-msg {
  font-size: 0.875rem;
  padding: 8px 10px;
  text-align: left;
  margin-top: -3px;
  background: #db0011;
  color: #fff;
}

.lb-all-select.lb-select-error .Select-control {
  border: 1px solid #db0011 !important;
}

.select-budget .select-option {
  min-height: 50px;
  border-left: 2px solid #fff;
}
.select-budget .select-option:hover {
  background: #e3e3e3;
  color: #db0011;
  border-left: 2px solid #db0011;
}
.select-budget .lb-scrollbar {
  border: none !important;
}

.white-select .Select-placeholder,
.white-select .Select-arrow-zone .icn-arrow-down {
  color: #242424 !important;
}

.larger-form-group .white-select .Select-control, .larger-form-group .white-select.is-open:not(.no-border) .Select-control {
  border: 0 !important;
}
.larger-form-group .white-select .Select-placeholder {
  font-size: 14px;
}
.larger-form-group .white-select .Select-option {
  padding: 0.625rem 1.0625rem;
  min-height: auto !important;
}
.larger-form-group .white-select .Select-menu-outer:after {
  display: none;
}
.larger-form-group .white-select .Select-value {
  background: #fafafa;
}
.larger-form-group .white-select .lb-scrollbar {
  border: 0 !important;
}

.lb-select.select-counter {
  width: 100px;
}
.lb-select.select-counter .Select-value {
  padding-left: 20px;
}
.lb-select.select-counter .select-option {
  padding-left: 20px;
}

.country-select {
  z-index: 6;
}
.country-select .Select-value:focus {
  outline: none;
}

.icon-info {
  top: 48% !important;
  right: 15px;
  z-index: 6;
}
.icon-info svg {
  color: #b2b2b2;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.icon-info:hover svg {
  color: #242424;
}

.transfers-form .lb-tooltip {
  max-width: 328px;
}

.lb-tooltip-text h6 {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.8125rem;
  padding-bottom: 8px;
}
.lb-tooltip-text p {
  font-size: 0.75rem;
  margin-bottom: 16px;
}

/* =========== Select Styles End ========== */
.providers-select {
  display: flex;
  align-items: center;
}
.providers-select .select-option .select-option-image {
  width: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.is-hidden {
  display: none !important;
}

/* access group */
/* alert group */
.is-warrning {
  color: #db0011 !important;
}

/* alert group */
.is-bold {
  font-weight: bold;
}

/* color green */
.is-green {
  color: #34ac34;
}

.is-greey {
  color: #616161;
}

.is-red {
  color: #db0011 !important;
  transition: 0.5s;
}
.is-red.pointer:hover {
  color: #db0011 !important;
}

.is-yellow {
  color: #fabf25;
}

a.is-red:hover {
  color: #db0011;
}

.is-blue {
  color: #1152C6;
}

.has-error {
  border: 1px solid #db0011 !important;
}

.green-bg {
  background: #34ac34;
}

.red-bg {
  background: #db0011;
}

.white-bg {
  background: #fff;
}

.light-grey {
  color: #b8b8b8;
}

.txt-steelGrey {
  color: #777;
}

.txt-grey {
  color: #464a4e;
}

.txt-blue {
  color: #3b6a98;
}

.is-grey {
  color: #74777a;
}

.is-lightgrey {
  color: #717171;
}

.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}

.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}

.pointer {
  cursor: pointer;
}

.po-s {
  position: static !important;
}

.po-r {
  position: relative;
}

.po-a {
  position: absolute;
}

.po-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ovf-h {
  overflow: hidden;
}

.br-bl {
  border-bottom-left-radius: 12px;
}

.br-br {
  border-bottom-right-radius: 12px;
}

.is-hiiden {
  visibility: hidden !important;
}

.grid {
  display: grid;
}

.row-1 {
  grid-row: 1;
}

.row-2 {
  grid-row: 2;
}

.rows-2 {
  grid-row: 1/3;
}

.col-1 {
  grid-column: 1;
}

.col-2 {
  grid-column: 2;
}

.opac-1 {
  opacity: 1;
}

.opac-3 {
  opacity: 0.3;
}

.opac-35 {
  opacity: 0.35;
}

.opac-4 {
  opacity: 0.4;
}

.opac-5 {
  opacity: 0.5;
}

.opac-6 {
  opacity: 0.6;
}

.opac-76 {
  opacity: 0.76;
}

.opac-64 {
  opacity: 0.64;
}

.flex {
  display: flex !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.flex-top {
  display: flex;
  align-items: flex-start;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-aligned {
  display: flex;
  align-items: center;
}

.flex-aligned-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-aligned-top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icn-block {
  width: 50px;
  height: 50px;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-justify-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-justify-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.flex-space,
.lb-block-subhead.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lb-block-subhead.flex-space > div {
  display: flex;
  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.flex-1 {
  flex: 1 1;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-end {
  align-self: flex-end;
}

.gap-12 {
  grid-gap: 12px;
  gap: 12px;
}

.gap-16 {
  grid-gap: 16px;
  gap: 16px;
}

.gap-24 {
  grid-gap: 24px;
  gap: 24px;
}

.gap-32 {
  grid-gap: 32px;
  gap: 32px;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.fnt-b {
  font-weight: bold;
}

.fnt-n {
  font-weight: normal;
}

.mw-305 {
  max-width: 305px;
}

.mw-470 {
  max-width: 470px;
}

.wp-190 {
  width: 190px;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-10 {
  width: 10%;
}

.w-13 {
  width: 13%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-46 {
  width: 46%;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52%;
}

.w-55 {
  width: 55%;
}

.w-58 {
  width: 58%;
}

.w-60 {
  width: 60%;
}

.w-63 {
  width: 63%;
}

.w-60i {
  width: 60% !important;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-97 {
  width: 97.8%;
}

.w-100 {
  width: 100% !important;
}

.wp-32 {
  width: 32px;
}

.w-12 {
  width: 12px;
}

.w-24 {
  width: 24px;
}

.wp-428 {
  width: 428px;
}

.wp-100 {
  width: 100px;
}

.wp-106 {
  width: 106px;
}

.wp-250 {
  width: 250px;
}

.wp-300 {
  width: 300px;
}

.w-auto {
  width: auto;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-50-15 {
  width: calc(50% - 15px);
}

.w-70-15 {
  width: calc(70% - 15px);
}

.mw-80 {
  max-width: 80%;
}

.mw-90 {
  max-width: 90%;
}

.mw-95 {
  max-width: 95%;
}

.mw-100 {
  max-width: 100%;
}

.mw-500 {
  max-width: 500px;
}

.mw-600 {
  max-width: 600px;
}

.mw-650 {
  max-width: 650px;
}

.mw-750 {
  max-width: 750px;
}

.wh-auto {
  width: auto;
  height: auto;
}

.mw-auto {
  min-width: auto;
}

.lb-popup-body.mw-500 {
  max-width: 500px !important;
}

.lb-popup-body.mw-562 {
  max-width: 562px !important;
}

.lb-popup-body.mw-5050 {
  max-width: 550px !important;
}

.lb-popup-body.mw-550 {
  max-width: 550px !important;
}

.lb-popup-body.mw-600 {
  max-width: 600px;
}

.lb-popup-body.mw-641 {
  max-width: 641px;
}

.lb-popup-body.mw-634 {
  max-width: 634px;
}

.lb-popup-body.mw-650 {
  max-width: 650px;
}

.lb-popup-body.mw-750 {
  max-width: 750px;
}

.w-650 {
  width: 650px !important;
}

.lb-popup-body.mw-490 {
  max-width: 490px;
}

.lb-popup-body.mw-655 {
  max-width: 655px;
}

.lb-popup-body.mw-700 {
  max-width: 700px;
}

.lb-popup-body.mw-750 {
  max-width: 750px;
}

.lb-popup-body.mw-780 {
  max-width: 780px;
}

.lb-popup-body.mw-800 {
  max-width: 800px;
}

.lb-popup-body.mw-834 {
  max-width: 834px;
}

.mw-845,
.lb-popup-body.mw-845 {
  max-width: 845px;
}

.lb-popup-body.mw-890 {
  max-width: 890px;
}

.lb-popup-body.mw-900 {
  max-width: 900px;
}

.mw-696 {
  max-width: 696px;
}

.mw-700 {
  max-width: 700px;
}

.mw-760 {
  max-width: 760px;
}

.mw-788 {
  max-width: 788px !important;
}

.mw-780 {
  max-width: 780px !important;
}

.mw-970 {
  max-width: 970px;
}

.h-32 {
  height: 32px;
}

.h-17 {
  height: 17px;
}

.h-40 {
  height: 40px;
}

.h-69 {
  height: 69px;
}

.mh-10 {
  min-height: 10vh;
}

.mh-15 {
  min-height: 15vh;
}

.mh-17 {
  min-height: 17vh;
}

.mh-20 {
  min-height: 20vh;
}

.mh-22 {
  min-height: 22vh;
}

.mh-25 {
  min-height: 25vh;
}

.mh-30 {
  min-height: 30vh;
}

.mh-35 {
  min-height: 35vh;
}

.mh-40 {
  min-height: 40vh;
}

.mh-45 {
  min-height: 45vh;
}

.mh-50 {
  min-height: 50vh;
}

.mih-40 {
  min-height: 40px;
}

.mh-3 {
  min-height: 393px;
}

.mh-40px {
  min-height: 40px;
}

.mh-45px {
  min-height: 45px;
}

.p-0 {
  padding: 0;
}

.h-50 {
  height: 50px !important;
}

.h-34 {
  height: 34px;
}

.h-100 {
  height: 100%;
}

.mv-auto {
  margin-left: auto;
  margin-right: auto;
}

.mtmi-24 {
  margin-top: -24px;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-34 {
  margin-top: 34px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ph-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.ph-12 {
  padding-right: 12px;
  padding-left: 12px;
}

.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.ph-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.ph-17 {
  padding-right: 17px;
  padding-left: 17px;
}

.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.ph-24 {
  padding-right: 24px;
  padding-left: 24px;
}

.ph-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.ph-32 {
  padding-right: 32px;
  padding-left: 32px;
}

.ph-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.ph-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.ph-42 {
  padding-right: 42px;
  padding-left: 42px;
}

.ph-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.ph-50 {
  padding-right: 50px;
  padding-left: 50px;
}

.ph-60 {
  padding-right: 60px;
  padding-left: 60px;
}

.ph-90 {
  padding-right: 90px;
  padding-left: 90px;
}

.mr-auto {
  margin-right: auto;
}

.mr-3 {
  margin-right: 3px;
}

.mr-4 {
  margin-right: 3.9%;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-32 {
  margin-right: 32px;
}

.mr-38 {
  margin-right: 38px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-520 {
  margin-right: 520px;
}

.mmr-25 {
  margin-right: -25px;
}

.mmt-20 {
  margin-top: -20px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-9 {
  margin-bottom: 9px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-19 {
  margin-bottom: 19px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-21 {
  margin-bottom: 21px;
}

.mb-23 {
  margin-bottom: 23px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-190 {
  margin-bottom: 190px;
}

.ml-auto {
  margin-left: auto;
}

.ml-2 {
  margin-left: 2px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-77 {
  margin-left: 77px;
}

.ml-86 {
  margin-left: 86px;
}

.ml-440 {
  margin-left: 440px !important;
}

.mmr-10 {
  margin-right: -10px;
}

.mmr-15 {
  margin-right: -15px;
}

.mmr-20 {
  margin-right: -20px;
}

.mmr-25 {
  margin-right: -25px;
}

.mmr-30 {
  margin-right: -30px;
}

.mmr-35 {
  margin-right: -35px;
}

.mmr-40 {
  margin-right: -40px;
}

.mmr-45 {
  margin-right: -45px;
}

.mmr-50 {
  margin-right: -50px;
}

.mmr-55 {
  margin-right: -55px;
}

.mmr-60 {
  margin-right: -60px;
}

.mml-10 {
  margin-left: -10px;
}

.mml-15 {
  margin-left: -15px;
}

.mml-20 {
  margin-left: -20px;
}

.mml-25 {
  margin-left: -25px;
}

.mml-30 {
  margin-left: -30px;
}

.mml-35 {
  margin-left: -35px;
}

.mml-40 {
  margin-left: -40px;
}

.mml-45 {
  margin-left: -45px;
}

.mml-50 {
  margin-left: -50px;
}

.mml-55 {
  margin-left: -55px;
}

.mml-60 {
  margin-left: -60px;
}

.p-25 {
  padding: 25px;
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: 2px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-26 {
  padding-top: 26px;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-32 {
  padding-top: 32px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-52 {
  padding-top: 52px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-0 {
  padding-right: 0;
}

.pr-5 {
  padding-right: 5px;
}

.pr-6 {
  padding-right: 6px;
}

.pr-7 {
  padding-right: 7px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-22 {
  padding-right: 22px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-32 {
  padding-right: 32px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-17 {
  padding-bottom: 17px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-88 {
  padding-bottom: 88px;
}

.pl-0 {
  padding-left: 0;
}

.pl-2 {
  padding-left: 2px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-32 {
  padding-left: 32px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-36 {
  padding-left: 36px !important;
}

.pl-38 {
  padding-left: 38px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-200 {
  padding-left: 200px;
}

.pl-410 {
  padding-left: 410px;
}

.mh-28 {
  margin-right: 28px;
  margin-left: 28px;
}

.mh-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pv-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pv-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}

.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ph-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.ph-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.ph-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.ph-17 {
  padding-right: 17px;
  padding-left: 17px;
}

.ph-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.ph-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.ph-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.ph-32 {
  padding-right: 32px;
  padding-left: 32px;
}

.ph-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.ph-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.ph-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.ph-50 {
  padding-right: 50px;
  padding-left: 50px;
}

.ph-70 {
  padding-right: 70px;
  padding-left: 70px;
}

.ph-90 {
  padding-right: 90px;
  padding-left: 90px;
}

.ph-100 {
  padding-right: 100px;
  padding-left: 100px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mp-20 {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.mp-25 {
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
}

.mp-30-25 {
  margin-left: -30px;
  margin-right: -25px;
  padding-left: 30px;
  padding-right: 25px;
}

.mp-30 {
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
}

.mp-32 {
  margin-left: -32px;
  margin-right: -32px;
  padding-left: 32px;
  padding-right: 32px;
}

.pl-pct-25 {
  padding-left: 25%;
}

.pr-pct-30 {
  padding-right: 30%;
}

.ml-pct-15 {
  margin-left: 15%;
}

.bt {
  border-top: 1px solid #e9ecf2;
}

.bb {
  border-bottom: 1px solid #e9ecf2;
}

.bb-b {
  border-bottom: 1px solid #c4c4c4;
}

.row-border:not(:last-child) {
  border-bottom: 1px solid #e9ecf2;
}

.rounded {
  border-radius: 50%;
}

.min-width {
  min-width: 0;
  width: 20%;
  white-space: nowrap;
}

/* =========== text-overflow ellipsis ========== */
.lb-ellipsis-wrap {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  align-items: center;
}
.lb-ellipsis-wrap .lb-ellipsis {
  min-width: 0;
}
.lb-ellipsis-wrap.text-right .lb-ellipsis {
  width: 100%;
  flex: 1 1;
}
.lb-ellipsis-wrap .icn-warning {
  margin: 0 0 0 9px;
}

.icn-filter {
  width: 18px !important;
  height: 18px !important;
}

.lb-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

/* =========== END of text-overflow ellipsis ========== */
/* =========== Main text font classes ========== */
.fnt-7 {
  font-size: 0.4375rem;
}

.fnt-8 {
  font-size: 0.5rem;
}

.fnt-10 {
  font-size: 0.625rem;
}

.fnt-11 {
  font-size: 0.6875rem;
}

.fnt-12 {
  font-size: 0.75rem;
}

.fnt-13 {
  font-size: 0.8125rem;
}

.fnt-14 {
  font-size: 0.875rem;
}

.fnt-15 {
  font-size: 0.9375rem;
}

.fnt-16 {
  font-size: 1rem;
}

.fnt-17 {
  font-size: 1.0625rem;
}

.fnt-18 {
  font-size: 1.125rem;
}

.fnt-19 {
  font-size: 1.1875rem;
}

.fnt-20 {
  font-size: 1.25rem;
}

.fnt-21 {
  font-size: 1.3125rem;
}

.fnt-22 {
  font-size: 1.375rem;
}

.fnt-23 {
  font-size: 1.4375rem;
}

.fnt-24 {
  font-size: 1.5rem;
}

.fnt-25 {
  font-size: 1.5625rem;
}

.fnt-25 {
  font-size: 1.5625rem;
}

.fnt-30 {
  font-size: 1.875rem;
}

.fnt-32 {
  font-size: 2rem;
}

.lh-14 {
  line-height: 0.9375rem;
}

.lh-15 {
  line-height: 1.5rem;
}

.lh-16 {
  line-height: 1rem;
}

.lh-17 {
  line-height: 1.0625rem;
}

.lh-18 {
  line-height: 1.125rem;
}

.lh-19 {
  line-height: 1.1875rem;
}

.lh-38 {
  line-height: 2.375rem;
}

.lh-24 {
  line-height: 1.5rem;
}

.lh-40 {
  line-height: 2.5rem;
}

/* =========== font family classes ========== */
.fnt-low {
  font-family: "Liberty-Nus", Arial, sans-serif;
}

.fnt-low-m {
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}

.fnt-low-b {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}

.fnt-upper {
  font-family: "Liberty-MT", Arial, sans-serif;
}

.fnt-upper-m {
  font-family: "Liberty-MT Medium", Arial, sans-serif;
}

.fnt-upper-b {
  font-family: "Liberty-MT Bold", Arial, sans-serif;
}

/* =========== font family classes end ========== */
.std-text-12 {
  font-size: 0.75rem;
  line-height: 1.8em;
}

.std-text-14 {
  font-size: 0.875rem;
  line-height: 1.8em;
}

.std-text-13 {
  font-size: 0.8125rem;
  line-height: 1.5em;
}

.light-grey {
  color: #74777a;
}

.lightg {
  color: #717171;
}

.tr-grey {
  color: #96999b;
}

.grey-24 {
  color: #242424;
}

.medium-grey {
  color: #8d9195;
}

.dark-clr {
  color: #1d262f;
}

.basic-clr {
  color: #242424;
}

.upper {
  text-transform: uppercase;
}

.bg-grey {
  background: #fafafa;
}

.bg-white {
  background: #fff;
}

.white {
  color: #fff;
}

/* =========== End of Main text font classes ========== */
.dwn-link {
  display: flex;
  font-family: "Liberty-Nus", Arial, sans-serif;
  align-items: center;
}
.dwn-link span + .icn-download {
  position: relative;
  top: -0.07rem;
  cursor: pointer;
}
.dwn-link span + .icn-download:hover {
  color: #db0011;
}

.lb-subhead-custom span + .icn-print {
  position: relative;
  top: -0.07rem;
  cursor: pointer;
}
.lb-subhead-custom span + .icn-print:hover {
  color: #db0011;
}

.radio-label {
  display: flex;
  align-items: center;
  margin: 0.9375rem 0;
  cursor: pointer;
}
.radio-label .radio-button {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.radio-label span {
  order: 2;
}

.radio-box {
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid #d9d9e6;
  margin: 0 10px 0 0;
  order: 1;
}

.radio-button:checked ~ .radio-box {
  border: 4px solid #db0011;
  background: #fff;
}
.radio-button:checked ~ span {
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
  font-size: 0.875rem;
}

.radio-button-black:checked ~ .radio-box {
  border: 4px solid #000;
  background-image: url("/static/media/tick.8bbdea7f.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.lb-flag {
  display: inline-block;
  font-size: 0;
  background: url(/static/media/flags_103x69.a1873eb5.png) no-repeat;
  background-size: 100% auto;
}

.flag-round {
  border-radius: 50%;
}

.lb-flag.flag20x20 {
  width: 20px;
  height: 20px;
  background-size: 30px 629px;
}
.lb-flag.flag20x20.fAED {
  background-position: 0 0;
}
.lb-flag.flag20x20.fAMD {
  background-position: 0 -21px;
}
.lb-flag.flag20x20.fAUD {
  background-position: 0 -41px;
}
.lb-flag.flag20x20.fAVD {
  background-position: 0 -61px;
}
.lb-flag.flag20x20.fAZM {
  background-position: -5px -85px;
}
.lb-flag.flag20x20.fBGN {
  background-position: 0 -106px;
}
.lb-flag.flag20x20.fBYB {
  background-position: 0 -126px;
}
.lb-flag.flag20x20.fCAD {
  background-position: -5px -147px;
}
.lb-flag.flag20x20.fCHF {
  background-position: -5px -168px;
}
.lb-flag.flag20x20.fCZK {
  background-position: -3px -190px;
}
.lb-flag.flag20x20.fDKK {
  background-position: -5px -209px;
}
.lb-flag.flag20x20.fEUR {
  background-position: -5px -231px;
}
.lb-flag.flag20x20.fGBP {
  background-position: -5px -252px;
}
.lb-flag.flag20x20.fGEL {
  background-position: -3px -273px;
  background-size: 25px 629px;
}
.lb-flag.flag20x20.fHKD {
  background-position: -5px -294px;
}
.lb-flag.flag20x20.fILS {
  background-position: -5px -315px;
}
.lb-flag.flag20x20.fJPY {
  background-position: -5px -336px;
}
.lb-flag.flag20x20.fKZT {
  background-position: -6px -356px;
}
.lb-flag.flag20x20.fLTL {
  background-position: -5px -378px;
}
.lb-flag.flag20x20.fLVL {
  background-position: 0 -400px;
}
.lb-flag.flag20x20.fMDL {
  background-position: -5px -420px;
}
.lb-flag.flag20x20.fNOK {
  background-position: -3px -441px;
}
.lb-flag.flag20x20.fPLN {
  background-position: 0 -463px;
}
.lb-flag.flag20x20.fRUB {
  background-position: 0 -483px;
}
.lb-flag.flag20x20.fRUR {
  background-position: 0 -504px;
}
.lb-flag.flag20x20.fSEK {
  background-position: -3px -524px;
}
.lb-flag.flag20x20.fTRL {
  background-position: -3px -546px;
}
.lb-flag.flag20x20.fUAH {
  background-position: 0 -567px;
}
.lb-flag.flag20x20.fUKG {
  background-position: 0 -587px;
}
.lb-flag.flag20x20.fUSD {
  background-position: 0px -607px;
}

.lb-flag.flag56x38 {
  width: 56px;
  height: 38px;
  border-radius: 4px;
}
.lb-flag.flag56x38.fAED {
  background-position: 0 0;
}
.lb-flag.flag56x38.fAMD {
  background-position: 0 -38px;
}
.lb-flag.flag56x38.fAUD {
  background-position: 0 -76px;
}
.lb-flag.flag56x38.fAVD {
  background-position: 0 -115px;
}
.lb-flag.flag56x38.fAZM {
  background-position: 0 -153px;
}
.lb-flag.flag56x38.fBGN {
  background-position: 0 -190px;
}
.lb-flag.flag56x38.fBYB {
  background-position: 0 -228px;
}
.lb-flag.flag56x38.fCAD {
  background-position: 0 -266px;
}
.lb-flag.flag56x38.fCHF {
  background-position: 0 -304px;
}
.lb-flag.flag56x38.fCZK {
  background-position: 0 -342px;
}
.lb-flag.flag56x38.fDKK {
  background-position: 0 -380px;
}
.lb-flag.flag56x38.fEUR {
  background-position: 0 -418px;
}
.lb-flag.flag56x38.fGBP {
  background-position: 0 -456px;
}
.lb-flag.flag56x38.fGEL {
  background-position: 0 -494px;
}
.lb-flag.flag56x38.fHKD {
  background-position: 0 -532px;
}
.lb-flag.flag56x38.fILS {
  background-position: 0 -571px;
}
.lb-flag.flag56x38.fJPY {
  background-position: 0 -609px;
}
.lb-flag.flag56x38.fKZT {
  background-position: 0 -646px;
}
.lb-flag.flag56x38.fLTL {
  background-position: 0 -684px;
}
.lb-flag.flag56x38.fLVL {
  background-position: 0 -722px;
}
.lb-flag.flag56x38.fMDL {
  background-position: 0 -760px;
}
.lb-flag.flag56x38.fNOK {
  background-position: 0 -798px;
}
.lb-flag.flag56x38.fPLN {
  background-position: 0 -837px;
}
.lb-flag.flag56x38.fRUB {
  background-position: 0 -875px;
}
.lb-flag.flag56x38.fRUR {
  background-position: 0 -913px;
}
.lb-flag.flag56x38.fSEK {
  background-position: 0 -950px;
}
.lb-flag.flag56x38.fTRL {
  background-position: 0 -989px;
}
.lb-flag.flag56x38.fUAH {
  background-position: 0 -1027px;
}
.lb-flag.flag56x38.fUKG {
  background-position: 0 -1065px;
}
.lb-flag.flag56x38.fUSD {
  background-position: 0 -1103px;
}

.lb-flag.flag96x64 {
  width: 96px;
  height: 64px;
  border-radius: 4px;
}
.lb-flag.flag96x64.fAED {
  background-position: 0 0;
}
.lb-flag.flag96x64.fAMD {
  background-position: 0 -64px;
}
.lb-flag.flag96x64.fAUD {
  background-position: 0 -130px;
}
.lb-flag.flag96x64.fAVD {
  background-position: 0 -195px;
}
.lb-flag.flag96x64.fAZM {
  background-position: 0 -262px;
}
.lb-flag.flag96x64.fBGN {
  background-position: 0 -326px;
}
.lb-flag.flag96x64.fBYB {
  background-position: 0 -390px;
}
.lb-flag.flag96x64.fCAD {
  background-position: 0 -457px;
}
.lb-flag.flag96x64.fCHF {
  background-position: 0 -521px;
}
.lb-flag.flag96x64.fCZK {
  background-position: 0 -588px;
}
.lb-flag.flag96x64.fDKK {
  background-position: 0 -652px;
}
.lb-flag.flag96x64.fEUR {
  background-position: 0 -717px;
}
.lb-flag.flag96x64.fGBP {
  background-position: 0 -784px;
}
.lb-flag.flag96x64.fGEL {
  background-position: 0 -848px;
}
.lb-flag.flag96x64.fHKD {
  background-position: 0 -913px;
}
.lb-flag.flag96x64.fILS {
  background-position: 0 -979px;
}
.lb-flag.flag96x64.fJPY {
  background-position: 0 -1044px;
}
.lb-flag.flag96x64.fKZT {
  background-position: 0 -1109px;
}
.lb-flag.flag96x64.fLTL {
  background-position: 0 -1174px;
}
.lb-flag.flag96x64.fLVL {
  background-position: 0 -1239px;
}
.lb-flag.flag96x64.fMDL {
  background-position: 0 -1305px;
}
.lb-flag.flag96x64.fNOK {
  background-position: 0 -1369px;
}
.lb-flag.flag96x64.fPLN {
  background-position: 0 -1435px;
}
.lb-flag.flag96x64.fRUB {
  background-position: 0 -1500px;
}
.lb-flag.flag96x64.fRUR {
  background-position: 0 -1565px;
}
.lb-flag.flag96x64.fSEK {
  background-position: 0 -1630px;
}
.lb-flag.flag96x64.fTRL {
  background-position: 0 -1696px;
}
.lb-flag.flag96x64.fUAH {
  background-position: 0 -1761px;
}
.lb-flag.flag96x64.fUKG {
  background-position: 0 -1827px;
}
.lb-flag.flag96x64.fUSD {
  background-position: 0 -1891px;
}

@media screen and (max-width: 1366px) {
  .fnt-22 {
    font-size: 1.125rem;
  }
  .fnt-24 {
    font-size: 1.25rem;
  }
}
.circle-black {
  background-color: #242424;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;
}

.circle-grey {
  background-color: #e9ecf2;
  border-radius: 50%;
  margin-right: 11px;
  margin-top: 7px;
  position: relative;
  width: 11px;
  height: 11px;
}

.line-circle {
  background-color: #e9ecf2;
  width: 1px;
  height: 18px;
  position: relative;
  right: -5px;
  margin-right: -1px;
  top: -13px;
}

.dot-row:last-child .dot-main:before {
  display: none;
}
.dot-row .dot {
  position: relative;
}
.dot-row .dot:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
}
.dot-row .dot.dot-main:before {
  width: 11px;
  height: 11px;
  margin-right: 10px;
  background: #242424;
}
.dot-row .dot:not(.dot-main):before {
  width: 7px;
  height: 7px;
  margin-right: 12px;
  background: #e9ecf2;
  margin-left: 2px;
}
.dot-row:not(:last-child) {
  margin-bottom: 22px;
}
.dot-row:not(:last-child) .dot:after {
  content: "";
  position: absolute;
  height: 18px;
  width: 1px;
  background: #e9ecf2;
  top: calc(100% + 2px);
  left: 5px;
}

.lb-back {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  font-family: "Liberty-Nus Medium", Arial, sans-serif;
}
.lb-back .icn {
  width: 4px;
  height: 7px;
  margin-right: 10px;
}

.list-style {
  position: relative;
  padding-left: 20px;
  margin: 10px 0;
  font-family: "Liberty-Nus Bold", Arial, sans-serif;
}
.list-style:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #db0011;
  border-radius: 50%;
}

.ovf-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.lh-20 {
  line-height: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e5e8eb;
}

.d-none {
  display: none !important;
}

.d-block {
  display: flex !important;
}

.disable {
  opacity: 0.4;
}

.lh-n {
  line-height: normal;
}

.color-light {
  color: #5c5c5e;
}

.color-dark {
  color: #212126;
}

.dis-block {
  display: block;
}

.lb-header-dashboard:not(.template-panel) .widget-item:not(.icn-add) .icn-widget:hover {
  background: #fff;
  border: 1px solid #db0011;
}
.lb-header-dashboard:not(.template-panel) .pay-abbr {
  transition: 0.1s;
}
.lb-header-dashboard:not(.template-panel) .pay-abbr:hover {
  color: #c61e3f;
  background: #fff;
  box-shadow: 0px 0 0px 0.05px #fff;
}

.dwn-hover {
  transition: 0.2s;
}
.dwn-hover:hover {
  color: #db0011;
}
.dwn-hover:hover i {
  color: #db0011;
}
.dwn-hover .loader-out.full-size {
  left: inherit;
}

.lb-popup-row.pointer {
  transition: 0.2s;
}
.lb-popup-row.pointer:hover {
  background: rgba(243, 243, 243, 0.44);
}
.lb-popup-row.pointer:hover .icn-arr-right,
.lb-popup-row.pointer:hover .arrow-right {
  color: #db0011 !important;
}

.link-default {
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;
}
.link-default:not(.link-back) {
  position: relative;
  display: inline-block;
}
.link-default:not(.link-back):after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  right: 0;
  height: 1px;
  background: #db0011;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
}
.link-default:not(.link-back):hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.link-default:not(.link-back).active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.link-default.link-back {
  position: relative;
  display: inline-block;
}
.link-default.link-back:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #db0011;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
}
.link-default.link-back:hover:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.link-default.link-back.active:after {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.link-default .icn {
  transition: 0.3s;
}
.link-default:hover {
  color: #db0011;
}
.link-default:hover .icn {
  color: #db0011;
}

.link-line {
  cursor: pointer;
  position: relative;
  display: inline-flex;
}
.link-line:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: currentColor;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
}
.link-line:hover:after {
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
}
.link-line.active:after {
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
}

.helpfull-links .pointer {
  transition: 0.3s;
}
.helpfull-links .pointer:hover {
  color: #db0011;
}

.lb-lang-switcher li {
  transition: 0.3s;
}
.lb-lang-switcher li:hover {
  color: #db0011;
}

.lb-select .can-select-favorites .toggle-favorite {
  transition: 0.3s;
}
.lb-select .can-select-favorites .toggle-favorite:hover {
  color: #db0011 !important;
}

.details-block .lb-select .toggle-favorite {
  transition: 0.3s;
}
.details-block .lb-select .toggle-favorite:hover {
  color: #242424 !important;
}

.set-trigger.link-line {
  transition: 0.3s;
}
.set-trigger.link-line:hover {
  color: #db0011;
}

.template-add-box {
  transition: color 0.3s;
}
.template-add-box:hover {
  opacity: color 0.7;
}

.utility-input-out .icn-close {
  cursor: pointer;
  transition: 0.2s;
}
.utility-input-out .icn-close:hover {
  color: #db0011;
}

.calendar-arrow {
  transition: 0.2s;
}
.calendar-arrow:hover {
  color: #db0011;
}

.template-btn .icn {
  transition: 0.2s;
}
.template-btn .icn:hover {
  background: #db0011;
}
